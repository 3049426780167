angular.module('inspecciones')
    .controller('inspeccionController', ['$rootScope', '$scope', '$location', '$route', '$routeParams', 'inspeccionesService', 'TVService', 'infraestructurasService', 'infraestructurasAdminService', 'authenticationInfo', 'authenticationService', '$sce',
        function ($rootScope, $scope, $location, $route, $routeParams, inspeccionesService, TVService, infraestructurasService, infraestructurasAdminService, authenticationInfo, authenticationService, $sce) {

            $scope.damageBarChartSeccion = null;

            $scope.authentication = authenticationInfo;
            //$scope.InfraestructuraId = $routeParams.idInfraestructura;

            $scope.InspeccionId = $routeParams.id;

            $scope.pestanyamenuinspeccion = 'general';
            //$scope.pestanyavisible = ($scope.InspeccionId > 0) ? 'general' : 'inspeccion';

            $scope.min = 10;
            $scope.max = 300;
            $scope.step = 10;
            $scope.seccion = 100;
            $scope.hoverValue = 100;
            $scope.tooltipStyle = { display: "none" };

            inspeccionesService.getTunelId($rootScope.InfraestructuraId)
                .then(function (response) {
                    $scope.TunelId = response.data;
                });


            infraestructurasAdminService.getPropietarios()
                .then(function (response) {
                    var data = response.data;
                    $scope.propietarios = data;
                });

            infraestructurasAdminService.getInspectores()
                .then(function (response) {
                    var data = response.data;
                    $scope.inspectores = data;
                });


            $scope.indice = 0;

            if ($scope.InspeccionId > 0) {
                inspeccionesService.getInspeccion($scope.InspeccionId)
                    .then(function (response) {
                        var data = response.data;
                        $scope.inspeccion = data;
                        $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                        $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
                        $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                        $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin ? $scope.inspeccion.FechaFin.toString() : ''; // Verificación específica
                        //$scope.inspeccion.FechaInspAnterior = $scope.inspeccion.FechaInspAnterior.toString();
                        $scope.inspeccion.FechaInspAnterior = dateFormat($scope.inspeccion.FechaInspAnterior, "dd/mm/yyyy");
                        $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                        $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
                        $scope.inspeccion.Inspector = $scope.inspeccion.Inspector.toString();
                        $scope.inspeccion.Propietario = $scope.inspeccion.Propietario.toString();
                        $scope.inspeccion.InspectorId = $scope.inspeccion.InspectorId.toString();
                        $scope.inspeccion.PropietarioId = $scope.inspeccion.PropietarioId.toString();
                        // $scope.inspeccion.TunelId = $scope.TunelId.toString();

                        $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.Tunel.IdentificadorTubo.toString();

                        //inspeccionesService.getDaños($scope.inspeccion.Id)
                        //    .then(function (response) {
                        //        $scope.damages = response.data;
                        //    });

                        //inspeccionesService.getDañosPorPK($scope.inspeccion.Id)
                        //    .then(function (response) {
                        //        $scope.damagesporpk = response.data;
                        //    });

                        //inspeccionesService.getDañosPorTipo($scope.inspeccion.Id)
                        //    .then(function (response) {
                        //        $scope.damagesportipo = response.data;
                        //    });

                        //inspeccionesService.getDañosPorElemento($scope.inspeccion.Id)
                        //    .then(function (response) {
                        //        $scope.damagesporelemento = response.data;
                        //    });

                    })
            }


            TVService.getSitio($rootScope.InfraestructuraId)
                .then(function (response) {
                    var data = response.data;
                    $scope.sitio = data;
                    //   $scope.sitioUrl = $sce.trustAsHtml("<iframe src='https://nube.infraestructuradigital.es/views/EntornoPruebasv2.html' style='width: 100%; height: 800px'></iframe>");
                    $scope.sitioUrl = $sce.trustAsHtml($scope.sitio.sitioinspeccion);
                });





            $scope.verDamage = function (idDaño) {
                $location.url('/damages/' + $scope.InspeccionId + '/' + idDaño);
            }


            $scope.validarSeleccion = function () {
                if (!$scope.informeSeleccionado) {
                    // Si no se selecciona un informe, muestra una alerta
                    alert("Por favor, seleccione un informe antes de continuar.");
                    return;
                }

                // Si se seleccionó un informe, procede con la generación
                $scope.imprimirInforme($scope.ultimainspeccion);
            };


            $scope.imprimirInforme = function () {
                if ($scope.informeSeleccionado == "Informe Inventario Tunel") {
                    infraestructurasService.getInforme($scope.InspeccionId)
                        .then(function (data, status, headers) {

                            var filename = "Informe Inspeccion.doc";
                            var contentType = 'application/msword';

                            var blob = new Blob([data.data],
                                { type: contentType });

                            if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                window.navigator.msSaveBlob(blob, filename);
                            else //Resto
                            {
                                var downloadLink = document.createElement("a");
                                var url = window.URL.createObjectURL(blob);
                                downloadLink.setAttribute("target", "_blank");
                                downloadLink.setAttribute("href", url);
                                downloadLink.setAttribute("download", filename);
                                document.body.appendChild(downloadLink);
                                downloadLink.click();
                                document.body.removeChild(downloadLink);
                                elemento.style.display = 'none';
                            }
                        })
                }
                else {
                    inspeccionesService.getUltimaInspeccion($scope.TunelId)
                        .then(function (data) {
                            infraestructurasService.getInforme(data.data)
                                .then(function (data, status, headers) {

                                    var filename = "Informe Inspeccion.doc";
                                    var contentType = 'application/msword';

                                    var blob = new Blob([data.data],
                                        { type: contentType });

                                    if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                        window.navigator.msSaveBlob(blob, filename);
                                    else //Resto
                                    {
                                        var downloadLink = document.createElement("a");
                                        var url = window.URL.createObjectURL(blob);
                                        downloadLink.setAttribute("target", "_blank");
                                        downloadLink.setAttribute("href", url);
                                        downloadLink.setAttribute("download", filename);
                                        document.body.appendChild(downloadLink);
                                        downloadLink.click();
                                        document.body.removeChild(downloadLink);
                                        elemento.style.display = 'none';
                                    }
                                })
                        })
                }
            }


            $scope.eliminarDamage = function (idDaño) {
                check = confirm("¿Seguro que deseas eliminar este daño?")

                if (check) {
                    inspeccionesService.eliminarDamage(idDaño)
                        .then(function (response) {
                            inspeccionesService.getDaños($scope.InspeccionId)
                                .then(function (response) {
                                    var data = response.data;
                                    $scope.damages = data;
                                });
                        });
                }
            };


            $scope.volver = function () {
                $location.path('/infraestructura/' + $rootScope.InfraestructuraId);
                $rootScope.inspeccionUrl = true;
            }


            $scope.guardar = function (mostrarAlerta) {
                const timeElapsed = Date.now();
                const today = new Date(timeElapsed);

                var inspeccionSQL = new Object();
                inspeccionSQL.Id = $scope.InspeccionId;
                inspeccionSQL.Descripcion = $scope.inspeccion.Descripcion;
                inspeccionSQL.IndiceTubo = $scope.inspeccion.IndiceTubo;
                inspeccionSQL.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo;
                inspeccionSQL.FechaInicio = $scope.inspeccion.FechaInicio;
                inspeccionSQL.FechaFin = $scope.inspeccion.FechaFin ? $scope.inspeccion.FechaFin : ''; // Verificación específica
                inspeccionSQL.NumTramos = $scope.inspeccion.NumTramos;
                inspeccionSQL.LongTramo = $scope.inspeccion.LongTramo;
                inspeccionSQL.InspectorId = $scope.inspeccion.InspectorId;
                inspeccionSQL.PropietarioId = $scope.inspeccion.PropietarioId;
                inspeccionSQL.TunelId = $scope.TunelId;





                // Validar que todos los valores sean numéricos
                if (!isNaN($scope.inspeccion.NumTramos) &&
                    !isNaN($scope.inspeccion.LongTramo)) {
                    if (!$scope.inspeccion.FechaInicio) {
                        alert("Debe introducir la Fecha de Inicio de la inspección.");
                        return; // Salir si las fechas no son válidas
                    }

                    // Convertir a números y luego a cadena
                    // $scope.inspeccion.IndiceTubo = parseInt($scope.inspeccion.IndiceTubo, 10).toString();
                    $scope.inspeccion.NumTramos = parseInt($scope.inspeccion.NumTramos, 10).toString();
                    $scope.inspeccion.LongTramo = parseFloat($scope.inspeccion.LongTramo).toString(); // Usar parseFloat para valores decimales


                    inspeccionesService.putInspeccion(inspeccionSQL)
                        .then(function (data) {

                            if ($scope.InspeccionId == 0) {
                                $scope.InspeccionId = data.data;
                            }
                            else {
                                $scope.InspeccionId = $scope.InspeccionId;
                            }


                            inspeccionesService.getInspeccion($scope.InspeccionId)
                                .then(function (response) {
                                    var data = response.data;
                                    $scope.inspeccion = data;
                                    $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                                    $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
                                    $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo.toString();
                                    $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                                    $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin ? $scope.inspeccion.FechaFin.toString() : ''; // Verificación específica
                                    $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                                    $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
                                    $scope.inspeccion.Inspector = $scope.inspeccion.Inspector.toString();
                                    $scope.inspeccion.Propietario = $scope.inspeccion.Propietario.toString();
                                    $scope.inspeccion.TunelId = $scope.TunelId.toString();
                                    $scope.inspeccion.InspectorId = $scope.inspeccion.InspectorId.toString();
                                    $scope.inspeccion.PropietarioId = $scope.inspeccion.PropietarioId.toString();

                                    alert('Datos guardados correctamente');
                                })
                        })

                } else {
                    alert("Compruebe que los valores Numero de Tramos y Longitud de Tramo sean numéricos");
                }

            };

            $scope.logout = function () {
                return authenticationService.logout();
            }


            $scope.refrescarGeneral = function () {
                $scope.pestanyavisible = 'general'
                inspeccionesService.getInspeccion($scope.InspeccionId)
                    .then(function (response) {
                        var data = response.data;
                        $scope.inspeccion = data;
                        $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                        $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
                        $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                        $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin ? $scope.inspeccion.FechaFin.toString() : ''; // Verificación específica
                        //$scope.inspeccion.FechaInspAnterior = $scope.inspeccion.FechaInspAnterior.toString();
                        $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                        $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
                        $scope.inspeccion.Inspector = $scope.inspeccion.Inspector.toString();
                        $scope.inspeccion.Propietario = $scope.inspeccion.Propietario.toString();
                        $scope.inspeccion.InspectorId = $scope.inspeccion.InspectorId.toString();
                        $scope.inspeccion.PropietarioId = $scope.inspeccion.PropietarioId.toString();
                        // $scope.inspeccion.TunelId = $scope.TunelId.toString();

                        $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.Tunel.IdentificadorTubo.toString();

                        //inspeccionesService.getDaños($scope.inspeccion.Id)
                        //    .then(function (response) {
                        //        $scope.damages = response.data;
                        //    });

                        //inspeccionesService.getDañosPorPK($scope.inspeccion.Id)
                        //    .then(function (response) {
                        //        $scope.damagesporpk = response.data;
                        //    });

                        //inspeccionesService.getDañosPorTipo($scope.inspeccion.Id)
                        //    .then(function (response) {
                        //        $scope.damagesportipo = response.data;
                        //    });

                        //inspeccionesService.getDañosPorElemento($scope.inspeccion.Id)
                        //    .then(function (response) {
                        //        $scope.damagesporelemento = response.data;
                        //    });

                    })
            }

            $scope.refrescarDanos = function () {
                $scope.mostrar('loader-seguimiento');
                inspeccionesService.getDaños($scope.inspeccion.Id)
                    .then(function (response) {
                        $scope.damages = response.data;
                        $scope.ocultar('loader-seguimiento');
                    });
            }

            $scope.refrescarPorPk = function () {

                inspeccionesService.getDañosPorPK($scope.inspeccion.Id)
                    .then(function (response) {
                        $scope.damagesporpk = response.data;
                    });
            }

            $scope.refrescarPorTipo = function () {

                inspeccionesService.getDañosPorTipo($scope.inspeccion.Id)
                    .then(function (response) {
                        $scope.damagesportipo = response.data;
                    });
            }

            $scope.refrescarPorElemento = function () {

                inspeccionesService.getDañosPorElemento($scope.inspeccion.Id)
                    .then(function (response) {
                        $scope.damagesporelemento = response.data;
                    });
            }

            $scope.refrescarPorSeccion = function () {
                $scope.mostrar('loader-seguimiento-seccion');
                inspeccionesService.getDañosPorSeccion($scope.InspeccionId, $scope.seccion)
                    .then(function (response) {
                        $scope.dañosSeccion = response.data;

                        // Generar dinámicamente los labels y los datos basados en $scope.daños
                        const labelsSec = $scope.dañosSeccion.map(function (d) {
                            return d.Seccion || 0;
                        });

                        const dataLeveSec = $scope.dañosSeccion.map(function (d) {
                            return d.Gravedades[0].Conteo || null;
                        });

                        const dataModSec = $scope.dañosSeccion.map(function (d) {
                            return d.Gravedades[1].Conteo || null;
                        });

                        const dataSevSec = $scope.dañosSeccion.map(function (d) {
                            return d.Gravedades[2].Conteo || null;
                        });

                        const dataCritSec = $scope.dañosSeccion.map(function (d) {
                            return d.Gravedades[3].Conteo || null;
                        });



                        //const labelsSeccion = { count: 7 };
                        const dataSec = {
                            labels: labelsSec,
                            datasets: [
                                {
                                    label: 'Leve',
                                    data: dataLeveSec,
                                    backgroundColor: 'rgba(0, 128, 0, 0.7)',
                                },
                                {
                                    label: 'Moderado',
                                    data: dataModSec,
                                    backgroundColor: 'rgba(255, 255, 0, 0.7)',
                                },
                                {
                                    label: 'Severo',
                                    data: dataSevSec,
                                    backgroundColor: 'rgba(255, 165, 0, 0.7)',
                                },
                                {
                                    label: 'Critico',
                                    data: dataCritSec,
                                    backgroundColor: 'rgba(255, 0, 0, 0.7)',
                                },
                            ]
                        };

                        const configSec = {
                            type: 'bar',
                            data: dataSec,
                            options: {
                                plugins: {
                                    title: {
                                        display: true,
                                    },
                                },
                                responsive: true,
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true
                                    }
                                }
                            }
                        };
                        if ($scope.damageBarChartSeccion) {
                            $scope.damageBarChartSeccion.destroy();
                        }

                        const ctxSec = document.getElementById('graficoDaniosPorSeccion').getContext('2d');
                        $scope.damageBarChartSeccion = new Chart(ctxSec, configSec);
                        $scope.ocultar('loader-seguimiento-seccion');
                    });
            }


            $scope.verCategorias = function () {
                $rootScope.inspeccionUrl = false;
                $rootScope.parteUrl = false;
                $location.path('/categorias');
            };

            inspeccionesService.getSecciones($scope.InspeccionId, $scope.seccion)
                .then(function (response) {
                    $scope.secciones = response.data;
                });


            inspeccionesService.getDañosPorPosicion($scope.InspeccionId, $scope.seccion)
                .then(function (response) {

                    //var colorInferior;
                    //var colorSuperior;
                    //var colorLateral;
                    $scope.danosTunel = response.data;
                    var diccionario = { 'Leve': 'rgba(0, 128, 0, 0.7)', 'Moderado': 'rgba(255, 255, 0, 0.7)', 'Severo': 'rgba(255, 165, 0, 0.7)', 'Critico': 'rgba(255, 0, 0, 0.7)', '-': 'rgba(25, 31, 52, 0.2)' };

                    $scope.seccionesTunelRevest = [
                        { startAngle: -Math.PI / 2, endAngle: -Math.PI / 4, color: diccionario[$scope.danosTunel[3].PeorGravedad] }, // Hastial Izq
                        { startAngle: -Math.PI / 4, endAngle: Math.PI / 4, color: diccionario[$scope.danosTunel[1].PeorGravedad] }, // Bóveda Superior
                        { startAngle: Math.PI / 4, endAngle: Math.PI / 2, color: diccionario[$scope.danosTunel[5].PeorGravedad] }, // Hastial Der
                    ];

                    $scope.seccionesTunelPP = [
                        { startAngle: -Math.PI / 2, endAngle: -Math.PI / 4, color: diccionario[$scope.danosTunel[2].PeorGravedad] }, // Hastial Izq
                        { startAngle: -Math.PI / 4, endAngle: Math.PI / 4, color: diccionario[$scope.danosTunel[0].PeorGravedad] }, // Bóveda Superior
                        { startAngle: Math.PI / 4, endAngle: Math.PI / 2, color: diccionario[$scope.danosTunel[4].PeorGravedad] }, // Hastial Der
                    ];

                    $scope.dibujarGrafico = function () {
                        var width = 700, height = 300;
                        var svg = d3.select("#tunnelChart").attr("width", width).attr("height", height);

                        // Limpiar el SVG antes de volver a dibujar
                        svg.selectAll("*").remove();

                        var centerX = width / 2, centerY = height * 0.8, radiusPP = 180, radiusRevest = 150;

                        var arcRevest = d3.arc()
                            .innerRadius(radiusPP)
                            .outerRadius(radiusPP + 30);

                        var arcPP = d3.arc()
                            .innerRadius(radiusRevest)
                            .outerRadius(radiusRevest + 30);

                        $scope.seccionesTunelRevest.forEach(function (section) {


                            svg.append("path")
                                .attr("d", arcPP({ startAngle: section.startAngle, endAngle: section.endAngle }))
                                .attr("transform", "translate(" + centerX + "," + centerY + ")")
                                .attr("fill", section.color)
                                .attr("stroke", "black").attr("stroke-width", 1);

                            //svg.append("path")
                            //    .attr("d", arcRevest({ startAngle: section.startAngle, endAngle: section.endAngle }))
                            //    .attr("transform", "translate(" + centerX + "," + centerY + ")")
                            //    .attr("fill", section.color)
                            //    .attr("stroke", "black").attr("stroke-width", 1);
                        });

                        $scope.seccionesTunelPP.forEach(function (section) {

                            svg.append("path")
                                .attr("d", arcRevest({ startAngle: section.startAngle, endAngle: section.endAngle }))
                                .attr("transform", "translate(" + centerX + "," + centerY + ")")
                                .attr("fill", section.color)
                                .attr("stroke", "black").attr("stroke-width", 1);
                        });

                        // Dibujar la base del túnel PP
                        svg.append("rect")
                            .attr("x", centerX - radiusPP - 30)
                            .attr("y", centerY)
                            .attr("width", radiusPP * 2 + 60)
                            .attr("height", 25)
                            .attr("fill", diccionario[$scope.danosTunel[7].PeorGravedad])
                            .attr("stroke", "black").attr("stroke-width", 1);


                        // Dibujar la base del túnel Revestimiento
                        svg.append("rect")
                            .attr("x", centerX - radiusPP - 30)
                            .attr("y", centerY + 25)
                            .attr("width", radiusPP * 2 + 60)
                            .attr("height", 25)
                            .attr("fill", diccionario[$scope.danosTunel[6].PeorGravedad])
                            .attr("stroke", "black").attr("stroke-width", 1);
                    };

                    angular.element(document).ready(function () {
                        $scope.dibujarGrafico();
                        $scope.ocultar('loader-seguimiento-seccion');
                    });
                });

            $scope.actualizarSeccion = function (seccionSeleccionada) {
                $scope.mostrar('loader-seguimiento-seccion');
                inspeccionesService.getDañosPorSeccionPosicion($scope.InspeccionId, seccionSeleccionada, $scope.seccion)
                    .then(function (response) {
                        //var colorInferior;
                        //var colorSuperior;
                        //var colorLateral;
                        $scope.danosTunel = response.data.Posiciones;
                        var diccionario = { 'Leve': 'rgba(0, 128, 0, 0.7)', 'Moderado': 'rgba(255, 255, 0, 0.7)', 'Severo': 'rgba(255, 165, 0, 0.7)', 'Critico': 'rgba(255, 0, 0, 0.7)', '-': 'rgba(25, 31, 52, 0.2)' };

                        $scope.seccionesTunelRevest = [
                            { startAngle: -Math.PI / 2, endAngle: -Math.PI / 4, color: diccionario[$scope.danosTunel[3].PeorGravedad] }, // Hastial Izq
                            { startAngle: -Math.PI / 4, endAngle: Math.PI / 4, color: diccionario[$scope.danosTunel[1].PeorGravedad] }, // Bóveda Superior Revestimiento
                            { startAngle: Math.PI / 4, endAngle: Math.PI / 2, color: diccionario[$scope.danosTunel[5].PeorGravedad] }, // Hastial Der
                        ];

                        $scope.seccionesTunelPP = [
                            { startAngle: -Math.PI / 2, endAngle: -Math.PI / 4, color: diccionario[$scope.danosTunel[2].PeorGravedad] }, // Hastial Izq
                            { startAngle: -Math.PI / 4, endAngle: Math.PI / 4, color: diccionario[$scope.danosTunel[0].PeorGravedad] }, // Bóveda Superior PP
                            { startAngle: Math.PI / 4, endAngle: Math.PI / 2, color: diccionario[$scope.danosTunel[4].PeorGravedad] }, // Hastial Der
                        ];

                        $scope.dibujarGrafico = function () {
                            var width = 700, height = 300;
                            var svg = d3.select("#tunnelChart").attr("width", width).attr("height", height);

                            // Limpiar el SVG antes de volver a dibujar
                            svg.selectAll("*").remove();

                            var centerX = width / 2, centerY = height * 0.8, radiusPP = 180, radiusRevest = 150;

                            var arcRevest = d3.arc()
                                .innerRadius(radiusPP)
                                .outerRadius(radiusPP + 30);

                            var arcPP = d3.arc()
                                .innerRadius(radiusRevest)
                                .outerRadius(radiusRevest + 30);

                            $scope.seccionesTunelRevest.forEach(function (section) {


                                svg.append("path")
                                    .attr("d", arcPP({ startAngle: section.startAngle, endAngle: section.endAngle }))
                                    .attr("transform", "translate(" + centerX + "," + centerY + ")")
                                    .attr("fill", section.color)
                                    .attr("stroke", "black").attr("stroke-width", 1);

                                //svg.append("path")
                                //    .attr("d", arcRevest({ startAngle: section.startAngle, endAngle: section.endAngle }))
                                //    .attr("transform", "translate(" + centerX + "," + centerY + ")")
                                //    .attr("fill", section.color)
                                //    .attr("stroke", "black").attr("stroke-width", 1);
                            });

                            $scope.seccionesTunelPP.forEach(function (section) {

                                svg.append("path")
                                    .attr("d", arcRevest({ startAngle: section.startAngle, endAngle: section.endAngle }))
                                    .attr("transform", "translate(" + centerX + "," + centerY + ")")
                                    .attr("fill", section.color)
                                    .attr("stroke", "black").attr("stroke-width", 1);
                            });

                            // Dibujar la base del túnel PP
                            svg.append("rect")
                                .attr("x", centerX - radiusPP - 30)
                                .attr("y", centerY)
                                .attr("width", radiusPP * 2 + 60)
                                .attr("height", 25)
                                .attr("fill", diccionario[$scope.danosTunel[7].PeorGravedad])
                                .attr("stroke", "black").attr("stroke-width", 1);


                            // Dibujar la base del túnel Revestimiento
                            svg.append("rect")
                                .attr("x", centerX - radiusPP - 30)
                                .attr("y", centerY + 25)
                                .attr("width", radiusPP * 2 + 60)
                                .attr("height", 25)
                                .attr("fill", diccionario[$scope.danosTunel[6].PeorGravedad])
                                .attr("stroke", "black").attr("stroke-width", 1);
                        };

                        angular.element(document).ready(function () {
                            $scope.dibujarGrafico();
                            $scope.ocultar('loader-seguimiento-seccion');
                        });
                    });
            }

            $scope.ocultar = function (loader) {
                var elemento = document.getElementById(loader);
                elemento.style.display = 'none';
            }

            $scope.mostrar = function (loader) {
                var elemento = document.getElementById(loader);
                elemento.style.display = 'block';
            }

            $scope.actualizarSeccionTamano = function () {
                $scope.mostrar('loader-seguimiento-seccion');
                inspeccionesService.getSecciones($scope.InspeccionId, $scope.seccion)
                    .then(function (response) {
                        $scope.secciones = response.data;
                    });

                // Limpiar manualmente el canvas para evitar residuos
                let canvas = document.getElementById('graficoDaniosPorSeccion');
                let ctxSec = canvas.getContext('2d');
                ctxSec.clearRect(0, 0, canvas.width, canvas.height);

                inspeccionesService.getDañosPorSeccion($scope.InspeccionId, $scope.seccion)
                    .then(function (response) {
                        $scope.dañosSeccion = response.data;

                        // Generar dinámicamente los labels y los datos basados en $scope.daños
                        const labelsSec = $scope.dañosSeccion.map(function (d) {
                            return d.Seccion || 0;
                        });

                        const dataLeveSec = $scope.dañosSeccion.map(function (d) {
                            return d.Gravedades[0].Conteo || null;
                        });

                        const dataModSec = $scope.dañosSeccion.map(function (d) {
                            return d.Gravedades[1].Conteo || null;
                        });

                        const dataSevSec = $scope.dañosSeccion.map(function (d) {
                            return d.Gravedades[2].Conteo || null;
                        });

                        const dataCritSec = $scope.dañosSeccion.map(function (d) {
                            return d.Gravedades[3].Conteo || null;
                        });



                        //const labelsSeccion = { count: 7 };
                        const dataSec = {
                            labels: labelsSec,
                            datasets: [
                                {
                                    label: 'Leve',
                                    data: dataLeveSec,
                                    backgroundColor: 'rgba(0, 128, 0, 0.7)',
                                },
                                {
                                    label: 'Moderado',
                                    data: dataModSec,
                                    backgroundColor: 'rgba(255, 255, 0, 0.7)',
                                },
                                {
                                    label: 'Severo',
                                    data: dataSevSec,
                                    backgroundColor: 'rgba(255, 165, 0, 0.7)',
                                },
                                {
                                    label: 'Critico',
                                    data: dataCritSec,
                                    backgroundColor: 'rgba(255, 0, 0, 0.7)',
                                },
                            ]
                        };

                        const configSec = {
                            type: 'bar',
                            data: dataSec,
                            options: {
                                plugins: {
                                    title: {
                                        display: true,
                                    },
                                },
                                responsive: true,
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true
                                    }
                                }
                            }
                        };

                        if ($scope.damageBarChartSeccion) {
                            $scope.damageBarChartSeccion.destroy();
                        }

                        const ctxSec = document.getElementById('graficoDaniosPorSeccion').getContext('2d');
                        $scope.damageBarChartSeccion = new Chart(ctxSec, configSec);
                        $scope.ocultar('loader-seguimiento-seccion');
                    });


                inspeccionesService.getDañosPorPosicion($scope.InspeccionId, $scope.seccion)
                    .then(function (response) {
                        //var colorInferior;
                        //var colorSuperior;
                        //var colorLateral;
                        $scope.danosTunel = response.data;
                        var diccionario = { 'Leve': 'rgba(0, 128, 0, 0.7)', 'Moderado': 'rgba(255, 255, 0, 0.7)', 'Severo': 'rgba(255, 165, 0, 0.7)', 'Critico': 'rgba(255, 0, 0, 0.7)', '-': 'rgba(25, 31, 52, 0.2)' };

                        $scope.seccionesTunelRevest = [
                            { startAngle: -Math.PI / 2, endAngle: -Math.PI / 4, color: diccionario[$scope.danosTunel[3].PeorGravedad] }, // Hastial Izq
                            { startAngle: -Math.PI / 4, endAngle: Math.PI / 4, color: diccionario[$scope.danosTunel[1].PeorGravedad] }, // Bóveda Superior
                            { startAngle: Math.PI / 4, endAngle: Math.PI / 2, color: diccionario[$scope.danosTunel[5].PeorGravedad] }, // Hastial Der
                        ];

                        $scope.seccionesTunelPP = [
                            { startAngle: -Math.PI / 2, endAngle: -Math.PI / 4, color: diccionario[$scope.danosTunel[2].PeorGravedad] }, // Hastial Izq
                            { startAngle: -Math.PI / 4, endAngle: Math.PI / 4, color: diccionario[$scope.danosTunel[0].PeorGravedad] }, // Bóveda Superior
                            { startAngle: Math.PI / 4, endAngle: Math.PI / 2, color: diccionario[$scope.danosTunel[4].PeorGravedad] }, // Hastial Der
                        ];

                        $scope.dibujarGrafico = function () {
                            var width = 700, height = 300;
                            var svg = d3.select("#tunnelChart").attr("width", width).attr("height", height);

                            // Limpiar el SVG antes de volver a dibujar
                            svg.selectAll("*").remove();

                            var centerX = width / 2, centerY = height * 0.8, radiusPP = 180, radiusRevest = 150;

                            var arcRevest = d3.arc()
                                .innerRadius(radiusPP)
                                .outerRadius(radiusPP + 30);

                            var arcPP = d3.arc()
                                .innerRadius(radiusRevest)
                                .outerRadius(radiusRevest + 30);

                            $scope.seccionesTunelRevest.forEach(function (section) {


                                svg.append("path")
                                    .attr("d", arcPP({ startAngle: section.startAngle, endAngle: section.endAngle }))
                                    .attr("transform", "translate(" + centerX + "," + centerY + ")")
                                    .attr("fill", section.color)
                                    .attr("stroke", "black").attr("stroke-width", 1);

                                //svg.append("path")
                                //    .attr("d", arcRevest({ startAngle: section.startAngle, endAngle: section.endAngle }))
                                //    .attr("transform", "translate(" + centerX + "," + centerY + ")")
                                //    .attr("fill", section.color)
                                //    .attr("stroke", "black").attr("stroke-width", 1);
                            });

                            $scope.seccionesTunelPP.forEach(function (section) {

                                svg.append("path")
                                    .attr("d", arcRevest({ startAngle: section.startAngle, endAngle: section.endAngle }))
                                    .attr("transform", "translate(" + centerX + "," + centerY + ")")
                                    .attr("fill", section.color)
                                    .attr("stroke", "black").attr("stroke-width", 1);
                            });

                            // Dibujar la base del túnel Revestimiento
                            svg.append("rect")
                                .attr("x", centerX - radiusPP - 30)
                                .attr("y", centerY + 25)
                                .attr("width", radiusPP * 2 + 60)
                                .attr("height", 25)
                                .attr("fill", diccionario[$scope.danosTunel[6].PeorGravedad])
                                .attr("stroke", "black").attr("stroke-width", 1);

                            // Dibujar la base del túnel PP
                            svg.append("rect")
                                .attr("x", centerX - radiusPP - 30)
                                .attr("y", centerY)
                                .attr("width", radiusPP * 2 + 60)
                                .attr("height", 25)
                                .attr("fill", diccionario[$scope.danosTunel[7].PeorGravedad])
                                .attr("stroke", "black").attr("stroke-width", 1);

                        };

                        angular.element(document).ready(function () {
                            $scope.dibujarGrafico();
                            $scope.ocultar('loader-seguimiento-seccion');
                        });
                    });
            }
        }
    ]);
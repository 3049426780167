angular
    .module('gis')
    .factory('gisService', ['$http', '$q', '__env', function tourvirtualService($http, $q, __env) {

        var serviceUrl = __env.apiUrl;
        var gisUrl = 'gis';

        function getGIS(id) {
            return $http.get(serviceUrl + gisUrl + '/' + id);
        }

        return {
            getGIS: getGIS
        }
    }]);

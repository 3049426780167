
var editarInfraestructura = angular.module('infraestructurasAdmin');

editarInfraestructura.controller('infraestructuraseditAdminController', ['$scope', '$routeParams', '$location', 'infraestructurasAdminService', function ($scope, $routeParams, $location, infraestructurasAdminService) {
    infraestructurasAdminService.getInfraestructura($routeParams.id)
        .then(function (response) {
            var data = response.data;
            $scope.infraestructura = data;
        });
    $scope.gestionInfraestructuras = function () {
        $location.path("/admin/infraestructurasAdmin");
    }


    infraestructurasAdminService.getCategorias()
        .then(function (response) {
            var data = response.data;
            $scope.categorias = data;
        });

    infraestructurasAdminService.getEntornos()
        .then(function (response) {
            var data = response.data;
            $scope.entornos = data;
        });

    infraestructurasAdminService.getMunicipios()
        .then(function (response) {
            var data = response.data;
            $scope.municipios = data;
        });

    infraestructurasAdminService.getIndicesInspeccion()
        .then(function (response) {
            var data = response.data;
            $scope.indicesInspeccion = data;
        });

    infraestructurasAdminService.getPropietarios()
        .then(function (response) {
            var data = response.data;
            $scope.propietarios = data;
        });

    infraestructurasAdminService.getUsuariosAsignados($routeParams.id)
        .then(function (response) {
            var data = response.data;
            $scope.usuarios = data;
        });

    infraestructurasAdminService.getRoles()
        .then(function (response) {
            var data = response.data;
            $scope.roles = data;
        });

    $scope.editarInfraestructura = function () {
        var usuariosAsignados = [];
        for (var i = 0; i < $scope.usuarios.length; i++) {
            if ($scope.usuarios[i].rolAsignado != 0) {
                usuariosAsignados.push($scope.usuarios[i]);
            }
        }
        infraestructurasAdminService.put($scope.infraestructura, usuariosAsignados, $routeParams.id).then(function (response) {
            var result = response.data;
            alert(result);
            $location.path("/admin/infraestructurasAdmin");
            //$location.path("/Admin");

        }).catch(function (response) {
            //$scope.error = infraestructurasAdminService.obtenerPrimerErrorModelState(response.data);
            //alert($scope.error);
        });
    }


    $scope.seleccionarTodos = function () {
        if (!$scope.SeleccionadosTodos) {
            $scope.SeleccionadosTodos = true;
        } else {
            $scope.SeleccionadosTodos = false;
        }
        angular.forEach($scope.usuarios, function (usuario) {
            infraestructuras.Selected = $scope.SeleccionadosTodos;
        });

    };

}]);
angular.module('login', [])
    .controller('loginController', ['$scope', '$rootScope', 'authenticationInfo', 'authenticationService', '$location', function ($scope, $rootScope, authenticationInfo, authenticationService, $location) {
        $scope.authentication = authenticationInfo;
        $scope.authentication.isAuthenticated = false;
        $scope.usuario = authenticationInfo.userName;

        $scope.login = function () {
            //var aux = $location.path();
            //$location.path('/infraestructuras');
            //aux = $location.path();
            //$scope.$apply();
            return authenticationService.login({
                userName: $scope.usuario,
                password: $scope.password
            }).then(function () {
                //$scope.authentication = authenticationInfo;
                if (authenticationInfo.isAuthenticated == true) {
                    $scope.authentication.isAuthenticated = true;
                    $rootScope.usuario = $scope.usuario;
                    $location.path('/login2FA');
                }
                else {
                    alert("Usuario o Contraseña incorrecta");
                }
                //var login = document.getElementById('login');
                //login.remove();
            });
        }
    }]);
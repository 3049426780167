
var editarMunicipio = angular.module('municipiosAdmin');

editarMunicipio.controller('municipioAdminController', ['$scope', '$routeParams', '$location', 'municipiosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, municipiosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    municipiosAdminService.getMunicipio($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.municipio = data;
                $scope.descripcion = data.Descripcion;
            });

    $scope.gestionMunicipios = function () {
        $location.path("/admin/municipiosAdmin");
    }

    $scope.editarMunicipio = function () {
        municipiosAdminService.put($scope.descripcion, $scope.municipio.Id).then(function (response) {
                var result = response.data;
                alert(result);
                //$location.path("/admin/usuarios");
            $location.path("/admin/municipiosAdmin");

            }).catch(function (response) {
                $scope.error = municipiosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }


    }]);
angular.module('auth')
    .factory('authInterceptor', ['$q', '$rootScope', 'authEvents', 'authenticationInfo', function ($q, $rootScope, authEvents, authenticationInfo) {

        function request(config) {

            config.headers = config.headers || {};

            addAuthorizationToHeaders(config.headers);

            return config;
        }

        function addAuthorizationToHeaders(headers) {
            if (!authenticationInfo.isAuthenticated)
                return;

            headers.Authorization = 'Bearer ' + authenticationInfo.token;
        }

        function responseError(rejection) {
            if (rejection.status === 401) {
                authenticationInfo.clear();

                $rootScope.$broadcast(authEvents.logout);
            }

            return $q.reject(rejection);
        }

        return {
            request: request,
            responseError: responseError
        }

    }])

    //.config(['$httpProvider', function ($httpProvider) {
    //    $httpProvider.interceptors.push('authInterceptor');
    //}])
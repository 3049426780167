var app = angular
    .module('partes')
    .factory('partesService', ['$http', '$q', '__env', '$routeParams', function partesService($http, $q, __env, $routeParams) {
        var serviceUrl = __env.apiUrl;
        var partesUrl = 'partes';


        function getInfraestructura(idInfraestructura) {
            return $http.get(serviceUrl + '/infraestructuras/cabecera/' + idInfraestructura);
        }


        function getPartes() {
            return $http.get(serviceUrl + partesUrl + '/obra/' + $routeParams.id);
        }


        function getPartesObra() {
            return $http.get(serviceUrl + '/partes/obra/' + $routeParams.id);
        }


        function getParte(idInfraestructura, idParte) {
            return $http.get(serviceUrl + partesUrl + '/' + idInfraestructura + '/' + idParte);
        }

        function getTajoPorParte(idParte) {
            return $http.get(serviceUrl + '/tajo/parte/' + idParte);

        }


        function getTiposTajo(InfraestructuraId) {
            return $http.get(serviceUrl + 'partes/tipostajo/' + InfraestructuraId);

        }

        function getImagenesPorParte(idParte) {
            return $http.get(serviceUrl + 'partes/imagenes/parte/' + idParte);

        }

        //function getTajosParte() {
        //    return $http.get(serviceUrl + 'tajosparte');
        //}


        function crearParteDiario(fecha, obraId) {
            return $http({
                method: 'post',
                url: serviceUrl + partesUrl + '/crear/',
                params:
                {
                    'fechaParte': fecha,
                    'obraId': obraId
                }

            });
        }

        function crearParteCompleto(parte, obraId) { 
        return $http({
            method: 'post',
            url: serviceUrl + partesUrl + '/crearCompleto/',
            params:
            {
                
                'obraId': obraId
            },
            data: JSON.stringify(parte),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

        function postParte(InfraestructuraId, parte, ParteId, tajo) {
            return $http.post(serviceUrl + '/partes/post/' + InfraestructuraId + '/' + ParteId, partetajo);
        }


        function putParte(parte, tajos, formDataTexto) {
            //return $http.put(serviceUrl + partesUrl + '/put/');
            return $http({
                method: 'put',
                url: serviceUrl + partesUrl + '/put/',
                params:
                {
                    'parte2': JSON.stringify(parte),
                    //'tajos2': JSON.stringify(tajos),
                    //'imagenes': JSON.stringify(imagenes),
                },
                data: formDataTexto,
                headers: { 'Content-Type': undefined },
            });
        }


        function eliminarTajo(tajo) {
            //return $http.put(serviceUrl + partesUrl + '/put/');
            return $http({
                method: 'post',
                url: serviceUrl + partesUrl + '/tajo/eliminar/',
                params:
                {
                    'tajo': JSON.stringify(tajo),
                    //'imagenes': JSON.stringify(imagenes),
                }
            });
        }

        function verParteDiario(path, nombre) {
            return $http({
                method: 'post',
                url: serviceUrl + partesUrl + '/',
                params:
                {
                    'parte': path + '/' + nombre,

                }

            });
        }


        function modificarParteDiario(path, nombre) {
            return $http({
                method: 'post',
                url: serviceUrl + partesUrl + '/',
                params:
                {
                    'parte': path + '/' + nombre,

                }

            });
        }


        function eliminarParteDiario(parteId) {
            return $http({
                method: 'post',
                url: serviceUrl + partesUrl + '/eliminarParte/' + parteId
            });
        }


        function postImagenes(imagenes, tajoImagenes) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'partes/tajo/imagenes/post/',
                data: imagenes,
                headers: { 'Content-Type': undefined },
                params:{
                    'tajoImagenes': tajoImagenes,
                }
            });
        }



        function postDatosTajosPartes(idParte, idTajo) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'partes/tajo/post/' + idParte + '/' + idTajo,
                headers: { 'Content-Type': undefined }
            });
        }



        function getImagenesParte(idParte, idTajo) {
            return $http.get(serviceUrl + 'partes/imagenes/' + idParte + '/' + idTajo);
        }

        function crearTipoTajo(parteId, Descripcion, infraestructuraId) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'partes/tipotajo/' + parteId,
                params:
                {
                    'descripcion': Descripcion,
                    'infraestructuraId': infraestructuraId
                }
            });
        }

        function imagenEliminar(nombre, idTajo, ParteId) {
            return $http({
                method: 'post',
                url: serviceUrl + 'partes/imagenes/eliminar',
                params:
                {
                    'nombre': nombre,
                    'idTajo': idTajo,
                    'ParteId': ParteId
                }
            });
        }


        function imprimirSeguimiento(inicio, fin, opciones, idInfraestructura) {
            return $http({
                method: 'post',
                url: serviceUrl + 'partes/seguimiento',
                responseType: 'blob',
                params:
                {
                    'inicio': inicio,
                    'fin': fin,
                    'opciones': JSON.stringify(opciones),
                    'idInfraestructura': idInfraestructura
                }
            });
        }

        function getRol(usuario, infraestructura) {
            return $http({
                method: 'get',
                url: serviceUrl + partesUrl + '/rol/',
                params:
                {
                    'usuario': usuario,
                    'infraestructura': infraestructura
                }
            });
        }


        return {
            getInfraestructura: getInfraestructura,
            getPartes: getPartes,
            getParte: getParte,
            getPartesObra: getPartesObra,
            crearParteDiario: crearParteDiario,
            crearParteCompleto: crearParteCompleto,
            verParteDiario: verParteDiario,
            modificarParteDiario: modificarParteDiario,
            eliminarParteDiario: eliminarParteDiario,
            getTajoPorParte: getTajoPorParte,
            postImagenes: postImagenes,
            getImagenesParte: getImagenesParte,
            getTiposTajo: getTiposTajo,
            postParte: postParte,
            putParte: putParte,
            //getTajosParte: getTajosParte,
            postDatosTajosPartes: postDatosTajosPartes,
            getImagenesPorParte: getImagenesPorParte,
            crearTipoTajo: crearTipoTajo,
            imagenEliminar: imagenEliminar,
            eliminarTajo: eliminarTajo,
            imprimirSeguimiento: imprimirSeguimiento,
            getRol: getRol
        }
    }]);
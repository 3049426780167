//const { forEach } = require("lodash");

var app = angular.module('partes', []);
app.controller('PartesController', ['$scope', '$rootScope', '$routeParams', '$location', 'partesService', 'infraestructurasService', 'authenticationInfo', 'authenticationService',
    function ($scope, $rootScope, $routeParams, $location, partesService, infraestructurasService, authenticationInfo, authenticationService) {

    //$scope.documentosSeleccionados = ""
    //$scope.SelectedFiles = new Array();
    //if ($scope.RutaActual == 'undefined') then
    //{
    //    $scope.rutaActual = "E:\\InfraestructurasArchivos\\" + $routeParams.id;
    //    $scope.SistemaArchivosRuta = [];
    //    $scope.SistemaArchivosNombre = [];
    //    $scope.SistemaArchivosRuta[0] = "E:\\InfraestructurasArchivos\\" + $routeParams.id;
    //    //$scope.SistemaArchivosNombre[0] = "root";
    //    infraestructurasService.getInfraestructura($routeParams.id)
    //    .then(function (response) {
    //        var data = response.data;
    //        $scope.auxiliar = data;
    //        $scope.SistemaArchivosNombre[0] = $scope.auxiliar.Descripcion;
    //    });


    //    $scope.SistemaArchivosLong = 0;
    //}
    $scope.authentication = authenticationInfo;
    $scope.InfraestructuraId = $routeParams.id;

    partesService.getRol($rootScope.usuario, $scope.InfraestructuraId)
        .then(function (response) {
            $scope.permiso = response.data;
        });


    partesService.getPartes($scope.idObra)
        .then(function (response) {
            var data = response.data;
            $scope.partes = data;
        });

        $scope.crearParteDiario = function (fechaparte) {
        //var fechaparte = prompt('Fecha del parte:', 'Fecha del parte');

        partesService.crearParteDiario(fechaparte, $routeParams.id)
            .then(function (response) {
                //partesService.getPartesByPath($scope.rutaActual)
                //documentosService.getDocumentosByInf($routeParams.id)
                //.then(function (response) {
                var data = response.data;
                $scope.parteId = data;
                $location.url('/partes/' + $routeParams.id + "/" + $scope.parteId);
                /*});*/
            });
        };

        $scope.copiarParte = function () {
            var partes = $scope.partes;
            //var ultimoParteId = partes.reduce((max, parte) => parte.id > max ? parte.id : max, partes[0].id);
            var ultimoParte = partes.reduce(function (maxParte, parte) {
                return parte.Id > maxParte.Id ? parte : maxParte;
            }, partes[0]);

            partesService.crearParteCompleto(ultimoParte, $routeParams.id)
                .then(function (response) {
                    var ultimaId = response.data;
                    $location.url('/partes/' + $routeParams.id + "/" + ultimaId);
                });
        };


        $scope.fechaparte;
        $scope.$watch("fechaparte", function (newValue, oldValue) {
            partesService.crearParteDiario($scope.fechaparte, $routeParams.id)
                .then(function (response) {
                    //partesService.getPartesByPath($scope.rutaActual)
                    //documentosService.getDocumentosByInf($routeParams.id)
                    //.then(function (response) {
                    var data = response.data;
                    $scope.parteId = data;
                    $location.url('/partes/' + $routeParams.id + "/" + $scope.parteId);
                    /*});*/
                });
        });



    $scope.verParte = function (idParte) {
        //$location.url('/partes/' + idParte);

        $location.url('/partes/' + $routeParams.id + "/" + idParte);
    }

    //$scope.modificarParteDiario = function () {
    //    // var fechaparte = prompt('Fecha del parte:', 'Fecha del parte');

    //    partesService.modificarParteDiario($scope.rutaActual)
    //        .then(function (response) {
    //            partesService.getPartesByPath($scope.rutaActual)
    //                //documentosService.getDocumentosByInf($routeParams.id)
    //                .then(function (response) {
    //                    var data = response.data;
    //                    $scope.documentacion = data;
    //                });
    //        });
    //};


    $scope.eliminarParteDiario = function (idParte) {
        // var fechaparte = prompt('Fecha del parte:', 'Fecha del parte');
        check = confirm("¿Seguro que deseas eliminar este parte?")

        if (check) {
            partesService.eliminarParteDiario(idParte)
                .then(function (response) {
                    partesService.getPartes($scope.idObra)
                        .then(function (response) {
                            var data = response.data;
                            $scope.partes = data;
                        });
                });
        }
    };


    $scope.QuitarArchivo = function (file) {

        $scope.SelectedFiles = $scope.SelectedFiles.filter(function (documento) {
            return documento !== file;
        });
    };

    $scope.editarDocumento = function () {
        var nuevoNombre = prompt('Desea cambiar el nombre del documento/carpeta ?', $rootScope.doc.Nombre);
        var extenLong = $rootScope.doc.Extension.length;
        var extenNueva = nuevoNombre.slice(-extenLong);
        if (extenNueva == $rootScope.doc.Extension) {
            confirmacion = true;
        }
        else {
            if (extenLong == 0) {
                partesService.editarCarpeta($rootScope.doc.Path, nuevoNombre, $rootScope.doc.Nombre)
                    .then(function (response) {
                        partesService.getPartesByPath($scope.rutaActual)
                            //documentosService.getDocumentosByInf($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.documentacion = data;
                                $scope.SelectedFiles = new Array();
                            });
                    });
            }
            else {
                confirmacion = confirm('Esta seguro de que quiere cambiar la extensión del documento?')
            }
        }
        if (confirmacion) {
            partesService.editarDocumento($rootScope.doc.Path, nuevoNombre, $rootScope.doc.Nombre)
                .then(function (response) {
                    partesService.getPartesByPath($scope.rutaActual)
                        //documentosService.getDocumentosByInf($routeParams.id)
                        .then(function (response) {
                            var data = response.data;
                            $scope.documentacion = data;
                            $scope.SelectedFiles = new Array();
                        });
                });
        }
    };


    $scope.eliminar = function () {
        confirmacion = confirm('Esta seguro de que quiere eliminar el documento\carpeta?')
        if (confirmacion) {
            $scope.documentosSeleccionados = "";
            angular.forEach($scope.documentacion, function (documento) {
                if (documento.Selected) {
                    var extenLong = documento.Extension.length;
                    if (extenLong == 0 && confirmacion) {
                        partesService.eliminarCarpeta(documento.Path)
                            .then(function (response) {
                                partesService.getPartesByPath($scope.rutaActual)
                                    //documentosService.getDocumentosByInf($routeParams.id)
                                    .then(function (response) {
                                        var data = response.data;
                                        $scope.documentacion = data;
                                        $scope.SelectedFiles = new Array();
                                    });
                            });
                    }

                    if (extenLong != 0 && confirmacion) {
                        partesService.eliminarDocumento(documento.Path)
                            .then(function (response) {
                                partesService.getPartesByPath($scope.rutaActual)
                                    //documentosService.getDocumentosByInf($routeParams.id)
                                    .then(function (response) {
                                        var data = response.data;
                                        $scope.documentacion = data;
                                        $scope.SelectedFiles = new Array();
                                    });
                            });
                    }
                };
            });
        }
    }

    $scope.eliminarDocumento = function () {
        var extenLong = $rootScope.doc.Extension.length;
        confirmacion = confirm('Esta seguro de que quiere eliminar el documento?')
        if (extenLong == 0 && confirmacion) {
            partesService.eliminarCarpeta($rootScope.doc.Path)
                .then(function (response) {
                    partesService.getPartesByPath($scope.rutaActual)
                        //documentosService.getDocumentosByInf($routeParams.id)
                        .then(function (response) {
                            var data = response.data;
                            $scope.documentacion = data;
                            $scope.SelectedFiles = new Array();
                        });
                });
        }

        if (extenLong != 0 && confirmacion) {
            partesService.eliminarDocumento($rootScope.doc.Path)
                .then(function (response) {
                    partesService.getPartesByPath($scope.rutaActual)
                        //documentosService.getDocumentosByInf($routeParams.id)
                        .then(function (response) {
                            var data = response.data;
                            $scope.documentacion = data;
                            $scope.SelectedFiles = new Array();
                        });
                });
        }
    };


    $scope.seleccionarDocumento = function (documento) {
        alert(documento.Nombre);
        $scope.documentoMenu = documento;
    };


    $scope.subirArchivos = function () {
        var aux = $scope.SelectedFiles;
        var auxFile;
        var auxData;

        angular.forEach($scope.SelectedFiles, function (file) {

            auxFiles = file;

            partesService.upload(auxFiles, $scope.rutaActual)
                .then(function (response) {
                    partesService.getPartesByPath($scope.rutaActual)
                        //documentosService.getDocumentosByInf($routeParams.id)
                        .then(function (response) {
                            var data = response.data;
                            $scope.documentacion = data;
                            $scope.SelectedFiles = new Array();
                        });
                });
        });

    };

    $scope.abrirDocumento = function () {
        //var tipoMime = getTipoMime(documento.Extension);
        if ($rootScope.doc.Extension == "") {
            $scope.SistemaArchivosLong = $scope.SistemaArchivosLong + 1;
            $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong] = $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong - 1] + "\\" + $rootScope.doc.Nombre;
            $scope.SistemaArchivosNombre[$scope.SistemaArchivosLong] = $rootScope.doc.Nombre;
            $scope.rutaActual = $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong];
            partesService.getPartesByPath($scope.rutaActual)
                //documentosService.getDocumentosByInf($routeParams.id)
                .then(function (response) {
                    var data = response.data;
                    $scope.documentacion = data;
                });
        }
        else {
            partesService.descargarDocumentosByPath($rootScope.doc.Path)
                .then(function (data, status, headers) {

                    var contentType = null;
                    var filename = $rootScope.doc.Nombre;
                    for (i = 0; i < mime.length; i++) {
                        if (mime[i][0] == $rootScope.doc.Extension) {
                            contentType = mime[i][1];
                        }
                    }


                    var blob = new Blob([data.data], { type: contentType });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, $rootScope.doc.Nombre);
                    } else {
                        var fileURL = URL.createObjectURL(blob);
                        window.open(fileURL);
                    }

                });
        }
    };


}]);

app.directive('context', [
    '$rootScope',
    function ($rootScope) {
        return {
            restrict: 'A',
            scope: '@&',
            compile: function compile(tElement, tAttrs, transclude) {
                return {
                    post: function postLink(scope, iElement, iAttrs, controller) {
                        var ul = $('#' + iAttrs.context),
                            last = null;

                        ul.css({
                            'display': 'none'
                        });
                        $(iElement).bind('contextmenu', function (event) {
                            $rootScope.doc = scope.documento;
                            event.preventDefault();
                            ul.css({
                                position: "fixed",
                                display: "block",
                                left: event.clientX + 'px',
                                top: event.clientY + 'px'
                            });
                            last = event.timeStamp;
                        });
                        //$(iElement).click(function(event) {
                        //  ul.css({
                        //    position: "fixed",
                        //    display: "block",
                        //    left: event.clientX + 'px',
                        //    top: event.clientY + 'px'
                        //  });
                        //  last = event.timeStamp;
                        //});
                        //alert(element);
                        //alert(scope.documento.Path);
                        //$rootScope.doc = scope.documento;
                        $(document).click(function (event) {
                            var target = $(event.target);
                            if (!target.is(".popover") && !target.parents().is(".popover")) {
                                if (last === event.timeStamp)
                                    return;
                                ul.css({
                                    'display': 'none'
                                });
                            }

                        });
                    }
                };
                alert(element);
            }
        };
    }
]);


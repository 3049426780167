
var editarCategoria = angular.module('categoriaAdmin');

editarCategoria.controller('categoriaAdminController', ['$scope', '$routeParams', '$location', 'categoriaAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, categoriaAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    var formData = new FormData();
    $scope.imagenNueva = false;
    $scope.imagenHooverNueva = false;
    $scope.imagenDisabledNueva = false;

    categoriaAdminService.getCategoriaAdmin($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.categoria = data;
                //var imagen = { FileName: data.Descripcion + "Imagen", Imagen: "data:image/svg+xml;base64,/" + data.RutaImagen };
                //var imagenHoover = { FileName: data.Descripcion + "ImagenHoover", Imagen: "data:image/svg+xml;base64," + data.RutaImagenHoover };
                //var imagenDisabled = { FileName: data.Descripcion + "ImagenDisabled", Imagen: "data:image/svg+xml;base64," + data.RutaImagenDisabled };
                var imagen = { FileName: data.Descripcion + "Imagen", Imagen: data.RutaImagen };
                var imagenHoover = { FileName: data.Descripcion + "ImagenHoover", Imagen: data.RutaImagenHoover };
                var imagenDisabled = { FileName: data.Descripcion + "ImagenDisabled", Imagen: data.RutaImagenDisabled };
                $scope.imagen = imagen;
                $scope.imagenHoover = imagenHoover;
                $scope.imagenDisabled = imagenDisabled;
                $scope.imagenSubida = true;
                $scope.imagenHooverSubida = true;
                $scope.imagenDisabledSubida = true;
            });

    $scope.gestionCategorias = function () {
        $location.path("/admin/categoriaAdmin");
    }


    $scope.editarCategoria = function () {
        categoriaAdminService.put($scope.categoria, formData, $scope.imagenNueva, $scope.imagenHooverNueva, $scope.imagenDisabledNueva).then(function (response) {
                var result = response.data;
                alert("Categoria guardada correctamente");
                //$location.path("/admin/usuarios");
            $location.path("/admin/categoriaAdmin");

            }).catch(function (response) {
                $scope.error = categoriaAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }

    $scope.getImagen = function (file) {

        //var formData = new FormData();
        formData.append(0, file);
        //$scope.FormData = formData;
        r = new FileReader();
        $scope.imagen = null;
        r.onloadend = function (e) { //callback after files finish loading
            var b64 = e.target.result;
            var imagen = { FileName: file.name, Imagen: b64 };
            $scope.imagen = imagen;
            $scope.imagenNueva = true;
            categoriaAdminService.getCategorias()
                .then(function (response) {
                    var data = response.data;
                    $scope.categorias = data;
                });
        }
        r.readAsDataURL(file); //once defined all callbacks, begin reading the file
    };

    $scope.getImagenHoover = function (file) {

        //var formData = new FormData();
        formData.append(1, file);
        //$scope.FormData = formData;
        r = new FileReader();
        $scope.imagenHoover = null;
        r.onloadend = function (e) { //callback after files finish loading
            var b64 = e.target.result;
            var imagen = { FileName: file.name, Imagen: b64 };
            $scope.imagenHoover = imagen;
            $scope.imagenHooverNueva = true;
            categoriaAdminService.getCategorias()
                .then(function (response) {
                    var data = response.data;
                    $scope.categorias = data;
                });
        }
        r.readAsDataURL(file); //once defined all callbacks, begin reading the file
    };

    $scope.getImagenDisabled = function (file) {

        //var formData = new FormData();
        formData.append(2, file);
        //$scope.FormData = formData;
        r = new FileReader();
        $scope.imagenDisabled = null;
        r.onloadend = function (e) { //callback after files finish loading
            var b64 = e.target.result;
            var imagen = { FileName: file.name, Imagen: b64 };
            $scope.imagenDisabled = imagen;
            $scope.imagenDisabledNueva = true;
            categoriaAdminService.getCategorias()
                .then(function (response) {
                    var data = response.data;
                    $scope.categorias = data;
                });
        }
        r.readAsDataURL(file); //once defined all callbacks, begin reading the file
    };


    }]);
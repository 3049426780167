var infraestructurasAdminService = angular.module('infraestructurasAdmin');

infraestructurasAdminService.factory('infraestructurasAdminService', ['$http', '$q', '__env', function infraestructurasAdminService($http, $q, __env) {
    var serviceUrl = __env.apiUrl;
    var usuariosUrl = 'usuarios';
    var rolesUrl = 'roles';
    var infraestructurasUrl = 'infraestructuras';
    var categoriasUrl = "categorias";
    var damagesUrl = "damages";
    var inspeccionesUrl = "inspecciones";



    function getInfraestructuras() {
        return $http.get(serviceUrl + infraestructurasUrl);
    }

    function getInfraestructura(id) {
        return $http.get(serviceUrl + infraestructurasUrl + '/Admin/' + id);
    }

    function put(infraestructura, usuarios, idinfraestructura) {
        //return $http.put(serviceUrl + usuariosUrl + '/editar/' + idRolUsuario, usuario);
        return $http({
            method: 'put',
            url: serviceUrl + infraestructurasUrl + '/Admin/registrar/',
            params:
            {
                //'Id': infraestructura.Id,
                //'Nombre': infraestructura.Nombre,
                //'Categoria': infraestructura.CategoriaId,
                //'CodIdentificador': infraestructura.CodIdentificador,
                //'Descripcion': infraestructura.Descripcion,
                //'IndiceInspeccion': infraestructura.IndiceInspeccion.Id,
                //'MunicipioId': infraestructura.Municipio.Id,
                //'EntornoId': infraestructura.Entorno.Id,
                //'Propietario': infraestructura.Propietario.Id,
                //'FechaUltimaInspeccion': infraestructura.FechaUltimaInspeccion,
                //'PosicionamientoXInfraestructura': infraestructura.PosicionamientoXInfraestructura,
                //'PosicionamientoYInfraestructura': infraestructura.PosicionamientoYInfraestructura,
                'infraestructura': JSON.stringify(infraestructura),
                'usuarios': JSON.stringify(usuarios),
                'idinfraestructura': idinfraestructura
            }
        });
    }

    function post(infraestructura, imagen) {
        //return $http.post(serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario, usuario);
        return $http({
            method: 'post',
            url: serviceUrl + infraestructurasUrl + "/registrar",
            params:
            {
                'infraestructura': JSON.stringify(infraestructura),
            },
            data: imagen,
            headers: { 'Content-Type': undefined }
        });
    }

    function obtenerPrimerErrorModelState(response) {
        var errores = [];
        for (var key in response.ModelState) {
            for (var i = 0; i < response.ModelState[key].length; i++) {
                errores.push(response.ModelState[key][i]);
            }
        }
        return errores[0];
    }
    function eliminarInfraestructuras(infraestructurasEliminar) {
        return $http.post(serviceUrl + infraestructurasUrl + '/eliminar/' + infraestructurasEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
            alert('Error');
        });
    }

    function getCategorias() {
        return $http.get(serviceUrl + categoriasUrl);
    }
    function getEntornos() {
        return $http.get(serviceUrl + categoriasUrl + '/entornos');
    }

    function getTiposTunel() {
        return $http.get(serviceUrl + tipostunelUrl);
    }

    function getMunicipios() {
        return $http.get(serviceUrl + categoriasUrl + '/municipios');
    }

    function getIndicesInspeccion() {
        return $http.get(serviceUrl + categoriasUrl + '/indiceInspeccion');
    }

    function getPropietarios() {
        return $http.get(serviceUrl + categoriasUrl + '/propietarios');
    }

    function getInspectores() {
        return $http.get(serviceUrl + categoriasUrl + '/inspectores');
    }


    function getRoles() {
        return $http.get(serviceUrl + categoriasUrl + '/roles');
    }

    function getUsuariosAsignados(idInfraestructura) {
        return $http.get(serviceUrl + usuariosUrl + '/usuariosAsignados/' + idInfraestructura);
    }

    function getTiposDaño() {
        return $http.get(serviceUrl + inspeccionesUrl + '/tiposdamage');
    }

    function getElementos() {
        return $http.get(serviceUrl + inspeccionesUrl + '/elementos');
    }

    function getCategoriasDaño() {
        return $http.get(serviceUrl + damagesUrl + '/categoriasdano');
    }

    function getIntensidades() {
        return $http.get(serviceUrl + damagesUrl + '/intensidades');
    }

    function getEvoluciones() {
        return $http.get(serviceUrl + inspeccionesUrl + '/evolucion');
    }

    return {
        getInfraestructuras: getInfraestructuras,
        getInfraestructura: getInfraestructura,
        put: put,
        post: post,
        obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
        eliminarInfraestructuras: eliminarInfraestructuras,
        getCategorias: getCategorias,
        getEntornos: getEntornos,
        getMunicipios: getMunicipios,
        getIndicesInspeccion: getIndicesInspeccion,
        getPropietarios: getPropietarios,
        getUsuariosAsignados: getUsuariosAsignados,
        getRoles: getRoles,
        getTiposTunel: getTiposTunel,
        getInspectores: getInspectores,
        getTiposDaño: getTiposDaño,
        getElementos: getElementos,
        getCategoriasDaño: getCategoriasDaño,
        getIntensidades: getIntensidades,
        getEvoluciones: getEvoluciones
    }

}]);
var municipiosAdminService = angular.module('municipiosAdmin');

municipiosAdminService.factory('municipiosAdminService', ['$http', '$q', '__env', function municipiosAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var categoriasUrl = 'categorias';
        var rolesUrl = 'roles';

        function getMunicipios() {
            return $http.get(serviceUrl + categoriasUrl + '/municipios');
        }

    function getMunicipio(id) {
        return $http.get(serviceUrl + categoriasUrl + '/municipios/' + id);
        }

        function put(descripcion, id) {
            return $http({
                method: 'put',
                url: serviceUrl + categoriasUrl + '/editar/municipios/' + id,
                params:
                {
                    'municipio': descripcion
                }
            });
        }

    function post(municipio) {
            //return $http.post(serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario, usuario);
            return $http({
                method: 'post',
                url: serviceUrl + categoriasUrl + '/registrar/municipios',
                params:
                {
                    'municipio': municipio
                }

            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }
    function eliminarMunicipios(municipiosEliminar) {
        return $http.post(serviceUrl + categoriasUrl + '/eliminarMunicipios/' + municipiosEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
                alert('Error');
            });
        }



        return {
            getMunicipios: getMunicipios,
            getMunicipio: getMunicipio,
            put: put,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarMunicipios: eliminarMunicipios,


        }

    }]);
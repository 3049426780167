var indiceInspeccionAdminService = angular.module('indiceInspeccionAdmin');

indiceInspeccionAdminService.factory('indiceInspeccionAdminService', ['$http', '$q', '__env', function indiceInspeccionAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var categoriasUrl = 'categorias';
        var rolesUrl = 'roles';

    function getIndicesInspeccion() {
        return $http.get(serviceUrl + categoriasUrl + '/indiceInspeccion');
        }

    function getIndiceInspeccion(id) {
        return $http.get(serviceUrl + categoriasUrl + '/indiceInspeccion/' + id);
        }

        function put(descripcion, color, id) {
            return $http({
                method: 'put',
                url: serviceUrl + categoriasUrl + '/editar/indiceInspeccion/' + id,
                params:
                {
                    'indiceInspeccion': descripcion,
                    'color': color
                }
            });
        }

    function post(descripcion, color) {
            //return $http.post(serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario, usuario);
            return $http({
                method: 'post',
                url: serviceUrl + categoriasUrl + '/registrar/indiceInspeccion',
                params:
                {
                    'indiceInspeccion': descripcion,
                    'color': color
                }

            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }
    function eliminarIndiceInspeccion(entornosEliminar) {
        return $http.post(serviceUrl + categoriasUrl + '/eliminarIndiceInspeccion/' + entornosEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
                alert('Error');
            });
        }



        return {
            getIndicesInspeccion: getIndicesInspeccion,
            getIndiceInspeccion: getIndiceInspeccion,
            put: put,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarIndiceInspeccion: eliminarIndiceInspeccion,


        }

    }]);
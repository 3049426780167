var app = angular.module('sensores');
app.controller('MapController', ['$scope',
    function ($scope) {
        var latlng = new google.maps.LatLng(43.20523189138532, -2.8637797716569295);
        var latlng2 = new google.maps.LatLng(43.206436195621386, -2.863221902844752);
        var latlng3 = new google.maps.LatLng(43.21863318484819, -2.7325404097610777);
        var latlng4 = new google.maps.LatLng();
        var latlng5 = new google.maps.LatLng();
        var latlng6 = new google.maps.LatLng
        // definimos valor por defecto
        var myOptions = {
            zoom: 14,
            center: latlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        // generamos el mapa. contentString es el recuduadro
        var map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
        const contentStringArnotegui =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<img src="../../images/fotoSensorAire.jpg"' +
            'alt="sesnor de aire situado en Arnotegui"' +
            'width="150"' +
            'height="150">' +
            '<h1 id="firstHeading" class="firstHeading"></h1>' +
            '<div id="bodyContent">' +
            '<p><b>Urdinbide-Aire<br></b>' +
            '<br>Nombre: Nodo 2059E7<br>' +
            'Tipo: Sensor de aire<br>' +
            'Ubicacion: Urdinbide<br>' +
            '</p > ' +
            // '<figure>'+
            //'</figure >'+
            "</div>" +
            "</div>";
        const contentStringArnotegui2 =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<img src="../../images/SensorAireArnotegui.jpg"' +
            'alt="senor de aire situado en el emboquille del tunel"' +
            'width="150"' +
            'height="150">' +
            '<h1 id="firstHeading" class="firstHeading"></h1>' +
            '<div id="bodyContent">' +
            '<p><b>Urdinbide-Aire<br></b>' +
            '<br>Nombre: Nodo XXXX<br>' +
            'Tipo: Sensor de aire<br>' +
            'Ubicacion: Urdinbide<br>' +
            '</p > ' +
            // '<figure>'+
            //'</figure >'+
            "</div>" +
            "</div>";
        const contenStringUrdinbide =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<img src="../../images/IMG_4965.JPG"' +
            'alt="sensor de aire situado en Urdinbide"' +
            'width="150"' +
            'height="150">' +
            '<h1 id="firstHeading" class="firstHeading"></h1>' +
            '<div id="bodyContent">' +
            '<p><b>Urdinbide-Aire<br></b>' +
            '<br>Nombre: Nodo XXXX<br>' +
            'Tipo: Sensor de aire<br>' +
            'Ubicacion: Urdinbide<br>' +
            '</p > ' +
            // '<figure>'+
            //'</figure >'+
            "</div>" +
            "</div>";

        const infowindow = new google.maps.InfoWindow({
            content: contentStringArnotegui,
        });
        const infowindow2 = new google.maps.InfoWindow({
            content: contentStringArnotegui2,
        });

        // añadimos marcas
        var marker = new google.maps.Marker({
            position: latlng,
            title: 'TunelUrdinbideAire',
            draggable: false
        });
        var marker3 = new google.maps.Marker({
            position: latlng3,
            title: 'EstacionAmorebieta',
            draggable: false
        });
        var marker2 = new google.maps.Marker({
            position: latlng2,
            title: 'TunelUrdinbideRuido',
            draggable: false
        });
        //listener de las marcas
        marker.addListener("mouseover", function(o) {
            infowindow.open(map, marker);
        });
        marker2.addListener("mouseover",function(o) {
            infowindow2.open(map, marker2);
        });
        marker3.addListener("mouseover", function(o) {
            infowindow3.open(map, marker3);
        });
        //lo situamos en el mapa
        marker.setMap(map);
        marker2.setMap(map);
        marker3.setMap(map);
        //añadimos popup a las marcas
        var popup = new google.maps.InfoWindow({
            content: 'TunelUrdinbideAire',
            position: latlng
        });
        var popup2 = new google.maps.InfoWindow({
            content: 'TunelUrdinbideRuido',
            position: latlng2
        });
        var popup3 = new google.maps.InfoWindow({
            content: 'EstacionAmorebieta',
            position: latlng3
        });
    }]);
angular.module('TV', [])
    .controller('TVController', ['$scope', '$routeParams', 'TVService', '$sce', function ($scope, $routeParams, TVService, $sce) {
        //let myviewer;
        TVService.getToken()
            .then(function (response) { });

        TVService.getSitio($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.sitio = data;
                $scope.sitioUrl = $sce.trustAsHtml($scope.sitio.sitio);
                });
        //$scope.sitioUrl = $sce.trustAsHtml("<iframe id='609beffceede9c6fb8aef8e7' width='560' height='316' src='http://87.106.30.6:9000/embed/map/609beffceede9c6fb8aef8e7?tools=false&minimap=false' frameborder='0'></iframe>")

        //$scope.scanid = '01d30152-e3a8-4872-b05f-8c9e85505d7c'
        //$scope.tvserver = 'http://87.106.30.6:9000'

        //function onMouseClick(obj) {
        //    if (obj.type== 'marker')
        //    {
        //        createviewer($scope.tvserver, obj.guid)
        //    }

        //}

        //function tvinit(err, viewer) {
        //    if (err) {
        //        alert('TruView Viewer initialization failed: ' + err.message);
        //        return;
        //    }
        //    viewer.mouseClick(onMouseClick);
        //    if (myviewer) myviewer.dispose();
        //    myviewer = viewer;
        //}

        //function createviewer(tvserver, scanid) {
        //    myviewer = tvgviewer({
        //        id: 'testviewer',
        //        server: tvserver,
        //        scanid: scanid,
        //        containerid: 'tvg-container',
        //        locale: 'es',
        //        access: $scope.token
        //    }, tvinit);
        //};


        //TVService.getToken()
        //    .then(function (response) {
        //        var data = response.data;
        //        $scope.token = data;
        //        createviewer($scope.tvserver, $scope.scanid);
        //        });

        //tvgmap({
        //    id: 'map',
        //    server: 'http://87.106.30.6:9000',
        //    siteid: '609a6a4deede9c6fb8aef652',
        //    containerid: 'tvg-map',
        //    access: token,
        //    //refresh: token2
        //}, function (err, sitemap) {
        //    if (err) {
        //        alert('Error :' + err.message);
        //    }
        //    else {
        //        alert('Map: ' + sitemap.name);
        //    }
        //    });

        //tvgviewer({
        //    id: 'testviewer',
        //    server: 'http://87.106.30.6:9000',
        //    scanid: '01d30152-e3a8-4872-b05f-8c9e85505d7c',
        //    containerid: 'tvg-container',
        //    locale: 'es',
        //    access: token
        //}, function (err, sitemap) {
        //    if (err) {
        //        alert('Error :' + err.message);
        //    }
        //    else {
        //        var vista = sitemap
        //        var aux = sitemap.geotags
        //        var keys = Object.keys(aux);

        //        var aux2 = sitemap._markers;
        //        var keysmarkers = Object.keys(aux2);
        //        var bind = true;

        //        sitemap.addPoint([2,2,2]);

        //        sitemap.showMinimap();
        //    }
        //});


    }]);
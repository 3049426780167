angular
    .module('tourvirtual')
    .factory('tourvirtualService', ['$http', '$q', '__env', function tourvirtualService($http, $q, __env) {

        var serviceUrl = __env.apiUrl;
        var tourvirtualUrl = 'tourvirtual';

        function getTourVirtual(id) {
            return $http.get(serviceUrl + tourvirtualUrl + '/' + id);
        }

        return {
            getTourVirtual: getTourVirtual
        }
    }]);

var municipiosAdmin = angular.module('municipiosAdmin', []);

municipiosAdmin.controller('municipiosController', ['$scope', '$location', 'municipiosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $location, municipiosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.municipiosSeleccionados = "";

    municipiosAdminService.getMunicipios()
            .then(function (response) {
                var data = response.data;
                $scope.municipios = data;
            });
    $scope.verMunicipios = function (Municipios) {
        $location.path('/admin/editarMunicipios/' + Municipios);
            //$location.path('/Admin/' + idUsuario);

    };

    $scope.crearMunicipio = function () {
        $location.path('/admin/municipios/nuevo');
    }

    $scope.municipiosSeleccionados = ""
    $scope.eliminarMunicipios = function () {
        var confirmado = confirm('¿Desea eliminar los municipio seleccionados?')
        if (confirmado) {
            angular.forEach($scope.municipios, function (municipio) {
                if (municipio.Selected) {
                    if ($scope.municipiosSeleccionados != "") {
                        $scope.municipiosSeleccionados = $scope.municipiosSeleccionados + "," + municipio.Id
                    } else {
                        $scope.municipiosSeleccionados = municipio.Id
                    }

                };
            });
            municipiosAdminService.eliminarMunicipios($scope.municipiosSeleccionados).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.municipiosSeleccionados = [];
                municipiosAdminService.getMunicipios()
                    .then(function (response) {
                        var data = response.data;
                        $scope.municipios = data;
                    });
            }).catch(function (response) {
                $scope.error = municipiosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.municipios, function (municipio) {
                municipio.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);
var nuevoTipoTajo = angular.module('tiposTajoAdmin');

nuevoTipoTajo.controller('nuevoTipoTajoAdminController', ['$scope', '$routeParams', '$location', 'tiposTajoAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, tiposTajoAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;

    tiposTajoAdminService.getInfraestructuras(4)
        .then(function (response) {
            var data = response.data;
            $scope.infraestructuras = data;
        });

    $scope.gestionTiposTajo = function () {
        $location.path("/admin/tiposTajoAdmin");
    }

        $scope.guardarTipoTajo = function () {

            tiposTajoAdminService.post($scope.tipotajo).then(function (response) {
                var result = response.data;
                alert("Tipo de Tajo creado correctamente");
                $location.path("/admin/tiposTajoAdmin");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
    }

    }]);
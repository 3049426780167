var infraestructurasAdmin = angular.module('infraestructurasAdmin',[]);

infraestructurasAdmin.controller('infraestructurasAdminController', ['$scope', '$location', 'infraestructurasAdminService', function ($scope, $location, infraestructurasAdminService) {
    infraestructurasAdminService.getInfraestructuras()
            .then(function (response) {
                var data = response.data;
                $scope.infraestructuras = data;
            });
    $scope.verInfraestructura = function (idInfraestructura) {
        $location.path('/admin/editarInf/' + idInfraestructura);
            //$location.path('/Admin/' + idUsuario);

    };

    $scope.crearInfraestructura = function () {
        $location.path('/admin/infraestructuras/nueva');
    }

    $scope.infraestructurasSeleccionadas = ""
    $scope.eliminarInfraestructuras = function () {
        var confirmado = confirm('¿Desea eliminar las infraestructuras seleccionadas?')
        if (confirmado) {
            angular.forEach($scope.infraestructuras, function (infraestructura) {
                if (infraestructura.Selected) {
                    if ($scope.infraestructurasSeleccionadas != "") {
                        $scope.infraestructurasSeleccionadas = $scope.infraestructurasSeleccionadas + "," + infraestructura.Id
                    } else {
                        $scope.infraestructurasSeleccionadas = infraestructura.Id
                    }

                };
            });
            infraestructurasAdminService.eliminarInfraestructuras($scope.infraestructurasSeleccionadas).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.infraestructurasSeleccionadas = [];
                infraestructurasAdminService.getInfraestructuras()
                    .then(function (response) {
                        var data = response.data;
                        $scope.infraestructuras = data;
                    });
            }).catch(function (response) {
                $scope.error = infraestructurasAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.usuarios, function (usuario) {
                infraestructuras.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);
var nuevaInfraestructura = angular.module('infraestructurasAdmin');

nuevaInfraestructura.controller('nuevaInfraestructurasAdminController', ['$scope', '$routeParams', '$location', 'infraestructurasAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, infraestructurasAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;
    $scope.imagenSubida = false;
    //$scope.archivo = null;

    infraestructurasAdminService.getCategorias()
        .then(function (response) {
            var data = response.data;
            $scope.categorias = data;
        });

    infraestructurasAdminService.getEntornos()
        .then(function (response) {
            var data = response.data;
            $scope.entornos = data;
        });

    infraestructurasAdminService.getMunicipios()
        .then(function (response) {
            var data = response.data;
            $scope.municipios = data;
        });

    infraestructurasAdminService.getIndicesInspeccion()
        .then(function (response) {
            var data = response.data;
            $scope.indicesInspeccion = data;
        });

    infraestructurasAdminService.getPropietarios()
        .then(function (response) {
            var data = response.data;
            $scope.propietarios = data;
        });

    $scope.gestionInfraestructuras = function () {
        $location.path("/admin/infraestructurasAdmin");
    }

    $scope.guardarInfraestructura = function () {
        var fd = new FormData();
        infraestructurasAdminService.post($scope.infraestructura, $scope.FormData).then(function (response) {
                var result = response.data;
                alert(result);
            $location.path("/admin/infraestructurasAdmin");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = infraestructurasAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
    }

    $scope.getImagen = function (file) {

        var formData = new FormData();
        formData.append(0, file);
        $scope.FormData = formData;
        r = new FileReader();
        $scope.imagen = null;
        r.onloadend = function (e) { //callback after files finish loading
            var b64 = e.target.result;
            var imagen = { FileName: file.name, Imagen: b64 };
            $scope.imagen = imagen;
            $scope.imagenSubida = true;
            infraestructurasAdminService.getMunicipios()
                .then(function (response) {
                    var data = response.data;
                    $scope.municipios = data;
                });
        }

        r.readAsDataURL(file); //once defined all callbacks, begin reading the file

        
    };

    }]);
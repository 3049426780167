var infraestructuras = angular.module('infraestructuras');

infraestructuras.factory('infraestructurasService', ['$http', '__env', function infraestructurasService($http, __env) {

    var serviceUrl = __env.apiUrl;
    var infraestructurasUrl = 'categorias';

    function getCategorias() {
        return $http.get(serviceUrl + infraestructurasUrl);
    }

    function getCategoria(idCategoria) {
        return $http.get(serviceUrl + infraestructurasUrl + '/' + idCategoria);
    }


    function getDatosGalibos() {
        return $http.get(serviceUrl + "galibos");
    }

    function getInfraestructurasCategoria(id, usuario) {
        return $http({
            method: 'get',
            url: serviceUrl + 'infraestructuras/categoria/' + id,
            params:
            {
                'usuario': usuario
            }
        });
    }


    function getInfraestructura(id) {
        return $http.get(serviceUrl + 'infraestructuras/' + id);
    }

    function getTiposTunel() {
        return $http.get(serviceUrl + "tiposTunel");
    }

    function getObstaculos() {
        return $http.get(serviceUrl + "obstaculo");
    }

    function getObstaculos() {
        return $http.get(serviceUrl + "obstaculo");
    }

    function getNumDireccionesTunel() {
        return $http.get(serviceUrl + "numDireccionesTunel");
    }

    function getLongitudTunel() {
        return $http.get(serviceUrl + "longitudTunel");
    }

    function getMaterialesTunel() {
        return $http.get(serviceUrl + "materialTunel");
    }

    function getTipoVia() {
        return $http.get(serviceUrl + "tipoVia");
    }

    function getCirculacionPermitida() {
        return $http.get(serviceUrl + "circulacionPermitida");
    }

    function getTunel(idInfraestructura) {
        return $http.get(serviceUrl + "tuneles/" + idInfraestructura);
    }

    //function guardarTunel(tunel) {
    //    return $http.put(serviceUrl + 'tuneles/put/' + tunel.Id, tunel, );
    //}

    function guardarInventario(tunel, COD_INT, propietarioId, entornoId, municipioId) {
        return $http({
            method: 'put',
            url: serviceUrl + 'tuneles/put/' + tunel.Id,
            params:
            {
                'strTunel': JSON.stringify(tunel),
                'COD_INT': COD_INT,
                'propietarioId': propietarioId,
                'entornoId': entornoId,
                'municipioId': municipioId
            }

        });
    }

    function verInspeccion(idInspeccion) {
        return $http.get(serviceUrl + "inspecciones/" + idInspeccion);
    }


    function getInspeccion(idInspeccion) {
        return $http.get(serviceUrl + "inspecciones/" + idInspeccion);
    }

    function getInspecciones(idTunel) {
        return $http.get(serviceUrl + "inspecciones/tunel/" + idTunel);
    }

    function guardarInspeccion(inspeccion) {
        return $http.put(serviceUrl + 'inspecciones/put/' + inspeccion.Id, inspeccion);
    }

    //function getMunicipios() {
    //    return $http.get(serviceUrl + 'municipios');
    //}

    //function getPropietarios() {
    //    return $http.get(serviceUrl + 'propietarios');
    //}

    //function getMateriales() {
    //    return $http.get(serviceUrl + 'materiales');
    //}

    //function getEntornos() {
    //    return $http.get(serviceUrl + 'entornos');
    //}


    return {
        getCategorias: getCategorias,
        getInfraestructurasCategoria: getInfraestructurasCategoria,
        getInfraestructura: getInfraestructura,
        getCategoria: getCategoria,
        //getMunicipios: getMunicipios,
        //getPropietarios: getPropietarios,
        //getMateriales: getMateriales,
        //getEntornos: getEntornos
        getTiposTunel: getTiposTunel,
        getObstaculos: getObstaculos,
        getNumDireccionesTunel: getNumDireccionesTunel,
        getLongitudTunel: getLongitudTunel,
        getMaterialesTunel: getMaterialesTunel,
        getTipoVia: getTipoVia,
        getCirculacionPermitida: getCirculacionPermitida,
        getTunel: getTunel,
        guardarInventario: guardarInventario,
        guardarInspeccion: guardarInspeccion,
        getInspeccion: getInspeccion,
        getDatosGalibos: getDatosGalibos,
        guardarInspeccion: guardarInspeccion,
        getInspecciones: getInspecciones,
        verInspeccion: verInspeccion
    }

}]);


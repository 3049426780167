angular.module('inspecciones')
    .controller('inspeccionController', ['$rootScope', '$scope', '$location', '$route', '$routeParams', 'inspeccionesService', 'infraestructurasService', 'authenticationInfo', 'authenticationService',
        function ($rootScope, $scope, $location, $route, $routeParams, inspeccionesService, infraestructurasService, authenticationInfo, authenticationService) {

            $scope.authentication = authenticationInfo;
            //$scope.InfraestructuraId = $routeParams.idInfraestructura;

            $scope.InspeccionId = $routeParams.id;

            $scope.pestanyavisible = ($scope.InspeccionId > 0) ? 'general' : 'inspeccion';

            //$scope.pestanyavisible = 'general';

            //infraestructurasService.getTunel($scope.InfraestructuraId)
            //    .then(function (response) {
            //        $scope.tunel = response.data;
            //    });

            $scope.indice = 0;


            if ($scope.InspeccionId > 0) {
                inspeccionesService.getInspeccion($scope.InspeccionId)
                    .then(function (response) {
                        var data = response.data;
                        $scope.inspeccion = data;
                        $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                        $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
                        //$scope.inspeccion.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo.toString();
                        $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                        $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin.toString();
                        $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                        $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();

                        if ($scope.inspeccion.UltimaEjecutada == "si" || $scope.inspeccion.UltimaEjecutada == "Si" || $scope.inspeccion.UltimaEjecutada == "SI") {
                            $scope.inspeccion.UltimaEjecutada = "1";
                        } else {
                            $scope.inspeccion.UltimaEjecutada = "0";
                        }

                        $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.Tunel.IdentificadorTubo.toString();

                        inspeccionesService.getElementosInspeccionados($scope.inspeccionId)
                            .then(function (response) {
                                var data = response.data;
                                $scope.elementosinspeccionados = data;
                            });


                        inspeccionesService.getElementosEvaluados($scope.inspeccionId)
                            .then(function (response) {
                                var data = response.data;
                                $scope.elementosevaluados = data;
                            });

                    })
            }


            $scope.ElementosInspeccionadosCargar = function (pestanya) {
                $scope.pestanyavisible = pestanya;
                inspeccionesService.getElementosInspeccionados($scope.inspeccionId)
                    .then(function (response) {
                        var data = response.data;
                        $scope.elementosinspeccionados = data;
                    });
            }

            $scope.ElementosEvaluadosCargar = function (pestanya) {
                $scope.pestanyavisible = pestanya;
                inspeccionesService.getElementosEvaluados($scope.inspeccionId)
                    .then(function (response) {
                        var data = response.data;
                        $scope.elementosinspeccionados = data;
                    });
            }


        $scope.volver = function () {
            $location.path('/infraestructura/' + $rootScope.InfraestructuraId);
            $rootScope.inspeccionUrl = true;
        }

        $scope.ElementosInspeccionadosCargar = function () {
            $scope.pestanyavisible = 'elementosinspeccionados';
        }

        $scope.ElementosEvaluadosCargar = function () {
            $scope.pestanyavisible = 'elementosevaluados';
        }

        $scope.guardar = function (mostrarAlerta) {
            const timeElapsed = Date.now();
            const today = new Date(timeElapsed);

            var inspeccionSQL = new Object();
            inspeccionSQL.Id = $scope.InspeccionId;
            inspeccionSQL.Descripcion = $scope.inspeccion.Descripcion;
            inspeccionSQL.IndiceTubo = $scope.inspeccion.IndiceTubo;
            inspeccionSQL.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo;
            inspeccionSQL.FechaInicio = $scope.inspeccion.FechaInicio;
            inspeccionSQL.FechaFin = $scope.inspeccion.FechaFin;
            inspeccionSQL.NumTramos = $scope.inspeccion.NumTramos;
            inspeccionSQL.LongTramo = $scope.inspeccion.LongTramo;

            if ($scope.inspeccion.UltimaEjecutada == "1") {
                inspeccionSQL.UltimaEjecutada = "SI";
            } else {
                inspeccionSQL.UltimaEjecutada = "NO";
            }

            //inspeccionSQL.UltimaEjecutada = $scope.inspeccion.UltimaEjecutada;

            inspeccionesService.putInspeccion(inspeccionSQL)
                .then(function (data) {
                    inspeccionesService.getInspeccion($scope.InspeccionId)
                        .then(function (response) {
                            var data = response.data;
                            $scope.inspeccion = data;
                            $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                            $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
                            $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo.toString();
                            $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                            $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin.toString();
                            $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                            $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
                            $scope.inspeccion.UltimaEjecutada = $scope.inspeccion.UltimaEjecutada.toString();


                            if ($scope.inspeccion.UltimaEjecutada == "si" || $scope.inspeccion.UltimaEjecutada == "Si" || $scope.inspeccion.UltimaEjecutada == "SI") {
                                $scope.inspeccion.UltimaEjecutada = "1";
                            } else {
                                $scope.inspeccion.UltimaEjecutada = "0";
                            }

                            alert('Datos guardados correctamente');
                        })
                })
        };

        $scope.logout = function () {
            //$rootScope.bodylayout = "login";
            return authenticationService.logout();
        }

        $scope.verCategorias = function () {
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
            $location.path('/categorias');
        };
    }
]);
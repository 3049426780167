var indiceInspeccionAdmin = angular.module('indiceInspeccionAdmin', []);

indiceInspeccionAdmin.controller('indiceInspeccionController', ['$scope', '$location', 'indiceInspeccionAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $location, indiceInspeccionAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.indiceInspeccionSeleccionados = "";

    indiceInspeccionAdminService.getIndicesInspeccion()
            .then(function (response) {
                var data = response.data;
                $scope.indicesInspeccion = data;
            });
    $scope.verIndiceInspeccion = function (indiceInspeccion) {
        $location.path('/admin/editarIndiceInspeccion/' + indiceInspeccion);
            //$location.path('/Admin/' + idUsuario);

    };
    $scope.crearIndiceInspeccion = function () {
        $location.path('/admin/indiceInspeccion/nuevo');
    }

    $scope.gestionIndicesInspeccion = function () {
        $location.path("/admin/indiceInspeccionAdmin");
    }

    $scope.indiceInspeccionSeleccionados = ""
    $scope.eliminarIndiceInspeccion = function () {
        var confirmado = confirm('¿Desea eliminar los indices de inspección seleccionados?')
        if (confirmado) {
            angular.forEach($scope.indicesInspeccion, function (indiceInspeccion) {
                if (indiceInspeccion.Selected) {
                    if ($scope.indiceInspeccionSeleccionados != "") {
                        $scope.indiceInspeccionSeleccionados = $scope.indiceInspeccionSeleccionados + "," + indiceInspeccion.Id
                    } else {
                        $scope.indiceInspeccionSeleccionados = indiceInspeccion.Id
                    }

                };
            });
            indiceInspeccionAdminService.eliminarIndiceInspeccion($scope.indiceInspeccionSeleccionados).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.indiceInspeccionSeleccionados = [];
                indiceInspeccionAdminService.getIndicesInspeccion()
                    .then(function (response) {
                        var data = response.data;
                        $scope.indiceInspeccion = data;
                    });
            }).catch(function (response) {
                $scope.error = indiceInspeccionAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.indicesInspeccion, function (indiceInspeccion) {
                indiceInspeccion.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);
angular.module('login')
    .controller('login2FAController', ['$scope', '$rootScope', 'authenticationInfo', 'authenticationService', '$location', function ($scope, $rootScope, authenticationInfo, authenticationService, $location) {
        $scope.usuario = $rootScope.usuario;

        authenticationService.generarCodigo($scope.usuario);
        alert("Se ha enviado un codigo al email registrado a su nombre");


        $scope.login2FA = function () {
            authenticationService.verificar2FA($scope.dobleAutentication, $scope.usuario)
                .then(function (response) {
                    $scope.FA2 = response.data;

                    if ($scope.FA2 == false) {
                        alert("clave incorrecta");
                    }
                    else {
                        $location.path('/categorias');
                    }
                });
        }
    }]);
////angular
////    .module('sensores')
////    .factory('creadorGraficoService', ['$http', '$q', '$rootScope', function modelosService($http, $q, $rootScope) {
////        var serviceURL = 'http://87.106.30.6:8090/api/';

////        function getGraficoDataParticulas(tipoMedicion, id) {
////            return $http({
////                method: 'get',
////                url: serviceURL + 'grafico/datos/particulas/' + tipoMedicion + '/' + id,
//                params: {
////                    'tipoMedicion': tipoMedicion,
////                    'id': id

////                }
////            });
////        }
////        function getGraficoData(medicion, tipoMedicion, id) {
////            return $http({
////                method: 'get',
////                url: serviceURL + 'grafico/datos/' + medicion + '/' + tipoMedicion + '/' + id,
////                params: {

////                    'medicion': medicion,
////                    'tipoMedicion¡': tipoMedicion,
////                    'id': id
////                }

////            });
////        }
////        function getDato(medicion, tipoMedicion, id) {
////            return $http({
////                method: 'get',
////                url: serviceURL + 'dato/' + medicion + '/' + tipoMedicion + '/' + id,
////                params: {
////                    'medicion': medicion,
////                    'tipoMedicion': tipoMedicion,
////                    'id': id
////                }
////            });
////        }
////        return {
////            getGraficoDataParticulas: getGraficoDataParticulas,
////            getGraficoData: getGraficoData,
////            getDato: getDato
////        }


////    }]);
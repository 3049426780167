
var editarEntorno = angular.module('entornosAdmin');

editarEntorno.controller('entornoAdminController', ['$scope', '$routeParams', '$location', 'entornosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, entornosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    entornosAdminService.getEntorno($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.entorno = data;
                $scope.descripcion = data.Descripcion;
            });

    $scope.gestionEntornos = function () {
        $location.path("/admin/entornosAdmin");
    }
    $scope.editarEntorno = function () {
        entornosAdminService.put($scope.descripcion, $scope.entorno.Id).then(function (response) {
                var result = response.data;
                alert("Entorno guardado correctamente");
                //$location.path("/admin/usuarios");
            $location.path("/admin/entornosAdmin");

            }).catch(function (response) {
                $scope.error = entornosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }


    }]);
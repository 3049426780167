var app = angular
    .module('inspecciones')
    .factory('inspeccionesService', ['$http', '$q', '__env', '$routeParams', function inspeccionesService($http, $q, __env, $routeParams) {
        var serviceUrl = __env.apiUrl;
        var inspeccionesUrl = 'inspecciones';
        var dañosUrl = 'damages';
        var tunelUrl = 'tuneles';


        function getInspecciones() {
            return $http.get(serviceUrl + inspeccionesUrl + '/tunel/' + $routeParams.id);
        }

        function getDaños(idInspeccion) {
            return $http({
                method: 'get',
                url: serviceUrl + dañosUrl + "/inspeccion/" + idInspeccion,
            });
        }

        function calculainspeccionanterior(idTunel) {
            return $http.get(serviceUrl + inspeccionesUrl + "/fechaanterior/" + idTunel);
        }

        function getDañosPorTipo(idInspeccion) {
            return $http.get(serviceUrl + dañosUrl + "/damagesportipogravedad/" + idInspeccion);
        }

        function getDañosPorPK(idInspeccion) {
            return $http.get(serviceUrl + dañosUrl + "/damagesporpkgravedad/" + idInspeccion);
        }

        function getDañosPorElemento(idInspeccion) {
            return $http.get(serviceUrl + dañosUrl + "/damagesporelementogravedad/" + idInspeccion);
        }

        function getDaño(idDaño) {
            return $http.get(serviceUrl + dañosUrl + "/damageInspeccion/" + idDaño);
        }

        function getInspeccion(idInspeccion) {
            return $http.get(serviceUrl + inspeccionesUrl + "/inspeccion/" + idInspeccion);
        }

        function getSecciones(idInspeccion, distancia) {
            return $http.get(serviceUrl + dañosUrl + "/secciones/" + idInspeccion + "/" + distancia);
        }





        function crearInspeccion(tunelId) {
            return $http({
                method: 'post',
                url: serviceUrl + inspeccionesUrl + '/crear/',
                params:
                {
                    'tunelId': tunelId
                }
            });
        }


        function putInspeccion(inspeccion) {
            //return $http.put(serviceUrl + inspeccionsUrl + '/put/');

            return $http({
                method: 'put',
                url: serviceUrl + inspeccionesUrl + '/put/',
                params:
                {
                    'strInspeccion': JSON.stringify(inspeccion)
                },
            });
        }


        function verInspeccion(path, nombre) {
            return $http({
                method: 'post',
                url: serviceUrl + inspeccionesUrl + '/',
                params:
                {
                    'parte': path + '/' + nombre,
                }
            });
        }


        function modificarInspeccion(path, nombre) {
            return $http({
                method: 'post',
                url: serviceUrl + inspeccionessUrl + '/',
                params:
                {
                    'parte': path + '/' + nombre,
                }
            });
        }


        function eliminarInspeccion(inspeccionId) {
            return $http({
                method: 'post',
                url: serviceUrl + inspeccionesUrl + '/eliminarInspeccion/' + inspeccionId
            });
        }


        function eliminarDamage(idDaño) {
            return $http({
                method: 'post',
                url: serviceUrl + dañosUrl + '/eliminarDamage/' + idDaño
            });
        }

        function getTunelId(infraestructuraId) {
            return $http.get(serviceUrl + tunelUrl + "/infraestructura/" + infraestructuraId);
        }

        function getDañosPorSeccion(idInspeccion, distSeccion) {
            return $http.get(serviceUrl + dañosUrl + "/damagesporseccion/" + idInspeccion + "/" + distSeccion);
        }

        function getDañosPorPosicion(idInspeccion, distSeccion) {
            return $http.get(serviceUrl + dañosUrl + "/damagesporposicion/" + idInspeccion + "/" + distSeccion);
        }

        function getDañosPorSeccionPosicion(idInspeccion, seccion, distSeccion) {
            return $http.get(serviceUrl + dañosUrl + "/damagesporseccionposicion/" + idInspeccion + "/" + seccion + "/" + distSeccion);
        }

        function getCategoriasDaño() {
            return $http.get(serviceUrl + dañosUrl + '/categoriasdano/');
        }

        function getUltimaInspeccion(idTunel) {
            return $http.get(serviceUrl + tunelUrl + '/last/' + idTunel);
        }

        function guardarDaño(damage) {
            return $http({
                method: 'post',
                url: serviceUrl + dañosUrl + '/guardardamage/',
                params:
                {
                    'strDañoNube': damage
                }
            });
        }


        return {

            getInspecciones: getInspecciones,
            eliminarInspeccion: eliminarInspeccion,
            modificarInspeccion: modificarInspeccion,
            verInspeccion: verInspeccion,
            putInspeccion: putInspeccion,
            getInspeccion: getInspeccion,
            getDaño: getDaño,
            getDaños: getDaños,
            getDañosPorTipo: getDañosPorTipo,
            getDañosPorElemento: getDañosPorElemento,
            calculainspeccionanterior: calculainspeccionanterior,
            eliminarDamage: eliminarDamage,
            getDañosPorPK: getDañosPorPK,
            getTunelId: getTunelId,
            getDañosPorSeccion: getDañosPorSeccion,
            getDañosPorPosicion: getDañosPorPosicion,
            getDañosPorSeccionPosicion: getDañosPorSeccionPosicion,
            getSecciones: getSecciones,
            getCategoriasDaño: getCategoriasDaño,
            guardarDaño: guardarDaño,
            getUltimaInspeccion: getUltimaInspeccion
        }
    }]);
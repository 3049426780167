var propietariosAdminService = angular.module('propietariosAdmin');

propietariosAdminService.factory('propietariosAdminService', ['$http', '$q', '__env', function propietariosAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var usuariosUrl = 'usuarios';
        var rolesUrl = 'roles';

        function getPropietarios() {
            return $http.get(serviceUrl + usuariosUrl + '/Propietarios');
        }

        function getPropietario(id) {
            return $http.get(serviceUrl + usuariosUrl + '/Propietario/' + id);
        }

        function put(descripcion, id) {
            return $http({
                method: 'put',
                url: serviceUrl + usuariosUrl + '/editarPropietario/' + id,
                params:
                {
                    'descripcion': descripcion
                }
            });
        }

    function post(propietario) {
            //return $http.post(serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario, usuario);
            return $http({
                method: 'post',
                url: serviceUrl + usuariosUrl + '/registrarPropietarios',
                params:
                {
                    'propietario': propietario
                }

            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }
        function eliminarPropietarios(usuariosEliminar) {
            return $http.post(serviceUrl + usuariosUrl + '/eliminarPropietarios/' + usuariosEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
                alert('Error');
            });
        }



        return {
            getPropietarios: getPropietarios,
            getPropietario: getPropietario,
            put: put,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarPropietarios: eliminarPropietarios,


        }

    }]);
var entornosAdmin = angular.module('entornosAdmin', []);

entornosAdmin.controller('entornosController', ['$scope', '$location', 'entornosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $location, entornosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.entornosSeleccionados = "";

    entornosAdminService.getEntornos()
            .then(function (response) {
                var data = response.data;
                $scope.entornos = data;
            });
    $scope.verEntornos = function (entorno) {
        $location.path('/admin/editarEntornos/' + entorno);
            //$location.path('/Admin/' + idUsuario);

    };
    $scope.crearEntornos = function () {
        $location.path('/admin/entornos/nuevo');
    };
    
    $scope.entornosSeleccionados = ""
    $scope.eliminarEntornos = function () {
        var confirmado = confirm('¿Desea eliminar los entornos seleccionados?')
        if (confirmado) {
            angular.forEach($scope.entornos, function (entorno) {
                if (entorno.Selected) {
                    if ($scope.entornosSeleccionados != "") {
                        $scope.entornosSeleccionados = $scope.entornosSeleccionados + "," + entorno.Id
                    } else {
                        $scope.entornosSeleccionados = entorno.Id
                    }

                };
            });
            entornosAdminService.eliminarEntornos($scope.entornosSeleccionados).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.entornosSeleccionados = [];
                entornosAdminService.getEntornos()
                    .then(function (response) {
                        var data = response.data;
                        $scope.entornos = data;
                    });
            }).catch(function (response) {
                $scope.error = entornosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.entornos, function (entorno) {
                entorno.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);
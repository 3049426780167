angular
    .module('TV')
    .factory('TVService', ['$http', '$q', '__env', '$filter', function TVService($http, $q, __env, $filter) {

        var serviceUrl = __env.apiUrl;
        var TVIndicadoresUrl = 'tokens';

        function getToken() {
            return $http.get(serviceUrl + TVIndicadoresUrl + '/refresh/');
        }

        function getSitio(infraestructuraId) {
            return $http.get(serviceUrl + TVIndicadoresUrl + '/sitio/' + infraestructuraId);
        }


        return {
            getToken: getToken,
            getSitio: getSitio
        }
    }]);

///////////////////////////////////////////////////////////////////////////////////////MALO/////////////////////////////////////////////////////////////////PERO FUNCIONA


//var app = angular.module('sensores', []);
//var myTheme = {
//    palette: {
//        line: [
//            ['#FBFCFE', '#00BAF2', '#00BAF2', '#00a7d9'], /* light blue */
//            ['#FBFCFE', '#E80C60', '#E80C60', '#d00a56'], /* light pink */
//            ['#FBFCFE', '#9B26AF', '#9B26AF', '#8b229d'], /* light purple */
//            ['#FBFCFE', '#E2D51A', '#E2D51A', '#E2D51A'], /* med yellow */
//            ['#FBFCFE', '#FB301E', '#FB301E', '#e12b1b'], /* med red */
//            ['#FBFCFE', '#00AE4D', '#00AE4D', '#00AE4D'], /* med green */
//        ]
//    },
//    graph: {
//        backgroundColor: '#FBFCFE',
//        title: {
//            fontFamily: 'Capian',
//            fontSize: 14,
//            // border: "1px solid black",
//            padding: "15px",
//            fontColor: "#1E5D9E",
//            adjustLayout: true
//        },
//        subtitle: {
//            fontFamily: 'Capian',
//            fontSize: 12,
//            fontColor: "#777",
//            padding: "5"
//        },
//        plot: {
//            backgroundColor: '#FBFCFE',
//            marker: {
//                size: 4
//            }
//        },
//        tooltip: {
//            visible: true,
//            text: "%kl<br><span style='color:%color'>%t: </span>%v<br>",
//            backgroundColor: "white",
//            borderColor: "#e3e3e3",
//            borderRadius: "5px",
//            bold: true,
//            fontSize: "12px",
//            fontColor: "#2f2f2f",
//            textAlign: 'left',
//            padding: '15px',
//            shadow: true,
//            shadowAlpha: 0.2,
//            shadowBlur: 5,
//            shadowDistance: 4,
//            shadowColor: "#a1a1a1"
//        },
//        plotarea: {
//            backgroundColor: 'transparent',
//            borderRadius: "0 0 0 10",
//            borderRight: "0px",
//            borderTop: "0px",
//            margin: "dynamic",
//            borderLeft: '1px solid #1E5D9E',
//            borderBottom: '1px solid #1E5D9E',
//        },
//        scaleX: {
//            zooming: true,
//            offsetY: -20,
//            lineWidth: 0,
//            padding: 20,
//            margin: 20,
//            label: {
//                text: ""
//            },
//            item: {
//                padding: 5,
//                fontColor: "#1E5D9E",
//                fontFamily: 'Montserrat',
//                rules: [
//                    {
//                        rule: "%i == 0",
//                        visible: false
//                    }
//                ]
//            },
//            tick: {
//                lineColor: '#D1D3D4',
//                rules: [
//                    {
//                        rule: "%i == 0",
//                        visible: false
//                    }
//                ]
//            },
//            guide: {
//                visible: true,
//                lineColor: '#D7D8D9',
//                lineStyle: 'dotted',
//                lineGapSize: '4px',
//                rules: [
//                    {
//                        rule: "%i == 0",
//                        visible: false
//                    }
//                ]
//            }
//        },
//        scaleY: {
//            zooming: true,
//            lineWidth: 0,
//            label: {
//                text: ""
//            },
//            item: {
//                padding: "0 10 0 0",
//                fontColor: "#1E5D9E",
//                fontFamily: 'Montserrat',
//                rules: [
//                    {
//                        rule: "%i == 0",
//                        offsetX: 10,
//                        offsetY: 10,
//                        backgroundColor: 'none'
//                    }
//                ]
//            },
//            tick: {
//                lineColor: '#D1D3D4',
//                rules: [
//                    {
//                        rule: "%i == 0",
//                        visible: false
//                    }
//                ]
//            },
//            guide: {
//                visible: true,
//                lineColor: '#D7D8D9',
//                lineStyle: 'dotted',
//                lineGapSize: '4px',
//                rules: [
//                    {
//                        rule: "%i == 0",
//                        visible: false
//                    }
//                ]
//            }
//        },
//        scrollX: {
//            bar: {
//                backgroundColor: 'none',
//                borderLeft: 'none',
//                borderTop: 'none',
//                borderBottom: 'none'
//            },
//            handle: {
//                backgroundColor: '#1E5D9E',
//                height: 5
//            }
//        },
//        scrollY: {
//            borderWidth: 0,
//            bar: {
//                backgroundColor: 'none',
//                width: 14,
//                borderLeft: 'none',
//                borderTop: 'none',
//                borderBottom: 'none'
//            },
//            handle: {
//                borderWidth: 0,
//                backgroundColor: '#1E5D9E',
//                width: 5
//            }
//        },
//        zoom: {
//            backgroundColor: '#1E5D9E',
//            alpha: .33,
//            borderColor: '#000',
//            borderWidth: 1
//        },
//        preview: {
//            borderColor: '#1E5D9E',
//            borderWidth: 1,
//            adjustLayout: true,
//            handle: {
//                backgroundColor: '#1E5D9E',
//                borderColor: '#1E5D9E'
//            },
//            mask: {
//                backgroundColor: '#FFF',
//                alpha: .95,
//            }
//        }
//    }
//};

//app.controller('HumedadControllerUrdinbide', ['$scope',
//    function ($scope) {
//        var xmlhttp = new XMLHttpRequest();
//        var url = "http://87.106.30.6:8090/api/grafico/datos/ValorMedicionHumedad/MedicionAire/2059E7";
//        xmlhttp.open("GET", url, true);
//        xmlhttp.send();
//        xmlhttp.onreadystatechange = function () {
//            if (this.readyState == 4 && this.status == 200) {
//                var dataURL = JSON.parse(this.responseText);
//                var dataPoints = dataURL.map(function (elemF) {
//                    return elemF.map(function (o) {
//                        return {
//                            fecha: o.fechaUnix,
//                            Y: o.ValorMedicionHumedad
//                        }
//                    })
//                });
//                // var dataPoints = humedad[0].map(function (point) {
//                //    return {
//                //        x: point.Fecha,
//                //    }
//                //})
//                var finalArr = [];
//                finalArr = dataPoints[0];
//                var grafDataMedicion = [];
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicion[i] = [];
//                    //se suman tres '0' para pasar de segundos a milisegundos, desde sigfox se reive Unix timestamps en segundos
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].Y
//                }
//                //console.log(finalArr[0].Fecha);
//                zingchart.render({
//                    id: 'chart-1',
//                    height: 300,
//                    width: 900,
//                    defaults: myTheme,
//                    crosshairY: {
//                        plotLabel: {
//                            text: '%t - %kl Value: %v',
//                            borderWidth: 2,
//                            bold: true
//                        }
//                    },
//                    data: {
//                        "graphset": [{
//                            "type": "line",
//                            "legend": {},
//                            "title": {
//                                "text": "Humedad"
//                            },
//                            "scale-x": {
//                                "zooming": true,
//                                "transform": {
//                                    "type": "date",
//                                    "all": "%d %M,<br> %h:%i %A",
//                                    "item": {
//                                        "visible": false
//                                    }
//                                }
//                            },
//                            "scale-y": {
//                            },
//                            "series": [{
//                                "text": "Humedad",
//                                "values": grafDataMedicion,
//                                'legend-text': "%",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            }]

//                        }]
//                    }
//                });
//            }
//        }
//    }
//]);

//app.controller('PresionControllerUrdinbide', ['$scope',
//    function ($scope) {
//        var xmlhttp = new XMLHttpRequest();
//        var url = "http://87.106.30.6:8090/api/grafico/datos/ValorMedicionPresion/MedicionAire/2059E7";
//        xmlhttp.open("GET", url, true);
//        xmlhttp.send();
//        xmlhttp.onreadystatechange = function () {
//            if (this.readyState == 4 && this.status == 200) {
//                var dataURL = JSON.parse(this.responseText);
//                var dataPoints = dataURL.map(function (elemF) {
//                    return elemF.map(function (o) {
//                        return {
//                            fecha: o.fechaUnix,
//                            Y: o.ValorMedicionPresion
//                        }
//                    })
//                });
//                // var dataPoints = humedad[0].map(function (point) {
//                //    return {
//                //        x: point.Fecha,
//                //    }
//                //})
//                var finalArr = [];
//                finalArr = dataPoints[0];
//                var grafDataMedicion = [];
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicion[i] = [];
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].Y
//                }
//                //console.log(finalArr[0].Fecha);
//                zingchart.render({
//                    id: 'chart-2',
//                    height: 300,
//                    width: 900,
//                    defaults: myTheme,
//                    crosshairX: {
//                        plotLabel: {
//                            text: '%t - %kl Value: %v',
//                            borderWidth: 2,
//                            bold: true
//                        }
//                    },
//                    data: {
//                        "graphset": [{
//                            "type": "line",
//                            "legend": {},
//                            "title": {
//                                "text": "Presion atmosferica"
//                            },
//                            "scale-x": {
//                                "zooming": true,
//                                "transform": {
//                                    "type": "date",
//                                    "all": "%d %M,<br> %h:%i %A",
//                                    "item": {
//                                        "visible": false
//                                    }
//                                }
//                            },
//                            "scale-y": {
//                                minValue: 95000,
//                                maxValue: 105000
//                            },

//                            "series": [{

//                                //"labels": grafDataFecha,
//                                "text": "Presion",
//                                "values": grafDataMedicion,
//                                'legend-text': "bar",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            }]
//                        }]
//                    }
//                });
//            }
//        }
//    }

//    ///////

//]);
//app.controller('ParticulasControllerUrdinbide', ['$scope',
//    function ($scope) {
//        var xmlhttp = new XMLHttpRequest();
//        var url = "http://87.106.30.6:8090/api/grafico/datos/particulas/medicionAire/2059E7";
//        xmlhttp.open("GET", url, true);
//        xmlhttp.send();
//        xmlhttp.onreadystatechange = function () {
//            if (this.readyState == 4 && this.status == 200) {
//                var dataURL = JSON.parse(this.responseText);
//                var dataPoints = dataURL.map(function (elemF) {
//                    return elemF.map(function (o) {
//                        return {
//                            fecha: o.fechaUnix,
//                            Y: o.ValorMedicionPM1,
//                            Y2: o.ValorMedicionPM25,
//                            Y3: o.ValorMedicionPM10
//                        }
//                    })
//                });
//                // var dataPoints = humedad[0].map(function (point) {
//                //    return {
//                //        x: point.Fecha,
//                //    }
//                //})
//                var finalArr = [];
//                finalArr = dataPoints[0];
//                var grafDataMedicionpm1 = [];
//                var grafDataMedicionpm25 = [];
//                var grafDataMedicionpm10 = [];
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicionpm1[i] = [];
//                    grafDataMedicionpm1[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicionpm1[i][j] = finalArr[finalArr.length - i].Y
//                }
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicionpm25[i] = [];
//                    grafDataMedicionpm25[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicionpm25[i][j] = finalArr[finalArr.length - i].Y2
//                }
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicionpm10[i] = [];
//                    grafDataMedicionpm10[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicionpm10[i][j] = finalArr[finalArr.length - i].Y3
//                }
//                //console.log(finalArr[0].Fecha);
//                zingchart.render({
//                    id: 'chart-3',
//                    height: 300,
//                    width: 900,
//                    defaults: myTheme,
//                    crosshairX: {
//                        plotLabel: {
//                            text: '%t - %kl Value: %v',
//                            borderWidth: 2,
//                            bold: true
//                        }
//                    },
//                    plot: {
//                        tooltip: {
//                            visible: true
//                        }
//                    },
//                    data: {
//                        "graphset": [{
//                            "type": "line",
//                            "legend": {},
//                            "title": {
//                                "text": "Particulas en μg"
//                            },

//                            "scale-x": {
//                                "zooming": true,

//                                "transform": {
//                                    "type": "date",
//                                    "all": "%d %M, <br> %h:%i %A",
//                                    "item": {
//                                        "visible": false
//                                    }
//                                }
//                            },
//                            //"scale-y": { dataPointsY},

//                            "series": [{
//                                //"labels": grafDataFecha,

//                                "values": grafDataMedicionpm1,
//                                text: 'PM 1 μg',
//                                'legend-text': "1 μg",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            },
//                            {
//                                //"labels": grafDataFecha,
//                                "values": grafDataMedicionpm25,
//                                text: 'PM 2,5μg',
//                                'legend-text': "2,5μg",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            },
//                            {
//                                //"labels": grafDataFecha,
//                                "values": grafDataMedicionpm10,
//                                text: 'PM 10μg',
//                                'legend-text': "10μg",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            },

//                            ]
//                        }]
//                    }
//                });
//            }
//        }
//    }

//]);
//app.controller('HumedadControllerSeberetxe', ['$scope',
//    function ($scope) {
//        var xmlhttp = new XMLHttpRequest();
//        var url = "http://87.106.30.6:8090/api/grafico/datos/ValorMedicionHumedad/MedicionAire/2059E7";
//        xmlhttp.open("GET", url, true);
//        xmlhttp.send();
//        xmlhttp.onreadystatechange = function () {
//            if (this.readyState == 4 && this.status == 200) {
//                var dataURL = JSON.parse(this.responseText);
//                var dataPoints = dataURL.map(function (elemF) {
//                    return elemF.map(function (o) {
//                        return {
//                            fecha: o.fechaUnix,
//                            Y: o.ValorMedicionHumedad
//                        }
//                    })
//                });
//                // var dataPoints = humedad[0].map(function (point) {
//                //    return {
//                //        x: point.Fecha,
//                //    }
//                //})
//                var finalArr = [];
//                finalArr = dataPoints[0];

//                var grafDataMedicion = [];
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicion[i] = [];
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].Y
//                }
//                //console.log(finalArr[0].Fecha);
//                zingchart.render({
//                    id: 'chart-4',
//                    height: 300,
//                    width: 900,
//                    defaults: myTheme,
//                    crosshairY: {
//                        plotLabel: {
//                            text: '%t - %kl Value: %v',
//                            borderWidth: 2,
//                            bold: true
//                        }
//                    },
//                    data: {
//                        "graphset": [{
//                            "type": "line",
//                            "legend": {},
//                            "title": {
//                                "text": "Humedad"
//                            },
//                            "scale-x": {
//                                "zooming": true,
//                                "transform": {
//                                    "type": "date",
//                                    "all": "%d %M,<br> %h:%i %A",
//                                    "item": {
//                                        "visible": false
//                                    }
//                                }
//                            },
//                            "scale-y": {
//                            },

//                            "series": [{
//                                //"labels": grafDataFecha,
//                                "values": grafDataMedicion,
//                                'legend-text': "%",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }
//                            }]
//                        }]
//                    }
//                });
//            }
//        }
//    }

//]);
//app.controller('PresionControllerSeberetxe', ['$scope',
//    function ($scope) {
//        var xmlhttp = new XMLHttpRequest();
//        var url = "http://87.106.30.6:8090/api/grafico/datos/ValorMedicionPresion/MedicionAire/2059E7";
//        xmlhttp.open("GET", url, true);
//        xmlhttp.send();
//        xmlhttp.onreadystatechange = function () {
//            if (this.readyState == 4 && this.status == 200) {
//                var dataURL = JSON.parse(this.responseText);
//                var dataPoints = dataURL.map(function (elemF) {
//                    return elemF.map(function (o) {
//                        return {
//                            fecha: o.fechaUnix,
//                            Y: o.ValorMedicionPresion
//                        }
//                    })
//                });
//                // var dataPoints = humedad[0].map(function (point) {
//                //    return {
//                //        x: point.Fecha,
//                //    }
//                //})
//                var finalArr = [];
//                finalArr = dataPoints[0];
//                var grafDataMedicion = [];
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicion[i] = [];
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].Y
//                }
//                //console.log(finalArr[0].Fecha);
//                zingchart.render({
//                    id: 'chart-5',
//                    height: 300,
//                    width: 900,
//                    defaults: myTheme,
//                    crosshairY: {
//                        plotLabel: {
//                            text: '%t - %kl Value: %v',
//                            borderWidth: 2,
//                            bold: true
//                        }
//                    },
//                    data: {
//                        "graphset": [{
//                            "type": "line",
//                            "legend": {},
//                            "title": {
//                                "text": "Presion atmosferica"
//                            },
//                            "scale-x": {
//                                "zooming": true,
//                                "transform": {
//                                    "type": "date",
//                                    "all": "%d %M,<br> %h:%i %A",
//                                    "item": {
//                                        "visible": false
//                                    }
//                                }
//                            },
//                            "scale-y": {
//                                minValue: 95000,
//                                maxValue: 105000
//                            },

//                            "series": [{

//                                //"labels": grafDataFecha,
//                                "values": grafDataMedicion,
//                                'legend-text': "bar",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            }]
//                        }]
//                    }
//                });
//            }
//        }
//    }
//]);
//app.controller('ParticulasControllerSeberetxe', ['$scope',
//    function ($scope) {
//        var xmlhttp = new XMLHttpRequest();
//        var url = "http://87.106.30.6:8090/api/grafico/datos/particulas/medicionAire/2059E7";
//        xmlhttp.open("GET", url, true);
//        xmlhttp.send();
//        xmlhttp.onreadystatechange = function () {
//            if (this.readyState == 4 && this.status == 200) {
//                var dataURL = JSON.parse(this.responseText);
//                var dataPoints = dataURL.map(function (elemF) {
//                    return elemF.map(function (o) {
//                        return {
//                            fecha: o.fechaUnix,
//                            Y: o.ValorMedicionPM1,
//                            Y2: o.ValorMedicionPM25,
//                            Y3: o.ValorMedicionPM10
//                        }
//                    })
//                });
//                // var dataPoints = humedad[0].map(function (point) {
//                //    return {
//                //        x: point.Fecha,
//                //    }
//                //})
//                var finalArr = [];
//                finalArr = dataPoints[0];
//                var grafDataMedicionpm1 = [];
//                var grafDataMedicionpm25 = [];
//                var grafDataMedicionpm10 = [];
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicionpm1[i] = [];
//                    grafDataMedicionpm1[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicionpm1[i][j] = finalArr[finalArr.length - i].Y
//                }
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicionpm25[i] = [];
//                    grafDataMedicionpm25[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicionpm25[i][j] = finalArr[finalArr.length - i].Y2
//                }
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicionpm10[i] = [];
//                    grafDataMedicionpm10[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicionpm10[i][j] = finalArr[finalArr.length - i].Y3
//                }
//                //console.log(finalArr[0].Fecha);
//                zingchart.render({
//                    id: 'chart-6',
//                    height: 300,
//                    width: 900,
//                    defaults: myTheme,
//                    crosshairX: {
//                        plotLabel: {
//                            text: '%t - %kl Value: %v',
//                            borderWidth: 2,
//                            bold: true
//                        }
//                    },
//                    plot: {
//                        tooltip: {
//                            visible: true
//                        }
//                    },
//                    data: {
//                        "graphset": [{
//                            "type": "line",
//                            "legend": {},
//                            "title": {
//                                "text": "Particulas"
//                            },
//                            "scale-y": {


//                            },
//                            "scale-x": {
//                                "zooming": true,
//                                "transform": {
//                                    "type": "date",
//                                    "all": "%d %M, <br> %h:%i %A",
//                                    "item": {
//                                        "visible": false
//                                    }
//                                }
//                            },
//                            //"scale-y": { dataPointsY},

//                            "series": [{
//                                //"labels": grafDataFecha,
//                                "values": grafDataMedicionpm1,
//                                text: 'PM 1μg',
//                                'legend-text': "1μg",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }
//                            },
//                            {
//                                //"labels": grafDataFecha,
//                                "values": grafDataMedicionpm25,
//                                text: 'PM 2,5μg',
//                                'legend-text': "2,5μg",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }
//                            },
//                            {
//                                //"labels": grafDataFecha,
//                                "values": grafDataMedicionpm10,
//                                text: 'PM 10μg',
//                                'legend-text': "10μg",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            },

//                            ]
//                        }]
//                    }
//                });
//            }
//        }
//    }
//]);

//app.controller('OpendataControllerPrecipitaciones', ['$scope',
//    function ($scope) {
//        $scope.InfraestructuraId = InfraestructuraId
//        var xmlhttp = new XMLHttpRequest();
//        var url = "http://87.106.30.6:8090/api//opendata/mediciones/" + InfraestructuraId + "";
//        xmlhttp.open("GET", url, true);
//        xmlhttp.send();
//        xmlhttp.onreadystatechange = function () {
//            if (this.readyState == 4 && this.status == 200) {
//                var dataURL = JSON.parse(this.responseText);
//                var dataPoints = dataURL.map(function (elemF) {
//                    return elemF.map(function (o) {
//                        return {
//                            fecha: o.fechaUnix,
//                            Y: o.ValorMedicionHumedad
//                        }
//                    })
//                });
//                // var dataPoints = humedad[0].map(function (point) {
//                //    return {
//                //        x: point.Fecha,
//                //    }
//                //})
//                var finalArr = [];
//                finalArr = dataPoints[0];
//                var grafDataMedicion = [];
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicion[i] = [];
//                    //se suman tres '0' para pasar de segundos a milisegundos, desde sigfox se reive Unix timestamps en segundos
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].Y
//                }
//                //console.log(finalArr[0].Fecha);
//                zingchart.render({
//                    id: 'chart-7',
//                    height: 300,
//                    width: 900,
//                    defaults: myTheme,
//                    crosshairY: {
//                        plotLabel: {
//                            text: '%t - %kl Value: %v',
//                            borderWidth: 2,
//                            bold: true
//                        }
//                    },
//                    data: {
//                        "graphset": [{
//                            "type": "line",
//                            "legend": {},
//                            "title": {
//                                "text": "Humedad"
//                            },
//                            "scale-x": {
//                                "zooming": true,
//                                "transform": {
//                                    "type": "date",
//                                    "all": "%d %M,<br> %h:%i %A",
//                                    "item": {
//                                        "visible": false
//                                    }
//                                }
//                            },
//                            "scale-y": {
//                            },
//                            "series": [{
//                                "text": "Humedad",
//                                "values": grafDataMedicion,
//                                'legend-text': "%",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            }]

//                        }]
//                    }
//                });
//            }
//        }
//    }
//]);
//app.controller('OpendataControllerViento', ['$scope',
//    function ($scope) {
//        $scope.InfraestructuraId = InfraestructuraId
//        var xmlhttp = new XMLHttpRequest();
//        var url = "http://87.106.30.6:8090/api//opendata/mediciones/" + InfraestructuraId + "";
//        xmlhttp.open("GET", url, true);
//        xmlhttp.send();
//        xmlhttp.onreadystatechange = function () {
//            if (this.readyState == 4 && this.status == 200) {
//                var dataURL = JSON.parse(this.responseText);
//                var dataPoints = dataURL.map(function (elemF) {
//                    return elemF.map(function (o) {
//                        return {
//                            fecha: o.fechaUnix,
//                            Y: o.ValorMedicionHumedad
//                        }
//                    })
//                });
//                // var dataPoints = humedad[0].map(function (point) {
//                //    return {
//                //        x: point.Fecha,
//                //    }
//                //})
//                var finalArr = [];
//                finalArr = dataPoints[0];
//                var grafDataMedicion = [];
//                for (var i = finalArr.length; i > 0; i--) {
//                    var j = 0;
//                    grafDataMedicion[i] = [];
//                    //se suman tres '0' para pasar de segundos a milisegundos, desde sigfox se reive Unix timestamps en segundos
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].fecha + "000";
//                    j++;
//                    grafDataMedicion[i][j] = finalArr[finalArr.length - i].Y
//                }
//                //console.log(finalArr[0].Fecha);
//                zingchart.render({
//                    id: 'chart-7',
//                    height: 300,
//                    width: 900,
//                    defaults: myTheme,
//                    crosshairY: {
//                        plotLabel: {
//                            text: '%t - %kl Value: %v',
//                            borderWidth: 2,
//                            bold: true
//                        }
//                    },
//                    data: {
//                        "graphset": [{
//                            "type": "line",
//                            "legend": {},
//                            "title": {
//                                "text": "Humedad"
//                            },
//                            "scale-x": {
//                                "zooming": true,
//                                "transform": {
//                                    "type": "date",
//                                    "all": "%d %M,<br> %h:%i %A",
//                                    "item": {
//                                        "visible": false
//                                    }
//                                }
//                            },
//                            "scale-y": {
//                            },
//                            "series": [{
//                                "text": "Humedad",
//                                "values": grafDataMedicion,
//                                'legend-text': "%",
//                                animation: {
//                                    effect: 'ANIMATION_EXPAND_BOTTOM',
//                                    speed: 15
//                                }

//                            }]

//                        }]
//                    }
//                });
//            }
//        }
//    }
//]);

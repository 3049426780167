var nuevaCategoria = angular.module('categoriaAdmin');

nuevaCategoria.controller('nuevaCategoriaAdminController', ['$scope', '$routeParams', '$location', 'categoriaAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, categoriaAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;

    var formData = new FormData();
    $scope.guardarCategoria = function () {
        categoriaAdminService.post($scope.descripcion, formData).then(function (response) {
                var result = response.data;
                alert("Categoria añadida correctamente");
                $location.path("/admin/categoriaAdmin");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = categoriaAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
    }

    $scope.gestionCategorias = function () {
        $location.path("/admin/categoriaAdmin");
    }

    $scope.getImagen = function (file) {

        //var formData = new FormData();
        formData.append(0, file);
        //$scope.FormData = formData;
        r = new FileReader();
        $scope.imagen = null;
        r.onloadend = function (e) { //callback after files finish loading
            var b64 = e.target.result;
            var imagen = { FileName: file.name, Imagen: b64 };
            $scope.imagen = imagen;
            $scope.imagenSubida = true;
            categoriaAdminService.getCategorias()
                .then(function (response) {
                    var data = response.data;
                    $scope.categorias = data;
                });
        }
        r.readAsDataURL(file); //once defined all callbacks, begin reading the file
    };

    $scope.getImagenHoover = function (file) {

        //var formData = new FormData();
        formData.append(1, file);
        //$scope.FormData = formData;
        r = new FileReader();
        $scope.imagenHoover = null;
        r.onloadend = function (e) { //callback after files finish loading
            var b64 = e.target.result;
            var imagen = { FileName: file.name, Imagen: b64 };
            $scope.imagenHoover = imagen;
            $scope.imagenHooverSubida = true;
            categoriaAdminService.getCategorias()
                .then(function (response) {
                    var data = response.data;
                    $scope.categorias = data;
                });
        }
        r.readAsDataURL(file); //once defined all callbacks, begin reading the file
    };

    $scope.getImagenDisabled = function (file) {

        //var formData = new FormData();
        formData.append(2, file);
        //$scope.FormData = formData;
        r = new FileReader();
        $scope.imagenDisabled = null;
        r.onloadend = function (e) { //callback after files finish loading
            var b64 = e.target.result;
            var imagen = { FileName: file.name, Imagen: b64 };
            $scope.imagenDisabled = imagen;
            $scope.imagenDisabledSubida = true;
            categoriaAdminService.getCategorias()
                .then(function (response) {
                    var data = response.data;
                    $scope.categorias = data;
                });
        }
        r.readAsDataURL(file); //once defined all callbacks, begin reading the file
    };

    }]);
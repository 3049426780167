var app = angular.module('gestiondocumental', []);
app.controller('GestionDocumentalController', ['$scope', '$rootScope', '$routeParams', '$location', 'gestiondocumentalService', 'infraestructurasService', function ($scope, $rootScope, $routeParams, $location, gestiondocumentalService, infraestructurasService) {

    var mime = [
        ['.css', 'text/css'],
        ['.csv', 'text/csv'],
        ['.doc', 'application/msword'],
        ['.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        ['.gif', 'image/gif'],
        ['.htm', 'text/html'],
        ['.html', 'text/html'],
        ['.jpeg', 'image/jpeg'],
        ['.jpg', 'image/jpeg'],
        ['.json', 'application/json'],
        ['.odp', 'application/vnd.oasis.opendocument.presentation'],
        ['.ods', 'application/vnd.oasis.opendocument.spreadsheet'],
        ['.odt', 'application/vnd.oasis.opendocument.text'],
        ['.pdf', 'application/pdf'],
        ['.png', 'image/png'],
        ['.ppt', 'application/vnd.ms-powerpoint'],
        ['.xhtml', 'application/xhtml+xml'],
        ['.xls', 'application/vnd.ms-excel'],
        ['.xml', 'application/xml']
    ];

    //documentosService.getIcono(1)
    //    .then(function (response) {
    //        var data = response.data;
    //        $scope.iconoArchivo = data;
    //    });

    //documentosService.getIcono(2)
    //    .then(function (response) {
    //        var data = response.data;
    //        $scope.iconoCarpeta = data;
    //    });

    //$scope.documentosSeleccionados = ""
    $scope.SelectedFiles = new Array();
    if ($scope.RutaActual == 'undefined') then
    {
        $scope.rutaActual = "E:\\InfraestructurasArchivos\\" + $routeParams.id;
        $scope.SistemaArchivosRuta = [];
        $scope.SistemaArchivosNombre = [];
        $scope.SistemaArchivosRuta[0] = "E:\\InfraestructurasArchivos\\" + $routeParams.id;
        //$scope.SistemaArchivosNombre[0] = "root";
        infraestructurasService.getInfraestructura($routeParams.id)
        .then(function (response) {
            var data = response.data;
            $scope.auxiliar = data;
            $scope.SistemaArchivosNombre[0] = $scope.auxiliar.Descripcion;
        });


        $scope.SistemaArchivosLong = 0;
    }

    gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
        //documentosService.getDocumentosByInf($routeParams.id)
        .then(function (response) {
            var data = response.data;
            $scope.documentacion = data;
            console.table(data);
        });


    $scope.UploadFiles = function (files) {
        //$scope.SelectedFiles = files;
        angular.forEach(files, function (file) {
            $scope.SelectedFiles.push(file);
        });
    };

    $scope.descargarDocumento = function (documento) {
        //var tipoMime = getTipoMime(documento.Extension);
        if (documento.Extension == "") {
            $scope.SistemaArchivosLong = $scope.SistemaArchivosLong + 1;
            $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong] = $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong - 1] + "\\" + documento.Nombre;
            $scope.SistemaArchivosNombre[$scope.SistemaArchivosLong] = documento.Nombre;
            $scope.rutaActual = $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong];
            gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                //documentosService.getDocumentosByInf($routeParams.id)
                .then(function (response) {
                    var data = response.data;
                    $scope.documentacion = data;
                });

        }
        else {
            gestiondocumentalService.descargarDocumentosByPath(documento.Path)
                .then(function (data, status, headers) {

                    var contentType = null;
                    var filename = documento.Nombre;
                    for (i = 0; i < mime.length; i++) {
                        if (mime[i][0] == documento.Extension) {
                            contentType = mime[i][1];
                        }
                    }


                    var blob = new Blob([data.data], { type: contentType });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, documento.Nombre);
                    } else {
                        var fileURL = URL.createObjectURL(blob);
                        window.open(fileURL);
                    }

                });
        }
    };

    $scope.descargarAbrir = function () {
        
        }

    $scope.descargarTodos = function () {
        $scope.documentosSeleccionados = "";
        angular.forEach($scope.documentacion, function (documento) {
            if (documento.Selected) {
                if ($scope.documentosSeleccionados != "") {
                    $scope.documentosSeleccionados = $scope.documentosSeleccionados + "," + documento.Path
                } else {
                    $scope.documentosSeleccionados = documento.Path
                }
            };
        });
        if ($scope.documentosSeleccionados.includes(",")) {
            gestiondocumentalService.descargarDocumentos($scope.documentosSeleccionados)
                .then(function (data, status, headers) {

                    var filename = "Archivos.zip";

                    var contentType = "application/octet-stream";
                    //var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    //var head = data.headers.get('Filename');

                    var blob = new Blob([data.data], { type: contentType });
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                        window.navigator.msSaveBlob(blob, filename);
                    else //Resto
                    {
                        var downloadLink = document.createElement("a");
                        var url = window.URL.createObjectURL(blob);
                        downloadLink.setAttribute("target", "_blank");
                        downloadLink.setAttribute("href", url);
                        downloadLink.setAttribute("download", filename);
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    }

                });
        }
        else {
            angular.forEach($scope.documentacion, function (documento) {
                if (documento.Selected) {
                    descargarUnDocumento(documento);
                }
            });
        }
    }

    function descargarUnDocumento(documento) {
        //var tipoMime = getTipoMime(documento.Extension);
        if (documento.Extension == "") {
            gestiondocumentalService.descargarDocumentos(documento.Path)
                .then(function (data, status, headers) {

                    var filename = "Archivos.zip";

                    var contentType = "application/octet-stream";
                    //var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    //var head = data.headers.get('Filename');

                    var blob = new Blob([data.data], { type: contentType });
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                        window.navigator.msSaveBlob(blob, filename);
                    else //Resto
                    {
                        var downloadLink = document.createElement("a");
                        var url = window.URL.createObjectURL(blob);
                        downloadLink.setAttribute("target", "_blank");
                        downloadLink.setAttribute("href", url);
                        downloadLink.setAttribute("download", filename);
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    }

                });

        }
        else {
            gestiondocumentalService.descargarDocumentosByPath(documento.Path)
                .then(function (data, status, headers) {

                    var filename = documento.Nombre;

                    var contentType = data.data.type;
                    //var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    //var head = data.headers.get('Filename');

                    var blob = new Blob([data.data], { type: contentType });
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                        window.navigator.msSaveBlob(blob, filename);
                    else //Resto
                    {
                        var downloadLink = document.createElement("a");
                        var url = window.URL.createObjectURL(blob);
                        downloadLink.setAttribute("target", "_blank");
                        downloadLink.setAttribute("href", url);
                        downloadLink.setAttribute("download", filename);
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    }

                });
        }
    };


        $scope.gotoFolder = function (sistema, indice) {
            $scope.SistemaArchivosLong = indice;
            $scope.rutaActual = $scope.SistemaArchivosRuta[indice]
            $scope.SistemaArchivosNombre.length = indice + 1;

            gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                //documentosService.getDocumentosByInf($routeParams.id)
                .then(function (response) {
                    var data = response.data;
                    $scope.documentacion = data;
                });
        }

        $scope.crearCarpeta = function () {
            var nombreCarpeta = prompt('Nombre de la Carpeta:', 'Nueva Carpeta');

            gestiondocumentalService.crearCarpeta($scope.rutaActual, nombreCarpeta)
                .then(function (response) {
                    gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                        //documentosService.getDocumentosByInf($routeParams.id)
                        .then(function (response) {
                            var data = response.data;
                            $scope.documentacion = data;
                        });
                });
        };

        $scope.QuitarArchivo = function (file) {

            $scope.SelectedFiles = $scope.SelectedFiles.filter(function (documento) {
                return documento !== file;
            });
        };

        $scope.editarDocumento = function () {
                var nuevoNombre = prompt('Desea cambiar el nombre del documento/carpeta ?', $rootScope.doc.Nombre);
                var extenLong = $rootScope.doc.Extension.length;
                var extenNueva = nuevoNombre.slice(-extenLong);
                if (extenNueva == $rootScope.doc.Extension)
                {
                    confirmacion = true;
                }
                else {
                    if (extenLong == 0) {
                        gestiondocumentalService.editarCarpeta($rootScope.doc.Path, nuevoNombre, $rootScope.doc.Nombre)
                            .then(function (response) {
                                gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                                    //documentosService.getDocumentosByInf($routeParams.id)
                                    .then(function (response) {
                                        var data = response.data;
                                        $scope.documentacion = data;
                                        $scope.SelectedFiles = new Array();
                                    });
                            });
                    }
                    else
                    {
                        confirmacion = confirm('Esta seguro de que quiere cambiar la extensión del documento?')
                    }
                }
            if (confirmacion)
            {
                gestiondocumentalService.editarDocumento($rootScope.doc.Path, nuevoNombre, $rootScope.doc.Nombre)
                    .then(function (response) {
                        gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                            //documentosService.getDocumentosByInf($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.documentacion = data;
                                $scope.SelectedFiles = new Array();
                            });
                    });
            }
        };


        $scope.eliminar = function () {
            confirmacion = confirm('Esta seguro de que quiere eliminar el documento\carpeta?')
            if (confirmacion) {
                $scope.documentosSeleccionados = "";
                angular.forEach($scope.documentacion, function (documento) {
                    if (documento.Selected) {
                        var extenLong = documento.Extension.length;
                        if (extenLong == 0 && confirmacion) {
                            gestiondocumentalService.eliminarCarpeta(documento.Path)
                                .then(function (response) {
                                    gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                                        //documentosService.getDocumentosByInf($routeParams.id)
                                        .then(function (response) {
                                            var data = response.data;
                                            $scope.documentacion = data;
                                            $scope.SelectedFiles = new Array();
                                        });
                                });
                        }

                        if (extenLong != 0 && confirmacion) {
                            gestiondocumentalService.eliminarDocumento(documento.Path)
                                .then(function (response) {
                                    gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                                        //documentosService.getDocumentosByInf($routeParams.id)
                                        .then(function (response) {
                                            var data = response.data;
                                            $scope.documentacion = data;
                                            $scope.SelectedFiles = new Array();
                                        });
                                });
                        }
                    };
                });
            }
        }

        $scope.eliminarDocumento = function () {
            var extenLong = $rootScope.doc.Extension.length;
            confirmacion = confirm('Esta seguro de que quiere eliminar el documento?')
                if (extenLong == 0 && confirmacion) {
                    gestiondocumentalService.eliminarCarpeta($rootScope.doc.Path)
                        .then(function (response) {
                            gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                                //documentosService.getDocumentosByInf($routeParams.id)
                                .then(function (response) {
                                    var data = response.data;
                                    $scope.documentacion = data;
                                    $scope.SelectedFiles = new Array();
                                });
                        });
                }

            if (extenLong != 0 && confirmacion) {
                gestiondocumentalService.eliminarDocumento($rootScope.doc.Path)
                    .then(function (response) {
                        gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                            //documentosService.getDocumentosByInf($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.documentacion = data;
                                $scope.SelectedFiles = new Array();
                            });
                    });
            }
        };


        $scope.seleccionarDocumento = function (documento) {
            alert(documento.Nombre);
            $scope.documentoMenu = documento;
        };


        $scope.subirArchivos = function () {
            var aux = $scope.SelectedFiles;
            var auxFile;
            var auxData;

            angular.forEach($scope.SelectedFiles, function (file) {
                
                auxFiles = file;

                gestiondocumentalService.upload(auxFiles, $scope.rutaActual)
                    .then(function (response) {
                        gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                            //documentosService.getDocumentosByInf($routeParams.id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.documentacion = data;
                                $scope.SelectedFiles = new Array();
                            });
                    });
            });
            
        };

        $scope.abrirDocumento = function () {
            //var tipoMime = getTipoMime(documento.Extension);
            if ($rootScope.doc.Extension == "")
            {
                $scope.SistemaArchivosLong = $scope.SistemaArchivosLong + 1;
                $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong] = $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong - 1] + "\\" + $rootScope.doc.Nombre;
                $scope.SistemaArchivosNombre[$scope.SistemaArchivosLong] = $rootScope.doc.Nombre;
                $scope.rutaActual = $scope.SistemaArchivosRuta[$scope.SistemaArchivosLong];
                gestiondocumentalService.getDocumentosByPath($scope.rutaActual)
                    //documentosService.getDocumentosByInf($routeParams.id)
                    .then(function (response) {
                        var data = response.data;
                        $scope.documentacion = data;
                    });    
            }
            else {
                gestiondocumentalService.descargarDocumentosByPath($rootScope.doc.Path)
                    .then(function (data, status, headers) {

                        var contentType = null;
                        var filename = $rootScope.doc.Nombre;
                        for (i = 0; i < mime.length; i++)
                        {
                            if (mime[i][0] == $rootScope.doc.Extension)
                            {
                                contentType = mime[i][1];
                            }
                        }


                        var blob = new Blob([data.data], { type: contentType });

                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, $rootScope.doc.Nombre);
                        } else {
                            var fileURL = URL.createObjectURL(blob);
                            window.open(fileURL);
                        }

                    });
            }
        };


}]);

app.directive('context', [
    '$rootScope',
    function ($rootScope) {
        return {
            restrict: 'A',
            scope: '@&',
            compile: function compile(tElement, tAttrs, transclude) {
                return {
                    post: function postLink(scope, iElement, iAttrs, controller) {
                        var ul = $('#' + iAttrs.context),
                            last = null;

                        ul.css({
                            'display': 'none'
                        });
                        $(iElement).bind('contextmenu', function (event) {
                            $rootScope.doc = scope.documento;
                            event.preventDefault();
                            ul.css({
                                position: "fixed",
                                display: "block",
                                left: event.clientX + 'px',
                                top: event.clientY + 'px'
                            });
                            last = event.timeStamp;
                        });
                        //$(iElement).click(function(event) {
                        //  ul.css({
                        //    position: "fixed",
                        //    display: "block",
                        //    left: event.clientX + 'px',
                        //    top: event.clientY + 'px'
                        //  });
                        //  last = event.timeStamp;
                        //});
                        //alert(element);
                        //alert(scope.documento.Path);
                        //$rootScope.doc = scope.documento;
                        $(document).click(function (event) {
                            var target = $(event.target);
                            if (!target.is(".popover") && !target.parents().is(".popover")) {
                                if (last === event.timeStamp)
                                    return;
                                ul.css({
                                    'display': 'none'
                                });
                            }
                            
                        });
                    }
                };
                alert(element);
            }
        };
    }
]);


//app.directive('context', function ($parse) {
//    return function (scope, element, attrs) {
//        $scope.seleccionado = element;
//        alert(element);
//        var fn = $parse(attrs.ngRightClick);
//        element.bind('contextmenu', function (event) {
//            scope.$apply(function () {
//                event.preventDefault();
//                fn(scope, { $event: event });
//            });
//        });
//    };
//});
angular.module('gis', [])
    .controller('GISController', ['$scope', '$sce', '$location', '$routeParams', 'gisService', function ($scope, $sce, $location, $routeParams, gisService) {

        gisService.getGIS($routeParams.id)
            .then(function (response) {
                var data = response.data;
                //$scope.modeloUrl = $sce.trustAsResourceUrl(data.Ruta);
                $scope.gisUrl = $sce.trustAsHtml(data.Ruta);
                //$scope.modeloUrl = data.Ruta;
            });
    }
    ]);
var nuevoIndiceInspeccion = angular.module('indiceInspeccionAdmin');

nuevoIndiceInspeccion.controller('nuevoIndiceInspeccionAdminController', ['$scope', '$routeParams', '$location', 'indiceInspeccionAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, indiceInspeccionAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;

    $scope.gestionIndicesInspeccion = function () {
        $location.path("/admin/indiceInspeccionAdmin");
    }

    $scope.guardarIndiceInspeccion = function () {
            indiceInspeccionAdminService.post($scope.descripcion, $scope.color).then(function (response) {
                var result = response.data;
                alert("Indice de Inspección añadido correctamente");
                $location.path("/admin/indiceInspeccionAdmin");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = indiceInspeccionAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    }]);
var infraestructuras = angular.module('infraestructuras', []);

infraestructuras.controller('categoriasInfraestructurasController', ['$scope', '$rootScope', '$location', 'infraestructurasService', 'authenticationInfo', 'authenticationService', function ($scope, $rootScope, $location, infraestructurasService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.userName = authenticationInfo.userName;
    $scope.rol = authenticationInfo.rol;
    $scope.usuario = $rootScope.usuario;
    $rootScope.parteUrl == false;


        infraestructurasService.getCategorias()
            .then(function (response) {
                var data = response.data;
                $scope.categorias = data;
            });
        
    $scope.verCategoria = function (idCategoria) {
        if (idCategoria == 4) {
            $rootScope.parteUrl = true;
        }
        if (idCategoria == 5) {

            $location.path('/galibos/' + idCategoria);
        } else {
            $location.path('/infraestructuras/' + idCategoria);
        }
        };
    }]);


angular.module('tourvirtual', [])
    .controller('TourVirtualController', ['$scope', '$sce', '$location', '$routeParams', 'tourvirtualService', function ($scope, $sce, $location, $routeParams, tourvirtualService) {

        tourvirtualService.getTourVirtual($routeParams.id)
            .then(function (response) {
                var data = response.data;
                //$scope.modeloUrl = $sce.trustAsResourceUrl(data.Ruta);
                $scope.tourvirtualUrl = $sce.trustAsHtml(data.Ruta);
                //$scope.modeloUrl = data.Ruta;
            });
    }
    ]);
angular.module('inspecciones')
    .controller('damageController', ['$rootScope', '$scope', '$location', '$route', '$routeParams', 'inspeccionesService', 'infraestructurasAdminService', 'authenticationInfo', 'authenticationService',
        function ($rootScope, $scope, $location, $route, $routeParams, inspeccionesService, infraestructurasAdminService, authenticationInfo, authenticationService) {

            $scope.authentication = authenticationInfo;
            //$scope.InfraestructuraId = $routeParams.idInfraestructura;           


            $scope.InspeccionId = $routeParams.idInspeccion;

            $scope.pestanyamenuinspeccion = 'general';

            $scope.indice = 0;

            $scope.IdDaño = $routeParams.id;


            infraestructurasAdminService.getInspectores()
                .then(function (response) {
                    var data = response.data;
                    $scope.inspectores = data;

                    infraestructurasAdminService.getTiposDaño()
                        .then(function (response) {
                            data = response.data;
                            $scope.tipos = data;

                            infraestructurasAdminService.getElementos()
                                .then(function (response) {
                                    data = response.data;
                                    $scope.elementos = data;

                                    inspeccionesService.getCategoriasDaño()
                                        .then(function (response) {
                                            var data = response.data;
                                            $scope.categorias = data;

                                            infraestructurasAdminService.getIntensidades()
                                                .then(function (response) {
                                                    var data = response.data;
                                                    $scope.intensidades = data;

                                                    infraestructurasAdminService.getEvoluciones()
                                                        .then(function (response) {
                                                            var data = response.data;
                                                            $scope.evoluciones = data;

                                                            inspeccionesService.getDaño($scope.IdDaño)
                                                                .then(function (response) {
                                                                    var data = response.data;
                                                                    $scope.damage = data;
                                                                });
                                                        });
                                                });

                                        });
                                });
                        });
                });











            //if ($scope.InspeccionId > 0) {
            //    inspeccionesService.getInspeccion($scope.InspeccionId)
            //        .then(function (response) {
            //            var data = response.data;
            //            $scope.inspeccion = data;
            //            $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
            //            $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
            //            //$scope.inspeccion.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo.toString();
            //            $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();                        
            //            $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin ? $scope.inspeccion.FechaFin.toString() : ''; // Verificación específica
            //            $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
            //            $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
            //            $scope.inspeccion.Inspector = $scope.inspeccion.Inspector.toString();

            //            $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.Tunel.IdentificadorTubo.toString();

            //            inspeccionesService.getDaños($scope.inspeccion.Id)
            //                .then(function (response) {
            //                    $scope.damages = response.data;
            //                });
            //        })
            //}







            //$scope.eliminarDamage = function (IdDaño) {
            //    $location.url('/damages/' + IdDaño);
            //}

            //$scope.verDamage = function (IdDaño) {
            //    $location.url('/damages/' + IdDaño);
            //}

            $scope.volver = function () {
                $location.path('/inspecciones/' + $scope.InspeccionId);
                $rootScope.inspeccionUrl = true;
            }

            $scope.guardar = function () {
                $scope.damage.CategoriaDañoId = $scope.damage.CategoriaId;
                $scope.damage.EvolucionDañoId = $scope.damage.EvolucionId;
                $scope.damage.TipoDañoId = $scope.damage.TipoId;
                $scope.damage.IntensidadDañoId = $scope.damage.IntensidadId;
                $scope.damage.PkDaño = $scope.damage.PkDano;
                inspeccionesService.guardarDaño($scope.damage)
                    .then(function (response) {
                        var data = response.data;
                        $scope.damage = data;
                        alert("Daño guardado correctamente")
                    });
            };

            $scope.logout = function () {
                //$rootScope.bodylayout = "login";
                return authenticationService.logout();
            }

            $scope.verCategorias = function () {
                $rootScope.inspeccionUrl = false;
                $rootScope.parteUrl = false;
                $location.path('/categorias');
            };
        }
    ]);

var editarPropietario = angular.module('propietariosAdmin');

editarPropietario.controller('propietarioAdminController', ['$scope', '$routeParams', '$location', 'propietariosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, propietariosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    propietariosAdminService.getPropietario($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.propietario = data;
                $scope.descripcion = data.Descripcion;
            });

    $scope.gestionPropietarios = function () {
        $location.path("/admin/propietariosAdmin");
    }

    $scope.editarPropietario = function () {
        propietariosAdminService.put($scope.descripcion, $scope.propietario.Id).then(function (response) {
                var result = response.data;
                alert(result);
                //$location.path("/admin/usuarios");
            $location.path("/admin/propietariosAdmin");

            }).catch(function (response) {
                $scope.error = propietariosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }


    }]);
var categoriaAdmin = angular.module('categoriaAdmin', []);

categoriaAdmin.controller('categoriaController', ['$scope', '$location', 'categoriaAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $location, categoriaAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.categoriaSeleccionados = "";
    var formData = new FormData();

    categoriaAdminService.getCategorias()
            .then(function (response) {
                var data = response.data;
                $scope.categorias = data;
            });
    $scope.verCategoria = function (categoria) {
        $location.path('/admin/editarCategoria/' + categoria);
            //$location.path('/Admin/' + idUsuario);

    };
    $scope.crearCategoria = function () {
        $location.path('/admin/categoria/nueva');
    }

    $scope.categoriaSeleccionados = ""
    $scope.eliminarCategorias = function () {
        var confirmado = confirm('¿Desea eliminar las categorias seleccionados?')
        if (confirmado) {
            angular.forEach($scope.categorias, function (categoria) {
                if (categoria.Selected) {
                    if ($scope.categoriaSeleccionados != "") {
                        $scope.categoriaSeleccionados = $scope.categoriaSeleccionados + "," + categoria.Id
                    } else {
                        $scope.categoriaSeleccionados = categoria.Id
                    }

                };
            });
            categoriaAdminService.eliminarCategorias($scope.categoriaSeleccionados).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.categoriaSeleccionados = [];
                categoriaAdminService.getCategorias()
                    .then(function (response) {
                        var data = response.data;
                        $scope.categorias = data;
                    });
            }).catch(function (response) {
                $scope.error = categoriaAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.categorias, function (categoria) {
                categoria.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);
var nuevoMunicipio = angular.module('municipiosAdmin');

nuevoMunicipio.controller('nuevoMunicipioAdminController', ['$scope', '$routeParams', '$location', 'municipiosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, municipiosAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;

    $scope.gestionMunicipios = function () {
        $location.path("/admin/municipiosAdmin");
    }

        $scope.guardarMunicipio = function () {
            municipiosAdminService.post($scope.descripcion).then(function (response) {
                var result = response.data;
                alert("Municipio añadido correctamente");
                $location.path("/admin/municipiosAdmin");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = municipiosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    }]);
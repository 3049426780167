angular
    .module('visormodelos')
    .factory('visormodelosService', ['$http', '$q', '__env', function visormodelosService($http, $q, __env) {

        var serviceUrl = __env.apiUrl;
        var visormodelosUrl = 'visormodelos';

        function getModeloInfraestructura(id) {        
            return $http.get(serviceUrl + visormodelosUrl + '/' + id);
        }

        return {
            getModeloInfraestructura: getModeloInfraestructura            
        }
    }]);

var tiposTajoAdmin = angular.module('tiposTajoAdmin', []);

tiposTajoAdmin.controller('tiposTajoController', ['$scope', '$location', 'tiposTajoAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $location, tiposTajoAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    tiposTajoAdminService.getTiposTajo()
            .then(function (response) {
                var data = response.data;
                $scope.tiposTajo = data;
            });

    $scope.verTipoTajo = function (idTipoTajo) {
        $location.path('/admin/editarTipoTajo/' + idTipoTajo);
            //$location.path('/Admin/' + idUsuario);

    };

    $scope.crearTipoTajo = function () {
        $location.path('/admin/tipoTajo/nuevo');
    }

    $scope.tiposTajoSeleccionados = ""
    $scope.eliminarTiposTajo = function () {
        var confirmado = confirm('¿Desea eliminar los tipos de tajo seleccionados?')
        if (confirmado) {
            angular.forEach($scope.tiposTajo, function (tipoTajo) {
                if (tipoTajo.Selected) {
                    if ($scope.tiposTajoSeleccionados != "") {
                        $scope.tiposTajoSeleccionados = $scope.tiposTajoSeleccionados + "," + tipoTajo.Id
                    } else {
                        $scope.tiposTajoSeleccionados = tipoTajo.Id
                    }

                };
            });
            tiposTajoAdminService.eliminarTiposTajo($scope.tiposTajoSeleccionados).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.tiposTajoSeleccionados = [];
                tiposTajoAdminService.getTiposTajo()
                    .then(function (response) {
                        var data = response.data;
                        $scope.tiposTajo = data;
                    });
            }).catch(function (response) {
                $scope.error = tiposTajoAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.tiposTajo, function (tipoTajo) {
                tipoTajo.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);
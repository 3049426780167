var categoriaAdminService = angular.module('categoriaAdmin');

categoriaAdminService.factory('categoriaAdminService', ['$http', '$q', '__env', function categoriaAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var categoriasUrl = 'categorias';
        var rolesUrl = 'roles';

    function getCategorias() {
        return $http.get(serviceUrl + categoriasUrl);
        }

    function getCategoria(id) {
        return $http.get(serviceUrl + categoriasUrl + '/' + id);
    }
    function getCategoriaAdmin(id) {
        return $http.get(serviceUrl + categoriasUrl + '/admin/' + id);
    }

        function put(categoria, imagen, imagenSubida, imagenHooverSubida, imagenDisabledSubida) {
            return $http({
                method: 'put',
                url: serviceUrl + categoriasUrl + '/editar/categoria/' + categoria.Id,
                data: imagen,
                params:
                {
                    'categoria': categoria.Descripcion,
                    'imagenSubida': imagenSubida,
                    'imagenHooverSubida': imagenHooverSubida,
                    'imagenDisabledSubida': imagenDisabledSubida
                },
                headers: { 'Content-Type': undefined }
            });
        }

    function post(descripcion, imagen) {
            //return $http.post(serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario, usuario);
            return $http({
                method: 'post',
                url: serviceUrl + categoriasUrl + '/registrar',
                data: imagen,
                params:
                {
                    'categoria': descripcion,
                },
                headers: { 'Content-Type': undefined }
            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }
    function eliminarCategorias(categoriasEliminar) {
        return $http.post(serviceUrl + categoriasUrl + '/eliminarCategoria/' + categoriasEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
                alert('Error');
            });
        }



        return {
            getCategorias: getCategorias,
            getCategoria: getCategoria,
            getCategoriaAdmin: getCategoriaAdmin,
            put: put,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarCategorias: eliminarCategorias,


        }

    }]);
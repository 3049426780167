//const { forEach } = require("lodash");

angular.module('inspecciones', [])
.controller('inspeccionesController', ['$scope', '$rootScope', '$routeParams', '$location', 'inspeccionesService', 'infraestructurasService', 'authenticationInfo', 'authenticationService',
    function ($scope, $rootScope, $routeParams, $location, inspeccionesService, infraestructurasService, authenticationInfo, authenticationService) {


        $scope.authentication = authenticationInfo;
        $scope.InfraestructuraId = $routeParams.id;
        $scope.TunelId = $routeParams.TunelId;
        $rootScope.TunelId = $routeParams.TunelId;

        inspeccionesService.getInspecciones($scope.idTunel)
            .then(function (response) {
                var data = response.data;
                $scope.inspecciones = data;
            });

        $scope.crearInspeccion = function () {
            inspeccionesService.crearInspeccion($scope.InfraestructuraId)
                .then(function (response) {
                    var data = response.data;
                    $scope.inspeccionId = data;
                    $location.url('/inspecciones/' + $routeParams.id + "/" + $scope.inspeccionId);
                });
        };

        $scope.verCategorias = function () {
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
            $location.path('/categorias');
        };
        //$scope.verInspeccion = function (idInspeccion) {
        //    $location.url('/inspecciones/' + $routeParams.id + "/" + idInspeccion);
        //}

        $scope.volver = function () {
            $rootScope.inspeccionUrl == false;
            $rootScope.parteUrl == false;
            $location.path('/categorias');
        };


        $scope.eliminarInspeccion = function (idInspeccion) {
            check = confirm("¿Seguro que deseas eliminar esta inspección?")

            if (check) {
                inspeccionesService.eliminarinspeccion(idInspeccion)
                    .then(function (response) {
                        inspeccionesService.getInspecciones($scope.idInspeccion)
                            .then(function (response) {
                                var data = response.data;
                                $scope.inspecciones = data;
                            });
                    });
            }
        }
    }]);


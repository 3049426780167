var infraestructuras = angular.module('infraestructuras');

infraestructuras.controller('infraestructurasController', ['$rootScope', '$scope', '$location', 'infraestructurasService', '$routeParams', 'authenticationInfo', 'authenticationService', '$sce',
    function ($rootScope, $scope, $location, infraestructurasService, $routeParams, authenticationInfo, authenticationService, $sce) {

        $scope.authentication = authenticationInfo;


        $rootScope.categoria = $routeParams.id;

        switch ($rootScope.categoria) {

            case '1': $scope.categoriaIcono = "active icon-puentes";
                break;

            case '2': $scope.categoriaIcono = "active icon-tuneles";
                break;

            case '3': $scope.categoriaIcono = "active icon-presas";
                break;


            case '4': $scope.categoriaIcono = "active icon-obras";
                break;

            case '5': $scope.categoriaIcono = "active icon-edar";
                break;

            case '6': $scope.categoriaIcono = "active icon-edificios";
                break;

            case '21': $scope.categoriaIcono = "active icon-tuneles-ferroviarios";
                break;
        }

        //infraestructurasService.getCategoria($rootScope.categoria)
        //    .then(function (response) {
        //        var data = response.data;
        //        $scope.categoria = data;
        //    });


        //infraestructurasService.getInfraestructurasCategoria($routeParams.id)
        infraestructurasService.getInfraestructurasCategoria($routeParams.id, $rootScope.usuario)
            .then(function (response) {
                var data = response.data;
                $scope.infraestructuras = data;

                for (var i = 0; i < $scope.infraestructuras.length; ++i) {
                    if ($scope.infraestructuras[i].IndiceInspeccion.Id == 1)
                        $scope.infraestructuras[i].IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-0 row";
                    else if ($scope.infraestructuras[i].IndiceInspeccion.Id == 2)
                        $scope.infraestructuras[i].IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-1 row";
                    else if ($scope.infraestructuras[i].IndiceInspeccion.Id == 3)
                        $scope.infraestructuras[i].IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-2 row";

                    $scope.infraestructuras[i].Tiempo = $sce.trustAsHtml($scope.infraestructuras[i].Tiempo);
                }

                var myLatlng = new google.maps.LatLng(43.20523189138532, -2.8637797716569295);

                var myOptions = {
                    zoom: 14,
                    center: myLatlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };

                $scope.map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);


                var icono = "http://maps.google.com/mapfiles/ms/icons/red-dot.png"

                angular.forEach($scope.infraestructuras, function (infraestructura) {
                    myLatlng = new google.maps.LatLng(infraestructura.PosicionamientoYInfraestructura, infraestructura.PosicionamientoXInfraestructura);

                    if (infraestructura.IndiceInspeccion.Id == 3) {
                        icono = "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                        var markerGreen = new google.maps.Marker({
                            position: myLatlng,
                            title: infraestructura.Descripcion,
                            draggable: false,
                            icon: {
                                url: icono
                            }
                        });
                        markerGreen.setMap($scope.map);
                    }
                    else {
                        if (infraestructura.IndiceInspeccion.Id == 2) {
                            icono = "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
                            var markerYellow = new google.maps.Marker({
                                position: myLatlng,
                                title: infraestructura.Descripcion,
                                draggable: false,
                                icon: {
                                    url: icono
                                }
                            });
                            markerYellow.setMap($scope.map);
                        }
                        else {
                            icono = "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                            var markerRed = new google.maps.Marker({
                                position: myLatlng,
                                title: infraestructura.Descripcion,
                                draggable: false,
                                icon: {
                                    url: icono
                                }
                            });
                            markerRed.setMap($scope.map);
                        }
                    }


                });

            });

        //infraestructurasService.getInfraestructurasCategoria($routeParams.id)
        //    .then(function (response) {
        //        var data = response.data;
        //        $scope.infraestructuras = data;

        //        for (var i = 0; i < $scope.infraestructuras.length; ++i) {
        //            if ($scope.infraestructuras[i].IndiceInspeccion.Id == 1)
        //                $scope.infraestructuras[i].IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-0 row";
        //            else if ($scope.infraestructuras[i].IndiceInspeccion.Id == 2)
        //                $scope.infraestructuras[i].IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-1 row";
        //            else if ($scope.infraestructuras[i].IndiceInspeccion.Id == 3)
        //                $scope.infraestructuras[i].IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-2 row";
        //        }
        //    });


        $scope.verCategorias = function () {
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
            $location.path('/categorias');
        };



        $scope.verInfraestructura = function (idInfraestructura) {
            $location.path('/infraestructura/' + idInfraestructura);

        };

        $scope.irInfraestructura = function (PosicionamientoYInfraestructura, PosicionamientoXInfraestructura) {

            myLatlng = new google.maps.LatLng(PosicionamientoYInfraestructura, PosicionamientoXInfraestructura);

            $scope.map.panTo(myLatlng);
        };

    }]);


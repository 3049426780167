angular.module('auth')

    .factory('authenticationInfo', ['$rootScope', 'storage', function ( $rootScope, storage) {
        var isAuthenticated = false,
            userName = "",
            rol = "",
            token = "",
            storageKey = 'authorizationData';

        var authData = storage.get(storageKey);

        if (authData) {
            isAuthenticated = true;
            userName = authData.userName;
            rol = authData.rol;
            token = authData.token;
        }

        function save() {
            storage.set(storageKey, {
                token: token,
                userName: userName,
                rol: rol
            }); 
            $rootScope.usuario = userName;
            $rootScope.rol = rol;
        }

        function clear() {
            storage.remove(storageKey);

            isAuthenticated = false;
            userName = "";
            rol = "";
            token = "";
        }

        var authenticationInfoFactory = {
            save: save,
            clear: clear
        };

        Object.defineProperty(authenticationInfoFactory, "isAuthenticated", {
            get: function () {
                return isAuthenticated;
            },
            set: function (value) {
                isAuthenticated = value;
            }
        });

        Object.defineProperty(authenticationInfoFactory, "userName", {
            get: function () {
                return userName;
            },
            set: function (value) {
                userName = value;
            }
        });

        Object.defineProperty(authenticationInfoFactory, "rol", {
            get: function () {
                return rol;
            },
            set: function (value) {
                rol = value;
            }
        });

        Object.defineProperty(authenticationInfoFactory, "token", {
            get: function () {
                return token;
            },
            set: function (value) {
                token = value;
            }
        });

        return authenticationInfoFactory;
    }]);
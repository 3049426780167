var usuariosAdmin = angular.module('usuariosAdmin', []);

usuariosAdmin.controller('usuariosController', ['$scope', '$location', 'usuariosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $location, usuariosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    usuariosAdminService.getUsuarios()
            .then(function (response) {
                var data = response.data;
                $scope.usuarios = data;
            });
        $scope.verUsuario = function (idUsuario) {
            $location.path('/admin/editar/' + idUsuario);
            //$location.path('/Admin/' + idUsuario);

    };
    $scope.crearUsuario = function () {
        $location.path('/admin/usuarios/nuevo');
    }

        $scope.usuariosSeleccionados = ""
    $scope.eliminarUsuarios = function () {
        var confirmado = confirm('¿Desea eliminar los usuarios seleccionados?')
        if (confirmado) {
            angular.forEach($scope.usuarios, function (usuario) {
                if (usuario.Selected) {
                    if ($scope.usuariosSeleccionados != "") {
                        $scope.usuariosSeleccionados = $scope.usuariosSeleccionados + "," + usuario.Id
                    } else {
                        $scope.usuariosSeleccionados = usuario.Id
                    }

                };
            });
            usuariosAdminService.eliminarUsuarios($scope.usuariosSeleccionados).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.usuariosSeleccionados = [];
                usuariosAdminService.getUsuarios()
                    .then(function (response) {
                        var data = response.data;
                        $scope.usuarios = data;
                    });
            }).catch(function (response) {
                $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.usuarios, function (usuario) {
                usuario.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);
var app = angular
    .module('inspecciones')
    .factory('inspeccionesService', ['$http', '$q', '__env', '$routeParams', function inspeccionesService($http, $q, __env, $routeParams) {
        var serviceUrl = __env.apiUrl;
        var inspeccionesUrl = 'inspecciones';


        function getInspecciones() {
            return $http.get(serviceUrl + inspeccionesUrl + '/tunel/' + $routeParams.id);
        }

        function getInspeccion(idTunel, idInspeccion) {
            return $http.get(serviceUrl + inspeccionesUrl + '/' + idTunel + '/' + idInspeccion);
        }

        function getElementosInspeccionados(idInspeccion) {
            return $http.get(serviceUrl + inspeccionesUrl + '/elementosinspeciionados/' + idInspeccion);
        }

        function getElementosEvaluados(idInspeccion) {
            return $http.get(serviceUrl + inspeccionesUrl + '/elementosevaluados/' + idInspeccion);
        }


        function crearInspeccion(tunelId) {
            return $http({
                method: 'post',
                url: serviceUrl + inspeccionesUrl + '/crear/',
                params:
                {
                    'tunelId': tunelId
                }
            });
        }


        function putInspeccion(inspeccion) {
            return $http.put(serviceUrl + inspeccionsUrl + '/put/');
        }




        function verInspeccion(path, nombre) {
            return $http({
                method: 'post',
                url: serviceUrl + inspeccionesUrl + '/',
                params:
                {
                    'parte': path + '/' + nombre,
                }
            });
        }


        function modificarInspeccion(path, nombre) {
            return $http({
                method: 'post',
                url: serviceUrl + inspeccionessUrl + '/',
                params:
                {
                    'parte': path + '/' + nombre,
                }
            });
        }


        function eliminarInspeccion(inspeccionId) {
            return $http({
                method: 'post',
                url: serviceUrl + inspeccionesUrl + '/eliminarInspeccion/' + inspeccionId
            });
        }

        return {

            getInspecciones: getInspecciones,
            eliminarInspeccion: eliminarInspeccion,
            modificarInspeccion: modificarInspeccion,
            verInspeccion: verInspeccion,
            putInspeccion: putInspeccion,
            getInspeccion: getInspeccion,
            getElementosInspeccionados: getElementosInspeccionados,
            getElementosEvaluados: getElementosEvaluados
        }
    }]);
var usuariosAdminService = angular.module('usuariosAdmin');

usuariosAdminService.factory('usuariosAdminService', ['$http', '$q', '__env', function usuariosAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var usuariosUrl = 'usuarios';
        var rolesUrl = 'roles';
        var DobleAutenticationUrl = '2FA';

        function getUsuarios() {
            return $http.get(serviceUrl + usuariosUrl);
        }

        function getUsuario(id) {
            return $http.get(serviceUrl + usuariosUrl + '/' + id);
        }

        function getUsuarioByName(nombre) {
            return $http({
                method: 'get',
                url: serviceUrl + usuariosUrl + '/nombre/',
                params:
                {
                    'nombre': nombre,
                }
            });
        }

        function put(usuario, idRolUsuario) {
            return $http.put(serviceUrl + usuariosUrl + '/editar/' + idRolUsuario, usuario);
        }

        function putPropio(usuario) {
            return $http.put(serviceUrl + usuariosUrl + '/editarUsuario/'+ 1, usuario);
        }



        function post(usuario, idRolUsuario) {
            //return $http.post(serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario, usuario);
            return $http({
                method: 'post',
                url: serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario,
                params:
                {
                    'userName': usuario.Username,
                    'nombre': usuario.Nombre,
                    'contraseña': usuario.PasswordHash,
                    'apellidos': usuario.Apellidos,
                    'email': usuario.Email,
                    'telefono': usuario.PhoneNumber
                }

            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }
        function eliminarUsuarios(usuariosEliminar) {
            return $http.post(serviceUrl + usuariosUrl + '/eliminar/' + usuariosEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
                alert('Error');
            });
        }

        function getRoles() {
            return $http.get(serviceUrl + rolesUrl);
        }
        function getRolesPorUsuario(id) {
            return $http.get(serviceUrl + rolesUrl + '/' + id);
        }
        
        function dobleAutentication(usuario) {
            //return $http.get(serviceUrl + DobleAutenticationUrl + '/crear/' + usuario);
            return $http({
                method: 'get',
                url: serviceUrl + DobleAutenticationUrl + '/GetQRCode/',
                params:
                {
                    'username': usuario
                }

            });
    }

    function verificar2FA(codigo, usuario) {
        //return $http.get(serviceUrl + DobleAutenticationUrl + '/crear/' + usuario);
        return $http({
            method: 'get',
            url: serviceUrl + DobleAutenticationUrl + '/ValidarQRCode/',
            params:
            {
                'codigo': codigo,
                'username': usuario
            }

        });
    }


        return {
            getUsuarios: getUsuarios,
            getUsuario: getUsuario,
            getUsuarioByName: getUsuarioByName,
            put: put,
            putPropio: putPropio,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarUsuarios: eliminarUsuarios,
            getRoles: getRoles,
            getRolesPorUsuario: getRolesPorUsuario,
            dobleAutentication: dobleAutentication,
            verificar2FA: verificar2FA
        }

    }]);
var propietariosAdmin = angular.module('propietariosAdmin', []);

propietariosAdmin.controller('propietariosController', ['$scope', '$location', 'propietariosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $location, propietariosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.propietariosSeleccionados = "";

    propietariosAdminService.getPropietarios()
            .then(function (response) {
                var data = response.data;
                $scope.propietarios = data;
            });
    $scope.verPropietario = function (idPropietario) {
        $location.path('/admin/editarPropietarios/' + idPropietario);
            //$location.path('/Admin/' + idUsuario);

        };

    $scope.crearPropietario = function () {
        $location.path('/admin/Propietarios/nuevo');
    }

    $scope.propietarioSeleccionados = ""
    $scope.eliminarPropietarios = function () {
        var confirmado = confirm('¿Desea eliminar los propietarios seleccionados?')
        if (confirmado) {
            angular.forEach($scope.propietarios, function (propietario) {
                if (propietario.Selected) {
                    if ($scope.propietariosSeleccionados != "") {
                        $scope.propietariosSeleccionados = $scope.propietariosSeleccionados + "," + propietario.Id
                    } else {
                        $scope.propietariosSeleccionados = propietario.Id
                    }

                };
            });
            propietariosAdminService.eliminarPropietarios($scope.propietariosSeleccionados).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.propietariosSeleccionados = [];
                propietariosAdminService.getPropietarios()
                    .then(function (response) {
                        var data = response.data;
                        $scope.propietarios = data;
                    });
            }).catch(function (response) {
                $scope.error = propietariosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.propietarios, function (propietario) {
                propietario.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);
//const { forEach } = require("lodash");

var app = angular.module('partes')

app.controller('parteController', ['$rootScope', '$scope', '$location', '$route', '$routeParams', 'partesService', 'infraestructurasService', 'authenticationInfo', 'authenticationService',
    function ($rootScope, $scope, $location, $route, $routeParams, partesService, infraestructurasService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.InfraestructuraId = $routeParams.idInfraestructura;

        $scope.ParteId = $routeParams.id;

        infraestructurasService.getInfraestructura($scope.InfraestructuraId)
            .then(function (response) {
                var data = response.data;
                $scope.infraestructura = data;
            });

    partesService.getRol($rootScope.usuario, $scope.InfraestructuraId)
        .then(function (response) {
            $scope.permiso = response.data;
        });

    $scope.pestanyavisible = ($scope.ParteId > 0) ? 'general' : 'parte';

    $scope.indice = 0;

    if ($scope.ParteId > 0) {
        partesService.getParte($scope.InfraestructuraId, $scope.ParteId)
            .then(function (response) {
                var data = response.data;
                $scope.parte = data;
                $scope.parte.Parte.Parte.VisitaCoordSS = $scope.parte.Parte.Parte.VisitaCoordSS.toString();
                $scope.parte.Parte.Parte.VisitaControlMA = $scope.parte.Parte.Parte.VisitaControlMA.toString();
                $scope.parte.Parte.Parte.VisitaInspeccionEquipos = $scope.parte.Parte.Parte.VisitaInspeccionEquipos.toString();
                $scope.parte.Parte.Parte.VisitaLaboratorio = $scope.parte.Parte.Parte.VisitaLaboratorio.toString();
                $scope.parte.Parte.Parte.VisitaTopografo = $scope.parte.Parte.Parte.VisitaTopografo.toString();

            })

    }

    //  $scope.ParteTajo.ParteId = $routeParams.id;




    //partesService.getTajosParte()
    //    .success(function (data) {
    //        $scope.tajosparte = data;
    //    });


    partesService.getTiposTajo($scope.InfraestructuraId)
        .then(function (response) {
            $scope.tipostajostajo = response.data;
        });


    //$scope.idNuevoTajo = 0;
    $scope.nuevoTajo = function () {
        $scope.indice = $scope.indice - 1
        var tajoNuevo;
        tajoNuevo = {
            Id: $scope.indice,
            TipoTajo: null,
            TipoTajoId: null,
            Parte: null,
            ParteId: $scope.ParteId,
            Descripcion: null,
            Medios: null
        }
        $scope.parte.Parte.Tajos.push(tajoNuevo);
    }
    //    var descripcionTajo = null;
    //    if ($scope.parte.Tajos == null) $scope.parte.Tajos = [];
    //    var numTajo = $scope.parte.Tajos.length;
    //    var tajosNombres = 'abcdefghijklmnopqrstuvwxyz';
    //    descripcionTajo = tajosNombres.substr(numTajo, 1);
    //    tajoNombreAsignado = $filter('filter')($scope.parte.Tajos, { DescripcionTajo: descripcionTajo });
    //    if (tajoNombreAsignado.length > 0) DescripcionTajo = '';
    //    $scope.idNuevoTajo--;
    //    var tajo = {
    //        Id: $scope.idNuevoTajo,
    //        TajoDescripcion: descripcionTajo,
    //        TajoMedios: null,

    //    };
    //    $scope.parte.Tajos.push(tajo);
    //}
    //$scope.eliminarTajo = function (index) {
    //    $scope.parte.Tajos.splice(index, 1);
    //}


    $scope.volver = function () {
        $location.path('/infraestructura/' + $scope.InfraestructuraId);
        $rootScope.parteUrl = true;
    }

        $scope.verCategorias = function () {
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
            $location.path('/categorias');
        };

    $scope.DatosTajoCargar = function (pestanya) {
        $scope.pestanyavisible = 'tajo';
        //partesService.getTiposTajo($scope.ParteId)
        //    .then(function (response) {
        //        var data = response.data;
        //        $scope.tipostajo = data;
        //    })
    }

    $scope.DatosImprimirInforme = function () {
        $scope.pestanyavisible = 'informeseguimiento';
    }

    $scope.DatosAsistenciaTecnicaCargar = function () {
        $scope.pestanyavisible = 'actasistenciatecnica';
    }

    $scope.DatosControlCalidadCargar = function () {
        $scope.pestanyavisible = 'controlcalidad';
    }


    //Imágenes SILVIA

    $scope.archivosMultiplesImagenes = [];


    $scope.aux = [];

    $scope.uploadingMultipleFilesImagenes = false;

    partesService.getImagenesPorParte($scope.ParteId)
        .then(function (data) {
            $scope.archivosParte = data.data;
        });

        $scope.formDataArchivosMultiplesImagenes = new FormData();
        $scope.tajoImagenes = "";

    $scope.getFilesImagenes = function (files, tajoId) {
        //$scope.aux = []
        //if (file != undefined) {
        //    $scope.aux.push(file)
        //angular.forEach($scope.aux, function (value, key) {
        angular.forEach(files, function (value, key) {
            $scope.formDataArchivosMultiplesImagenes.append(key, value);
            $scope.archivosMultiplesImagenes.push({ FileName: value.name, FileLength: value.size, FileTajo: tajoId });
            $scope.tajoImagenes = $scope.tajoImagenes + tajoId + ",";
            })
            partesService.getImagenesPorParte($scope.ParteId)
                .then(function (data) {
                    $scope.archivosParte = data.data;
                    $("#lista-imagenes-parte").load(location.href + " #lista-imagenes-parte");
                });
        //}
    };

    $scope.imagenGuardarEliminar = function (nombre, tamano) {
        for (var i = 0; i < $scope.archivosMultiplesImagenes.length; i++) {
            var obj = $scope.archivosMultiplesImagenes[i];
            if (obj.FileLength == tamano && obj.FileName == nombre) {
                $scope.archivosMultiplesImagenes.splice(i, 1);
            }
        }
        var aux = $scope.archivosMultiplesImagenes;
    }

    $scope.eliminarTajo = function (tajoId) {
        var isConfirmed = confirm("¿Seguro que quieres eliminar este Tajo?");
        if (isConfirmed) {
            for (var i = 0; i < $scope.parte.Parte.Tajos.length; i++) {
                var obj = $scope.parte.Parte.Tajos[i];
                if (obj.Id == tajoId) {
                    if (obj.Id < 0) {
                        $scope.parte.Parte.Tajos.splice(i, 1);
                    }
                    else {
                        partesService.eliminarTajo(obj)
                            .then(function (response) {
                                $scope.parte.Parte.Tajos = response.data;
                                partesService.getImagenesPorParte($scope.ParteId)
                                    .then(function (data) {
                                        $scope.archivosParte = data.data;
                                    });
                            });
                    }
                }
            }
        }
    }

    $scope.descargarImagen = function (nombre, imagen) {
        //window.location.href = 'data:application/octet-stream;base64,' + imagen;
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + imagen; //Image Base64 Goes here
        a.download = nombre; //File name Here
        a.click(); //Downloaded file
    }

    $scope.uploadFilesImagenes = function () {
        if ($scope.tajoImagenes.indexOf("-") == -1) {
            $scope.uploadingMultipleFilesImagenes = true;
            partesService.postImagenes($scope.formDataArchivosMultiplesImagenes, $scope.tajoImagenes)
                .then(function (data) {
                    $scope.uploadingMultipleFilesImagenes = false;
                    $scope.formDataArchivosMultiplesImagenes = new FormData();
                    $scope.archivosMultiplesImagenes = [];
                    $scope.tajoImagenes = "";

                    $scope.$apply;
                    partesService.getImagenesPorParte($scope.ParteId)
                        .then(function (data) {
                            $scope.archivosParte = data.data;
                            alert("Imagenes guardadas correctamente");
                        })
                        .error(function (response) {
                            $location.path('/partes/' + $routeParams.id);
                        });
                }), function (error) {
                    $scope.uploadingMultipleFilesImagenes = false;
                    alert("Error al guardar las imagenes ");
                }
                    .error(function (error) {
                        $scope.uploadingMultipleFilesImagenes = false;
                        alert("Error al guardar las imagenes ");
                    });
        }
        else {
            alert("Hay que guardar el tajo antes de subir imagenes");
        }
    };

        $scope.imagenEliminar = function (indice) {
            var isConfirmed = confirm("¿Seguro que quieres eliminar esta imagen?");
            if (isConfirmed)
            { 
            partesService.deleteImagenTajo($scope.ParteId, $scope.parte.tajo.Imagenes[indice].Id, $scope.parte.tajo.Imagenes[indice].Nombre)
                .success(function (data) {
                    alert('Imagen eliminada correctamente');
                    $scope.parte.tajo.Imagenes.splice(indice, 1);
                })
                .error(function (response) {
                    $scope.error = partesService.obtenerPrimerErrorModelState(response);
                    alert($scope.error);
                });
        }
    };


    $scope.guardar = function (mostrarAlerta) {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);

        var parteSQL = {
            Id: $scope.ParteId,
            FechaParte: $scope.parte.Parte.Parte.FechaParte,
            Fecha: today.toISOString(),
            RealizadoPor: $scope.parte.Parte.Parte.RealizadoPor,
            Cliente: $scope.parte.Parte.Parte.Cliente,
            InfraestructuraId: $scope.InfraestructuraId,
            Temperatura: $scope.parte.Parte.Parte.Temperatura,
            Climatologia: $scope.parte.Parte.Parte.Climatologia,
            VisitaCoordSS: $scope.parte.Parte.Parte.VisitaCoordSS,
            Observaciones_VisitaCoordSS: $scope.parte.Parte.Parte.Observaciones_VisitaCoordSS,
            VisitaControlMA: $scope.parte.Parte.Parte.VisitaControlMA,
            Observaciones_VisitaControlMA: $scope.parte.Parte.Parte.Observaciones_VisitaControlMA,
            Visitalaboratorio: $scope.parte.Parte.Parte.VisitaLaboratorio,
            Observaciones_VisitaLaboratorio: $scope.parte.Parte.Parte.Observaciones_VisitaLaboratorio,
            VisitaInspeccionEquipos: $scope.parte.Parte.Parte.VisitaInspeccionEquipos,
            Observaciones_VisitaInspeccionEquipos: $scope.parte.Parte.Parte.Observaciones_VisitaInspeccionEquipos,
            VisitaTopografo: $scope.parte.Parte.Parte.VisitaTopografo,
            Observaciones_VisitaTopografo: $scope.parte.Parte.Parte.Observaciones_VisitaTopografo,
            TopografiaRealizadaPor: $scope.parte.Parte.Parte.TopografiaRealizadaPor,
            TopografiaDuracion: $scope.parte.Parte.Parte.TopografiaDuracion,
            TopografiaComprobaciones: $scope.parte.Parte.Parte.TopografiaComprobaciones,
            TopografiaMediciones: $scope.parte.Parte.Parte.TopografiaMediciones,
            TajoControlCalidad: $scope.parte.Parte.Parte.TajoControlCalidad,
            TipoEnsayoControlCalidad: $scope.parte.Parte.Parte.TipoEnsayoControlCalidad,
            NumControlCalidad: $scope.parte.Parte.Parte.NumControlCalidad,
            LaboratorioControlCalidad: $scope.parte.Parte.Parte.LaboratorioControlCalidad,
            ObservacionesControlCalidad: $scope.parte.Parte.Parte.ObservacionesControlCalidad
        }
        var tajosSQL = [];
        var tajo;
        //var tajos = $scope.parte.Parte.Tajos;
        for (var i = 0; i < $scope.parte.Parte.Tajos.length; i++) {
            tajo = {
                Id: $scope.parte.Parte.Tajos[i].Id,
                ParteId: $scope.ParteId,
                TipoTajoId: $scope.parte.Parte.Tajos[i].TipoTajoId,
                Descripcion: $scope.parte.Parte.Tajos[i].Descripcion,
                Medios: $scope.parte.Parte.Tajos[i].Medios
            }
            tajosSQL.push(tajo);
        }

        var formDataTexto = new FormData();
        formDataTexto.append("tajos", JSON.stringify(tajosSQL));
        formDataTexto.append("parte", JSON.stringify(parteSQL));
        partesService.putParte(parteSQL, tajosSQL, formDataTexto)
            .then(function (data) {
                partesService.getParte($scope.InfraestructuraId, $scope.ParteId)
            .then(function (response) {
                var data = response.data;
                $scope.parte = data;
                $scope.parte.Parte.Parte.VisitaCoordSS = $scope.parte.Parte.Parte.VisitaCoordSS.toString();
                $scope.parte.Parte.Parte.VisitaControlMA = $scope.parte.Parte.Parte.VisitaControlMA.toString();
                $scope.parte.Parte.Parte.VisitaInspeccionEquipos = $scope.parte.Parte.Parte.VisitaInspeccionEquipos.toString();
                $scope.parte.Parte.Parte.VisitaLaboratorio = $scope.parte.Parte.Parte.VisitaLaboratorio.toString();
                $scope.parte.Parte.Parte.VisitaTopografo = $scope.parte.Parte.Parte.VisitaTopografo.toString();
                $scope.tajoImagenes = "";
                $scope.formDataArchivosMultiplesImagenes = new FormData();
                $scope.uploadingMultipleFilesImagenes = false;
                $scope.archivosMultiplesImagenes = [];
                alert('Datos guardados correctamente');
            })
            })
    }

    $scope.crearTipoTajo = function () {
        var Descripcion = prompt('Inserta el tipo tajo:', 'Tipo tajo');
        partesService.crearTipoTajo($scope.ParteId, Descripcion, $routeParams.idInfraestructura)
            .then(function (response) {
                $scope.tipostajostajo = response.data;
            })
    }

        $scope.imagenEliminar = function (nombre, tajoId) {
            var isConfirmed = confirm("¿Seguro que quieres eliminar esta imagen?");
            if (isConfirmed) {
                partesService.imagenEliminar(nombre, tajoId, $scope.ParteId)
                    .then(function (response) {
                        $scope.archivosParte = response.data;
                    })
            alert('Imagen eliminada');
            }
    }

    $scope.imprimirSeguimiento = function () {
        var inicio = $scope.fechainicio;
        var fin = $scope.fechafin;
        var opciones = $scope.opciones;
        var elemento = document.getElementById('loader-seguimiento');
        


        if (opciones == null || opciones == "")
            alert("Es necesario que seleccione los tajos que desea imprimir en el informe");
        else if (inicio == "" || inicio == null || fin == "" || fin == null )
            alert("Es necesario que introduzca la fecha de inicio y la fecha de fin para el informe");
        else {
            elemento.style.display = 'block';
            partesService.imprimirSeguimiento(inicio, fin, opciones, $scope.InfraestructuraId)
                .then(function (data, status, headers) {

                    var filename = $scope.parte.Descripcion + "_Informe_Seguimiento.pdf";
                    var contentType = 'application/pdf';

                    var blob = new Blob([data.data],
                        { type: contentType });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                        window.navigator.msSaveBlob(blob, filename);
                    else //Resto
                    {
                        var downloadLink = document.createElement("a");

                        var url = window.URL.createObjectURL(blob);
                        downloadLink.setAttribute("target", "_blank");
                        downloadLink.setAttribute("href", url);
                        downloadLink.setAttribute("download", filename);
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        elemento.style.display = 'none';
                    }
                })
        }
    }
        $scope.logout = function () {
            //$rootScope.bodylayout = "login";
            return authenticationService.logout();
        }


}
]);

var editarIndiceInspeccion = angular.module('indiceInspeccionAdmin');

editarIndiceInspeccion.controller('indiceInspeccionAdminController', ['$scope', '$routeParams', '$location', 'indiceInspeccionAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, indiceInspeccionAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    indiceInspeccionAdminService.getIndiceInspeccion($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.indiceInspeccion = data;
            });


    $scope.editarIndiceInspeccion = function () {
        indiceInspeccionAdminService.put($scope.indiceInspeccion.Descripcion, $scope.indiceInspeccion.Color, $scope.indiceInspeccion.Id).then(function (response) {
                var result = response.data;
                alert("Entorno guardado correctamente");
                //$location.path("/admin/usuarios");
            $location.path("/admin/indiceInspeccionAdmin");

            }).catch(function (response) {
                $scope.error = indiceInspeccionAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }


    }]);
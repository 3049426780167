var nuevoPropietario = angular.module('propietariosAdmin');

nuevoPropietario.controller('nuevoPropietarioAdminController', ['$scope', '$routeParams', '$location', 'propietariosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, propietariosAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;

    $scope.gestionPropietarios = function () {
        $location.path("/admin/propietariosAdmin");
    }

        $scope.guardarPropietario = function () {
            propietariosAdminService.post($scope.descripcion).then(function (response) {
                var result = response.data;
                alert(result);
                $location.path("/admin/propietariosAdmin");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = propietariosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    }]);

var editarTipoTajo = angular.module('tiposTajoAdmin');

editarTipoTajo.controller('tiposTajoAdminController', ['$scope', '$routeParams', '$location', 'tiposTajoAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, tiposTajoAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    tiposTajoAdminService.getTipoTajo($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.tipotajo = data;
            });


    tiposTajoAdminService.getInfraestructuras(4)
            .then(function (response) {
                var data = response.data;
                $scope.infraestructuras = data;
            });

    $scope.gestionTiposTajo = function () {
        $location.path("/admin/tiposTajoAdmin");
    }

    $scope.editarTipoTajo = function () {
            $scope.tipotajo.Infraestructura = null;
            tiposTajoAdminService.put($scope.tipotajo).then(function (response) {
                var result = response.data;
                alert($scope.tipotajo.Descripcion + " modificado correctamente");
                //$location.path("/admin/usuarios");
                $location.path("/admin/tiposTajoAdmin");

            }).catch(function (response) {
                $scope.error = tiposTajoAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }


    }]);
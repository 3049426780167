var nuevoEntorno = angular.module('entornosAdmin');

nuevoEntorno.controller('nuevoEntornoAdminController', ['$scope', '$routeParams', '$location', 'entornosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, entornosAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;

    $scope.gestionEntornos = function () {
        $location.path("/admin/entornosAdmin");
    }

        $scope.guardarEntorno = function () {
            entornosAdminService.post($scope.descripcion).then(function (response) {
                var result = response.data;
                alert("Entorno añadido correctamente");
                $location.path("/admin/entornosAdmin");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = entornosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    }]);
var modelo = angular.module('visormodelos', [])
    .controller('VisorModelosController', ['$scope', '$rootScope', '$sce', '$location', '$routeParams', 'visormodelosService', function ($scope, $rootScope, $sce, $location, $routeParams, visormodelosService) {

        visormodelosService.getModeloInfraestructura($routeParams.id)
            .then(function (response) {
                var data = response.data;
                //$scope.modeloUrl = $sce.trustAsResourceUrl(data.Ruta);
                $scope.modeloUrl = $sce.trustAsHtml(data.Ruta);
                //$scope.modeloUrl = data.Ruta;
        });
    }
    ]);
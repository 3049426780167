var infraestructura = angular.module('infraestructuras');

infraestructura.controller('infraestructuraController', ['$rootScope', '$scope', '$location', 'infraestructurasService', '$routeParams', 'authenticationInfo', 'authenticationService', 'infraestructurasAdminService', 'inspeccionesService'
    , function ($rootScope, $scope, $location, infraestructurasService, $routeParams, authenticationInfo, authenticationService, infraestructurasAdminService, inspeccionesService) {

        $scope.authentication = authenticationInfo;
        $scope.pestanyavisible = 'general';
        $scope.pestanyavisibleInsp = 'general';
        $scope.pestanyamenusuperior = 'informacion';
        $rootScope.InfraestructuraId = $routeParams.id;


        if ($rootScope.parteUrl == true) {
            $scope.OpcionSeleccionada = "app/Partes/partes.html";
            $scope.OpcionSeleccionadaClass = 'parte';
            $scope.pestanyaparte = "tab-parte active"
            $scope.pestanyanube = "tab-nube"
            $rootScope.parteUrl = false;
            $rootScope.inspeccionUrl = false;
        }
        else {
            if ($rootScope.inspeccionUrl == true) {
                $scope.pestanyamenusuperior = 'inspeccion';
            }
            $scope.OpcionSeleccionada = "app/TV/TV.html";
            $scope.OpcionSeleccionadaClass = 'tv';
            $scope.pestanyaparte = "tab-parte"
            $scope.pestanyanube = "tab-nube active"
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
        }

        $scope.idObra = $routeParams.id;

        infraestructurasAdminService.getEntornos()
            .then(function (response) {
                var data = response.data;
                $scope.entornos = data;
            });


        infraestructurasAdminService.getPropietarios()
            .then(function (response) {
                var data = response.data;
                $scope.propietarios = data;
            });

        infraestructurasAdminService.getMunicipios()
            .then(function (response) {
                var data = response.data;
                $scope.municipios = data;
            });

        $scope.verInspeccion = function (idInspeccion) {
            $location.url('/inspecciones/' + idInspeccion);
        }

        // Variable que representa el valor del índice
        const indiceValor = 2; // Cambia este valor dinámicamente (1, 2 o 3)

        infraestructurasService.getInfraestructura($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.infraestructura = data;

                indicevalor = $scope.infraestructura.IndiceInspeccionId;


                /*const indiceEstado = document.getElementById('indiceEstado');*/
                const indiceValorSpan = document.getElementById('indiceValor');

                if ($scope.infraestructura.IndiceInspeccion.Color == "Verde") {
                    $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-0 row";
                    $scope.infraestructura.IndiceEstadoInfraestructura = "indice-verde";
                    /*indiceEstado.style.color = "green";*/
                    indiceValorSpan.style.color = "green";
                }
                else if ($scope.infraestructura.IndiceInspeccion.Color == "Amarillo") {
                    $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-1 row";
                    $scope.infraestructura.IndiceEstadoInfraestructura = "indice-naranja";
                    /*indiceEstado.style.color = "orange";*/
                    indiceValorSpan.style.color = "orange";
                }
                else if ($scope.infraestructura.IndiceInspeccion.Color == "Naranja") {
                    $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-1 row";
                    $scope.infraestructura.IndiceEstadoInfraestructura = "indice-naranja";
                    /*indiceEstado.style.color = "orange";*/
                    indiceValorSpan.style.color = "orange";
                }
                else if ($scope.infraestructura.IndiceInspeccion.Color == "Rojo") {
                    $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-2 row";
                    $scope.infraestructura.IndiceEstadoInfraestructura = "indice-rojo";
                    /*indiceEstado.style.color = "red";*/
                    indiceValorSpan.style.color = "red";
                }


                infraestructurasService.getTunel($scope.infraestructura.Id)
                    .then(function (response) {
                        var data = response.data;
                        $scope.tunel = data;


                        infraestructurasService.getInspecciones($scope.tunel.Id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.inspecciones = data;
                                $scope.ultimainspeccion = $scope.inspecciones[0];


                                // codigo de chatGPT

                                infraestructurasService.getDañosPorTipo($scope.ultimainspeccion.Id)
                                    .then(function (response) {
                                        $scope.daños = response.data;

                                        // Generar dinámicamente los labels y los datos basados en $scope.daños
                                        const labels = $scope.daños.map(function (d) {
                                            return d.TipoDañoDescripcion || 'N/A';
                                        });

                                        const data = $scope.daños.map(function (d) {
                                            return d.TipoDañoId || 0;
                                        });

                                        // Generar colores dinámicamente si hay más de 3 tipos de daños
                                        const backgroundColors = labels.map(function (_, i) {
                                            return 'rgba(' +
                                                Math.floor(Math.random() * 255) + ', ' +
                                                Math.floor(Math.random() * 255) + ', ' +
                                                Math.floor(Math.random() * 255) + ', 0.7)';
                                        });

                                        const borderColors = [];
                                        for (var i = 0; i < labels.length; i++) {
                                            borderColors.push(
                                                'rgba(' +
                                                Math.floor(Math.random() * 255) + ', ' +
                                                Math.floor(Math.random() * 255) + ', ' +
                                                Math.floor(Math.random() * 255) + ', 1)'
                                            );
                                        }
                                        // Configuración de los datos del gráfico
                                        const damageData = {
                                            labels: labels, // Etiquetas dinámicas
                                            datasets: [{
                                                label: 'Distribución de Daños',
                                                data: data, // Datos dinámicos
                                                backgroundColor: backgroundColors,
                                                borderColor: borderColors,
                                                borderWidth: 1
                                            }]
                                        };

                                        // Configuración del gráfico
                                        const config = {
                                            type: 'bar',
                                            data: damageData,
                                            options: {
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'top', // Posición de la leyenda
                                                    },
                                                    tooltip: {
                                                        enabled: true, // Mostrar valores al pasar el mouse
                                                    }
                                                }
                                            }
                                        };

                                        // Creación del gráfico
                                        const ctx = document.getElementById('graficoDañosPorTipo').getContext('2d');
                                        const damagePieChart = new Chart(ctx, config);
                                    });

                            });

                    });
     });

      inspeccionesService.getElementosInspeccionados($scope.inspeccionId)
         .then(function (response) {
             var data = response.data;
             $scope.elementosinspeccionados = data;
      });

      inspeccionesService.getElementosEvaluados($scope.inspeccionId)
        .then(function (response) {
        var data = response.data;
                        $scope.elementosevaluados = data;
                    });
        infraestructurasService.getTiposTunel()
            .then(function (response) {
                var data = response.data;
                $scope.tiposTunel = data;
            });

        infraestructurasService.getObstaculos()
            .then(function (response) {
                var data = response.data;
                $scope.obstaculos = data;
            });

        infraestructurasService.getNumDireccionesTunel()
            .then(function (response) {
                var data = response.data;
                $scope.numDireccionesTunel = data;
            });

        infraestructurasService.getLongitudTunel()
            .then(function (response) {
                var data = response.data;
                $scope.longitudes = data;
            });

        infraestructurasService.getMaterialesTunel()
            .then(function (response) {
                var data = response.data;
                $scope.materiales = data;
            });

        infraestructurasService.getTipoVia()
            .then(function (response) {
                var data = response.data;
                $scope.tiposVia = data;
            });

        infraestructurasService.getCirculacionPermitida()
            .then(function (response) {
                var data = response.data;
                $scope.circulacionesPermitida = data;
            });

        infraestructurasService.getCategoria($rootScope.categoria)
            .then(function (response) {
                var data = response.data;
                $scope.categoria = data;
            });


        $scope.verCategorias = function () {
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
            $location.path('/categorias');
        };



        $scope.guardarInventario = function () {
            $scope.tunel.InfraestructuraId = $routeParams.id;
            $scope.tunel.AñoConstruccion = $scope.tunel.AnioConstruccion;
            $scope.tunel.MaterialHastialDcholId = $scope.tunel.MaterialHastialDchoId;
            if ($scope.tunel.Id != undefined) {
                infraestructurasService.guardarInventario($scope.tunel, $scope.infraestructura.CodIdentificador, $scope.infraestructura.Propietario.Id, $scope.infraestructura.Entorno.Id, $scope.infraestructura.Municipio.Id)
                    .then(function (response) {
                        var data = response.data;
                        $scope.tunel.Id = data;
                    });
                alert("Datos guardados correctamente");
            } else {
                $scope.tunel.Id = -1;
                infraestructurasService.guardarInventario($scope.tunel)
                    .then(function (response) {
                        var data = response.data;
                        $scope.tunel.Id = data;
                    });
                alert("Datos guardados correctamente");
            }
        };


        $scope.guardarInspeccion = function () {
            infraestructurasService.guardarInspeccion($scope.inspeccion)
                .then(function (response) {
                    var data = response.data;
                    $scope.tunel.Id = data;
                });

            alert("Datos guardados correctamente");
        };

        $scope.volver = function () {
            $location.path('/categorias');
        }

        $scope.getModelo = function () {
            //$location.path("/modelo/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/VisorModelos/visorModelos.html";
            $scope.OpcionSeleccionadaClass = 'modelos';
        }


        $scope.getSensores = function () {
            //$location.path("/modelo/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/Sensores/Sensores.html";
            $scope.OpcionSeleccionadaClass = 'sensores';
        }

        $scope.nuevaInspeccion = function () {
            $location.url('/inspecciones/0');
        }

        $scope.getNubeDePuntos = function () {
            //$location.path("/TV/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/TV/TV.html";
            $scope.OpcionSeleccionadaClass = 'tv';
        }

        $scope.getGestionDocumental = function () {
            //$location.path("/TV/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/GestionDocumental/gestiondocumental.html";
            $scope.OpcionSeleccionadaClass = 'docs';
        }

        $scope.getRealidadVirtual = function () {
            //$location.path("/TV/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/TourVirtual/tourvirtual.html";
            $scope.OpcionSeleccionadaClass = 'rv';
        }


        $scope.getPartes = function () {
            $scope.OpcionSeleccionada = "app/Partes/partes.html";
            $scope.OpcionSeleccionadaClass = 'parte';
        }

        $scope.getGIS = function () {
            $scope.OpcionSeleccionada = "app/GIS/gis.html";
            $scope.OpcionSeleccionadaClass = 'gis';
        }




        $scope.logout = function () {
            //$rootScope.bodylayout = "login";
            return authenticationService.logout();
        }

        $scope.TipologiaCargar = function () {
            $scope.pestanyavisible = 'tipologia';
        }

        $scope.DimensionesCargar = function () {
            $scope.pestanyavisible = 'dimensiones';
        }

        $scope.MaterialesCargar = function () {
            $scope.pestanyavisible = 'materiales';
        }

        $scope.ImportanciaCargar = function () {
            $scope.pestanyavisible = 'importancia';
        }

        $scope.ElementosInspeccionadosCargar = function () {
            $scope.pestanyavisibleInsp = 'elementosinspeccionados';
        }

        $scope.ElementosEvaluadosCargar = function () {
            $scope.pestanyavisibleInsp = 'elementosevaluados';
        }

        $scope.InventarioCargar = function () {
            $scope.pestanyamenusuperior = 'inventario';
        }

        $scope.InspeccionCargar = function () {
            $scope.pestanyamenusuperior = 'inspeccion';
        }

        $scope.ConservacionCargar = function () {
            $scope.pestanyamenusuperior = 'conservacion';
        }

        $scope.InformacionCargar = function () {
            //$scope.calcularGrafico();
            $scope.pestanyamenusuperior = 'informacion';
        }

        $scope.VisualizacionCargar = function () {
            $scope.pestanyamenusuperior = 'visualizacion';
        }

    }]);
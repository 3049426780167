var tiposTajoAdminService = angular.module('tiposTajoAdmin');

tiposTajoAdminService.factory('tiposTajoAdminService', ['$http', '$q', '__env', function tiposTajoAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var categoriasUrl = 'categorias';
        var infraestructurasUrl = 'infraestructuras';
        var rolesUrl = 'roles';

        function getTiposTajo() {
            return $http.get(serviceUrl + categoriasUrl + '/tipostajo');
        }

    function getInfraestructuras(idCategoria) {
        return $http.get(serviceUrl + infraestructurasUrl + '/admin/categoria/' + idCategoria);
        }

        function getTipoTajo(id) {
            return $http.get(serviceUrl + categoriasUrl + '/tipotajo/' + id);
        }

        function put(tipotajo) {
            return $http({
                method: 'put',
                url: serviceUrl + categoriasUrl + '/editar/tipotajo',
                params:
                {
                    'tipotajo': JSON.stringify(tipotajo)
                }
            });
        }

        function post(tipotajo) {
            //return $http.post(serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario, usuario);
            return $http({
                method: 'post',
                url: serviceUrl + categoriasUrl + '/registrar/tipotajo' ,
                params:
                {
                    'tipotajo': JSON.stringify(tipotajo)
                }

            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }
        function eliminarTiposTajo(tiposTajoEliminar) {
            return $http.post(serviceUrl + categoriasUrl + '/eliminar/' + tiposTajoEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
                alert('Error');
            });
        }

        function getRolesPorUsuario(id) {
            return $http.get(serviceUrl + rolesUrl + '/' + id);
        }


        return {
            getTiposTajo: getTiposTajo,
            getInfraestructuras: getInfraestructuras,
            getTipoTajo: getTipoTajo,
            put: put,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarTiposTajo: eliminarTiposTajo

        }

    }]);